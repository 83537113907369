import React from "react";
import { useQueryClient, useQuery, useMutation } from "react-query";

import { fetchRecentFiles, deleteRecentFile } from "domain/files";

export default function useRecentFiles() {
  const queryClient = useQueryClient();
  const { data: recentFiles = [], status } = useQuery(
    "recentFiles",
    fetchRecentFiles
  );
  const deletion = useMutation(deleteRecentFile, {
    onSuccess: () => {
      queryClient.invalidateQueries("recentFiles");
    },
  });

  return { recentFiles, status, mutations: { deletion } };
}
