import React, { useRef } from "react";

import Text, { Props as TextProps } from "atoms/Text";
import useHasEllipsis from "hooks/useHasEllipsis";

export interface Props extends TextProps {
  children: string;
}

/**
 * @example
 * <TruncatedText style={{ maxWidth: 240 }}>
 *     Some long text
 * </TruncatedText>
 */
const TruncatedText = ({ children, style, ...rest }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasEllipsis = useHasEllipsis(ref);

  return (
    <Text>
      <div
        ref={ref}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...style,
        }}
        title={hasEllipsis ? children : undefined}
        {...rest}
      >
        {children}
      </div>
    </Text>
  );
};

export default TruncatedText;
