import React, {
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
  useReducer,
  useRef,
} from "react";
import cn from "classnames";

import InputLabel from "atoms/InputLabel";
import { Calendar as CalendarIcon } from "components/Icons";
import Text, { Props as TextProps } from "atoms/Text";
import useClickOutside from "hooks/useClickOutside";
import { MonthOfYear, Month } from "domain/dates";
import { Triangle } from "components/Icons";
import InputError from "atoms/InputError";

import styles from "./MonthPicker.module.sass";

export interface Props {
  month: MonthOfYear | null;
  label: string;
  placeholder: string;
  onMonthChange: (month: MonthOfYear) => void;

  error?: string;
  earliestAllowedSelection?: MonthOfYear;
  latestAllowedSelection?: MonthOfYear;
}

export const nullEarliestAllowedSelection = new MonthOfYear(1, 1);
export const nullLatestAllowedSelection = new MonthOfYear(9999, 12);

export default function MonthPicker({
  month,
  label,
  placeholder,
  onMonthChange,

  error = "",
  earliestAllowedSelection = nullEarliestAllowedSelection,
  latestAllowedSelection = nullLatestAllowedSelection,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isSelecting = ["selectingYear", "selectingMonth"].includes(state.step);

  function handleTriggerClick() {
    if (isSelecting) {
      dispatch({ type: "cancelSelecting" });
      return;
    }
    dispatch({ type: "startSelecting" });
  }

  function handleYearSelection(year: number) {
    dispatch({ type: "selectYear", year });
  }

  function handleMonthSelection(month: Month) {
    dispatch({ type: "selectMonth", month });
    onMonthChange(new MonthOfYear(state.year as number, month));
  }

  useClickOutside(dropdownRef, () => {
    dispatch({ type: "cancelSelecting" });
  });

  return (
    <div
      className={cn(styles.monthPicker, error && styles.monthPicker$withError)}
    >
      <InputLabel>{label}</InputLabel>
      <button className={styles.trigger} onClick={handleTriggerClick}>
        {month ? (
          <Text>
            {getMonthLabel(month.month)} {month.year}
          </Text>
        ) : (
          <Text size={16} color="grey600" style={{ fontStyle: "italic" }}>
            {placeholder}
          </Text>
        )}
        <CalendarIcon className={styles.calendarIcon} />
      </button>
      {state.step === "selectingYear" && (
        <YearSelectionDropdown
          ref={dropdownRef}
          earliestAllowedSelection={earliestAllowedSelection}
          latestAllowedSelection={latestAllowedSelection}
          onSelect={handleYearSelection}
        />
      )}
      {state.step === "selectingMonth" && (
        <MonthSelectionDropdown
          ref={dropdownRef}
          year={state.year as number}
          earliestAllowedSelection={earliestAllowedSelection}
          latestAllowedSelection={latestAllowedSelection}
          onCurrentYearClick={() => dispatch({ type: "startSelecting" })}
          onSelect={handleMonthSelection}
        />
      )}
      {error !== "" && (
        <InputError style={{ marginTop: 6 }}>{error}</InputError>
      )}
    </div>
  );
}

const YearSelectionDropdown = forwardRef<
  HTMLDivElement,
  {
    earliestAllowedSelection: MonthOfYear;
    latestAllowedSelection: MonthOfYear;
    onSelect: (year: number) => void;
  }
>(({ earliestAllowedSelection, latestAllowedSelection, onSelect }, ref) => (
  <Dropdown ref={ref}>
    <DropdownTitle style={{ justifyContent: "center" }}>
      Select Year
    </DropdownTitle>
    <Grid>
      {/* Currently displays last 21 years */}
      {[...Array(21).keys()]
        .map((i) => MonthOfYear.now().year - i)
        .reverse()
        .map((year) => (
          <Button
            key={year}
            disabled={
              year < earliestAllowedSelection.year ||
              year > latestAllowedSelection.year
            }
            onClick={() => onSelect(year)}
          >
            {year}
          </Button>
        ))}
    </Grid>
  </Dropdown>
));

const months = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

function getMonthLabel(monthOrdinalNumber: Month) {
  return Object.keys(months)[monthOrdinalNumber - 1];
}

const MonthSelectionDropdown = forwardRef<
  HTMLDivElement,
  {
    year: number;
    earliestAllowedSelection: MonthOfYear;
    latestAllowedSelection: MonthOfYear;
    onCurrentYearClick: () => void;
    onSelect: (month: Month) => void;
  }
>(
  (
    {
      year,
      earliestAllowedSelection,
      latestAllowedSelection,
      onCurrentYearClick,
      onSelect,
    },
    ref
  ) => (
    <Dropdown ref={ref}>
      <DropdownTitle>
        <button
          className={styles.backButton}
          onClick={() => onCurrentYearClick()}
        >
          {year}
          <Triangle style={{ marginLeft: 4 }} />
        </button>
        <span>Select Month</span>
        <button className={styles.backButton} style={{ visibility: "hidden" }}>
          {year}
          <Triangle style={{ marginLeft: 4 }} />
        </button>
      </DropdownTitle>
      <Grid>
        {(Object.keys(months) as Array<keyof typeof months>).map(
          (monthName, monthIndex) => {
            const monthOfYear = new MonthOfYear(
              year,
              (monthIndex + 1) as Month
            );

            return (
              <Button
                key={monthName}
                disabled={
                  monthOfYear.isBefore(earliestAllowedSelection) ||
                  monthOfYear.isAfter(latestAllowedSelection)
                }
                onClick={() => onSelect(months[monthName] as Month)}
              >
                {monthName}
              </Button>
            );
          }
        )}
      </Grid>
    </Dropdown>
  )
);

const Dropdown = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => (
    <div
      ref={ref}
      className={styles.dropdown}
      style={{ padding: 20 }}
      {...props}
    />
  )
);

function DropdownTitle({ style, ...rest }: TextProps) {
  return (
    <Text
      size={16}
      weight="bold"
      style={{
        paddingBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
      {...rest}
    />
  );
}

function Grid(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 66px)",
        gridAutoRows: "33px",
        gap: 6,
      }}
      {...props}
    />
  );
}

function Button(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button className={styles.button} {...props} />;
}

type State = {
  step: "notSelecting" | "selectingYear" | "selectingMonth" | "doneSelecting";
  year: number | null;
  month: number | null;
};

type Action =
  | { type: "startSelecting" }
  | { type: "cancelSelecting" }
  | { type: "selectYear"; year: number }
  | { type: "selectMonth"; month: number };

const initialState: State = {
  step: "notSelecting",
  year: null,
  month: null,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "cancelSelecting":
      return { ...initialState };
    case "startSelecting":
      return {
        ...state,
        step: "selectingYear",
      };
    case "selectYear":
      return {
        ...state,
        step: "selectingMonth",
        year: action.year,
      };
    case "selectMonth":
      return {
        ...state,
        step: "doneSelecting",
        month: action.month,
      };
  }
}
