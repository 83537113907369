import React, { SVGProps } from "react";
import cn from "classnames";

import styles from "./PdfIcon.module.sass";

const PdfIcon = ({
  width = 24,
  height = 24,
  hasHoverState = true,
  ...rest
}: SVGProps<SVGSVGElement> & { hasHoverState?: boolean }) => (
  <svg
    className={cn(styles.pdf, hasHoverState && styles.pdf$hasHoverState)}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    {...rest}
  >
    <g>
      <path d="M8.59 11.12h-.38v2.74h.38c.32 0 .55-.04.69-.11.14-.07.24-.2.32-.38s.12-.47.12-.87c0-.53-.09-.89-.26-1.09s-.47-.29-.87-.29zM3.5 11.01h-.44v1.12h.38c.3 0 .51-.05.63-.16s.18-.24.18-.4c0-.16-.05-.29-.16-.4s-.3-.16-.59-.16z" />
      <path d="M13 0H3v9H0v7h3v4h16V6l-6-6zm4.6 6H13V1.4L17.6 6zM3.06 13.14V15H1.51V9.99h2.57c.56 0 .98.13 1.26.4s.42.65.42 1.14c0 .51-.15.9-.46 1.19-.3.28-.77.42-1.39.42h-.85zM18 19H4v-3h13V9H4V1h8v6h6v12zM6.66 15V9.99h2.3c.45 0 .82.06 1.1.18.28.12.51.3.69.53.18.23.32.5.4.8s.12.63.12.97c0 .54-.06.95-.18 1.25s-.29.54-.51.74c-.22.2-.45.33-.7.4-.34.09-.65.14-.92.14h-2.3zm9-3.06v1.01h-1.94V15h-1.55V9.99H16v1.08h-2.27v.88h1.93z" />
    </g>
  </svg>
);

export default PdfIcon;
