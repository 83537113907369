import React, { SVGProps } from "react";

export interface Props extends SVGProps<SVGSVGElement> {}

const Magnifier = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    height={18}
    width={18}
    role="presentation"
    {...props}
  >
    <path d="M18 19a1 1 0 01-.71-.29L12.56 14a7.76 7.76 0 01-4.76 1.6A7.8 7.8 0 117.8 0a7.82 7.82 0 017.8 7.8 7.84 7.84 0 01-1.6 4.76l4.73 4.73A1 1 0 0118 19zM7.8 2a5.73 5.73 0 00-4.1 1.7 5.81 5.81 0 000 8.2 5.73 5.73 0 004.1 1.7 5.75 5.75 0 004.1-1.7 5.83 5.83 0 001.7-4.1A5.8 5.8 0 007.8 2z" />
  </svg>
);

export default Magnifier;
