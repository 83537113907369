import axios from "axios";
import { BASE_URI } from "domain/api";

import { Monitor, APIMonitor } from "./models";

async function fetchAll(): Promise<Monitor[]> {
  const { data: apiMonitors } = await axios.get<APIMonitor[]>(
    `${BASE_URI}/api/v2/GetAllMonitorsController.php`
  );
  return apiMonitors.map(createFromAPIMonitor);
}

/**
 * @todo Make into DELETE request
 */
function deleteSingle(monitor: Monitor): Promise<boolean> {
  return axios.get(`${BASE_URI}/api/v2/DeleteSingleMonitorController.php`, {
    params: {
      id: monitor.id,
    },
  });
}

function createFromAPIMonitor({
  uuid,
  dateCreated,
  dateModified,
  ...rest
}: APIMonitor) {
  return {
    ...rest,
    briefcaseUUID: uuid,
    dateCreated: new Date(dateCreated),
    dateModified: dateModified ? new Date(dateModified) : new Date(dateCreated),
  };
}

export { fetchAll, deleteSingle };
