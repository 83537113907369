import React, { ReactNode, useState } from "react";

import { User, getBriefcasesVariant, getMonitorsVariant } from "domain/users";
import {
  Briefcase,
  generateAfterFinalStrategy,
  generatePortfolioPerformanceReport,
  SortOrder,
  getSortFunction,
} from "domain/briefcases";
import { refreshDownloadProgressIndicator } from "integration/progress";
import { Monitor } from "domain/monitors";
import useBriefcasesAppCounts from "hooks/useBriefcasesAppCounts";
import useDocumentTitle from "hooks/useDocumentTitle";
import useBriefcases from "hooks/useBriefcases";
import useMonitors from "hooks/useMonitors";
import useRecentFiles from "hooks/useRecentFiles";
import PageTitle from "components/PageTitle";
import Text from "components/Text";
import BriefcaseCard from "components/BriefcaseCard";
import BriefcaseDialog from "components/BriefcaseDialog";
import BriefcaseCreationDialog from "components/BriefcaseCreationDialog";
import Modal from "components/Modal";

import BriefcasesPanel from "./BriefcasesPanel";
import MonitorsPanel from "./MonitorsPanel";
import RecentFilesPanel from "./RecentFilesPanel";
import Button from "components/Button";

import styles from "./module.sass";
import cn from "classnames";

export type Variant = "default" | "preview" | "readOnly";

export default function MySavedWork({ user }: { user: User }) {
  useDocumentTitle("My Saved Work - PatentAdvisor\u00ae");

  const [briefcaseQuery, setBriefcaseQuery] = useState("");
  const [briefcasesSortOrder, setBriefcasesSortOrder] = useState<SortOrder>(
    "dateUpdated"
  );
  const [briefcasesLimit, setBriefcasesLimit] = useState<null | 8>(8);

  const {
    status: briefcasesStatus,
    briefcases: allBriefcases,
    mutations: briefcaseMutations,
  } = useBriefcases();
  const allBriefcasesAppCounts = useBriefcasesAppCounts();
  const shownBriefcases = getShownBriefcases(
    allBriefcases,
    briefcaseQuery,
    briefcasesLimit,
    briefcasesSortOrder
  );
  const shouldGetQuickAppCounts =
    shownBriefcases.length > 0 &&
    Object.keys(allBriefcasesAppCounts).length === 0;
  const quickBriefcasesAppCounts = useBriefcasesAppCounts(
    shownBriefcases,
    shouldGetQuickAppCounts
  );
  const shownBriefcasesWithAppCounts = enrichBriefcasesWithAppCounts(
    shownBriefcases,
    { ...quickBriefcasesAppCounts, ...allBriefcasesAppCounts }
  );
  const [openedBriefcase, setOpenedBriefcase] = useState<Briefcase | null>(
    null
  );
  const [isCreationDialogOpen, setIsCreationDialogOpen] = useState(false);

  const [errorDialogText, setErrorDialogText] = useState("");

  const {
    monitors,
    status: monitorsStatus,
    mutations: monitorMutations,
  } = useMonitors();
  const {
    recentFiles,
    status: recentFilesStatus,
    mutations: recentFileMutations,
  } = useRecentFiles();

  const briefcasesVariant = getBriefcasesVariant(user);
  const monitorsVariant = getMonitorsVariant(user);

  const handleSave = async (briefcase: Briefcase) => {
    setOpenedBriefcase(briefcase);

    try {
      await briefcaseMutations.edition.mutateAsync(briefcase);
    } catch (err) {
      setOpenedBriefcase(null);
    }
  };
  const handleAfterFinalStrategy = (briefcase: Briefcase) => {
    setOpenedBriefcase(null);
    generateAfterFinalStrategy(briefcase).catch((err) => {
      console.log(err);
    });
    setTimeout(() => {
      refreshDownloadProgressIndicator();
    }, 300);
  };
  const handlePortfolioPerformanceReport = (
    briefcase: Briefcase,
    dateFrom: Date,
    dateTo: Date | null
  ) => {
    setOpenedBriefcase(null);
    generatePortfolioPerformanceReport(briefcase, dateFrom, dateTo).catch(
      (err) => {
        console.log(err);
      }
    );
    setTimeout(() => {
      refreshDownloadProgressIndicator();
    }, 300);
  };
  const handleBriefcaseLinkClick = (monitor: Monitor) => {
    const briefcase = allBriefcases.find(
      (b) => b.uuid === monitor.briefcaseUUID
    );
    if (briefcase) {
      setOpenedBriefcase(briefcase);
    }
  };

  const createBriefcase = async (name: string) => {
    setIsCreationDialogOpen(false);
    try {
      const briefcase = await briefcaseMutations.creation.mutateAsync(name);
      setOpenedBriefcase(briefcase);
    } catch (e) {
      const error = e?.response?.data?.error;
      const message = e?.response?.data?.message;
      if (error === "usageLimitReached" && message) {
        setErrorDialogText(message);
        return;
      }
      console.error(e);
    }
  };
  const handleBriefcaseDelete = async (briefcase: Briefcase) => {
    try {
      await briefcaseMutations.deletion.mutateAsync(briefcase);
    } catch (err) {
      const error = err?.response?.data?.error;
      if (error == "onlyEditor") {
        setErrorDialogText(
          "Before you delete, please assign EDIT permissions to someone else."
        );
      } else {
        console.log(err);
      }
    }
  };

  return (
    <>
      <PageTitle>My Saved Work</PageTitle>
      {(briefcasesVariant === "default" || briefcasesVariant === "preview") && (
        <>
          <BriefcasesPanel
            totalCount={allBriefcases.length}
            shownCount={shownBriefcases.length}
            query={briefcaseQuery}
            sortOrder={briefcasesSortOrder}
            isViewingAll={briefcasesLimit === null}
            isLoading={briefcasesStatus === "loading"}
            variant={briefcasesVariant}
            onCreate={() => setIsCreationDialogOpen(true)}
            onQuery={(query) => setBriefcaseQuery(query)}
            onSort={(sortOrder) => setBriefcasesSortOrder(sortOrder)}
            onToggleViewAll={() =>
              setBriefcasesLimit((limit) => (limit === 8 ? null : 8))
            }
          >
            {shownBriefcasesWithAppCounts.map((briefcase) => (
              <BriefcaseCard
                key={briefcase.uuid}
                briefcase={briefcase}
                isReadOnly={briefcasesVariant === "preview"}
                onOpen={(briefcase) => setOpenedBriefcase(briefcase)}
                onDelete={handleBriefcaseDelete}
              />
            ))}
          </BriefcasesPanel>
          <Spacing />
        </>
      )}

      {(monitorsVariant === "default" || monitorsVariant === "preview") && (
        <>
          <MonitorsPanel
            monitors={monitors}
            isLoading={monitorsStatus === "loading"}
            variant={monitorsVariant}
            onBriefcaseClick={
              monitorsVariant === "default" || monitorsVariant === "preview"
                ? handleBriefcaseLinkClick
                : () => {}
            }
            onDelete={(monitor) => monitorMutations.deletion.mutate(monitor)}
          />
          <Spacing />
        </>
      )}

      <RecentFilesPanel
        files={recentFiles}
        isLoading={recentFilesStatus === "loading"}
        onDelete={(file) => recentFileMutations.deletion.mutate(file)}
      />
      <Spacing />

      {openedBriefcase ? (
        <BriefcaseDialog
          briefcase={openedBriefcase}
          permissions={user.permissions}
          hasUserCompany={user.hasCompany}
          isOpen={true}
          isReadOnly={briefcasesVariant === "preview"}
          onDismiss={() => setOpenedBriefcase(null)}
          onSave={handleSave}
          onAfterFinalStrategy={handleAfterFinalStrategy}
          onPortfolioPerformanceReport={handlePortfolioPerformanceReport}
        />
      ) : null}

      <BriefcaseCreationDialog
        isOpen={isCreationDialogOpen}
        onDismiss={() => setIsCreationDialogOpen(false)}
        onCreate={(name) => createBriefcase(name)}
      />
      <Modal
        title="Error"
        ariaLabel="Error"
        isOpen={errorDialogText.length > 0}
        onDismiss={() => setErrorDialogText("")}
        footer={
          <Button
            variant="contained"
            size="xs"
            onClick={() => setErrorDialogText("")}
          >
            <span style={{ minWidth: 50 }}>Close</span>
          </Button>
        }
      >
        {errorDialogText}
      </Modal>
    </>
  );
}

export const Container = ({ children }: { children: ReactNode }) => (
  <div className={styles.row}>
    <div className={styles.container}>{children}</div>
  </div>
);

export const Heading = ({
  text,
  subtext,
  hasBorder = true,
}: {
  text: ReactNode;
  subtext?: ReactNode;
  hasBorder?: boolean;
}) => (
  <div className={cn(styles.heading, !hasBorder && styles.heading$noBorder)}>
    <Text variant="h2">{text}</Text>
    {subtext && <div style={{ marginTop: 8 }}>{subtext}</div>}
  </div>
);

export const Controls = ({ children }: { children: ReactNode }) => (
  <div className={styles.controls}>{children}</div>
);

export const LongDescription = ({ children }: { children: ReactNode }) => (
  <div className={styles.longDescription}>{children}</div>
);

export const NoResults = () => (
  <div className={styles.noResults}>There are no results for this search</div>
);

const Spacing = () => <div style={{ paddingBottom: 65 }} />;

export function getShownBriefcases(
  briefcases: Briefcase[],
  query: string,
  limit: number | null = 8,
  sortOrder: SortOrder = "dateUpdated"
): Briefcase[] {
  const filtered = briefcases.filter((b) =>
    query ? b.name.toLowerCase().includes(query.toLowerCase()) : true
  );
  const sorted = filtered
    .sort(
      (a, b) =>
        (sortOrder === "alphabetical" ? 1 : -1) *
        getSortFunction(sortOrder)(a, b)
    )
    .sort((a, b) => {
      if (a.isLeader && !b.isLeader) {
        return -1;
      }
      if (a.isLeader === b.isLeader) {
        return 0;
      }
      return 1;
    });
  const shown = limit === null ? sorted : sorted.slice(0, limit);

  return shown;
}

function enrichBriefcasesWithAppCounts(
  briefcases: Briefcase[],
  appCounts: Record<string, number>
): Briefcase[] {
  return briefcases.map((b) => {
    return {
      ...b,
      applicationCount:
        appCounts[b.uuid] !== undefined ? appCounts[b.uuid] : "N/A",
    };
  });
}
