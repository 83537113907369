import React, { AnchorHTMLAttributes, MouseEventHandler } from "react";
import cn from "classnames";

import styles from "./module.sass";

export type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

export default function Anchor(props: Props) {
  return <a {...props} className={cn(styles.anchor, props.className)} />;
}

export interface InterceptingAnchorProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "onClick"> {
  onBeforeNavigate?: () => void;
}

export function BareInterceptingAnchor({
  href,
  onBeforeNavigate = () => {},
  ...rest
}: InterceptingAnchorProps) {
  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();

    onBeforeNavigate();

    if (!href) {
      return;
    }

    window.location.href = href;
  };

  return <a onClick={handleClick} {...rest} />;
}
