import axios, { AxiosResponse } from "axios";
import { AssigneeWithCitationCount } from "./models";

export interface CancelablePromise<T> extends Promise<T> {
  cancel: () => void;
}

export function searchAssignees(nameFragment: string) {
  type Data = AssigneeWithCitationCount[];
  type Response = AxiosResponse<Data>;

  const controller = new AbortController();

  const promise = axios.get<Data>("/api/v2/search-assignees.php", {
    params: { nameFragment },
    signal: controller.signal,
  });
  (promise as CancelablePromise<Response>).cancel = () => {
    controller.abort();
  };

  return promise as CancelablePromise<Response>;
}
