import React, { ReactNode } from "react";
import cn from "classnames";

import styles from "./Item.module.sass";

const Item = ({
  children,
  variant = "horizontallyPadded",
  isActive = false,
}: {
  children: ReactNode;
  variant?: "horizontallyPadded" | "thin" | "padded";
  isActive?: boolean;
}) => (
  <div
    className={cn(
      styles.item,
      styles[`item$${variant}`],
      isActive && styles.item$active
    )}
  >
    {children}
  </div>
);

export default Item;
