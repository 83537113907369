import React, { SVGProps } from "react";
import colors from "style/colors.module.scss";

const Triangle = ({
  width = 9,
  height = 6,
  fill = colors.grey900,
  variant = "down",
  ...rest
}: { variant?: "down" | "up" } & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 6"
    width={width}
    height={height}
    transform={variant == "up" ? `rotate(180)` : ""}
    {...rest}
  >
    <path fill={fill} d="M9 0L4.5 6 0 0z" />
  </svg>
);

export default Triangle;
