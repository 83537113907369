import React, { useState, useEffect } from "react";
import Text from "components/Text";
import styles from "./module.sass";
import { Gap } from "../utils";
import { Back } from "components/Icons";
import Button from "components/Button";
import cn from "classnames";
import Pulse from "../Pulse";
import Toast, { useToastContext } from "components/Toast";

const useScrollPosition = (): { scrollPosition: number } => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { scrollPosition };
};

const Header = ({
  title,
  title2,
  subtitle,
  backText,
  onBackClick,
}: {
  title?: string;
  title2?: string;
  subtitle?: string;
  backText?: string;
  onBackClick?: () => void;
}) => (
  <div className={styles.header}>
    {!!backText && !!onBackClick && (
      <>
        <Button
          onClick={onBackClick}
          variant={"link"}
          size={"xxs"}
          className={styles.backButton}
        >
          <Back />
          <Gap size={8} />
          {backText}
        </Button>

        {(!!title || !!title2 || !!subtitle) && <Gap size={28} vertical />}
      </>
    )}

    {!!title && <Text variant={"h1"}>{title}</Text>}
    {!!title2 && <Text variant={"h2"}>{title2}</Text>}
    {!!subtitle && <Text className={styles.subtitle}>{subtitle}</Text>}
  </div>
);

export default function Layout({
  title,
  title2,
  subtitle,
  backText,
  onBackClick,
  children,
  filters,
  pills,
  summary,
  isLoading,
}: {
  title?: string;
  title2?: string;
  subtitle?: string;
  backText?: string;
  onBackClick?: () => void;
  filters?: React.ReactNode;
  pills?: React.ReactNode;
  summary?: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
}) {
  const toastContext = useToastContext();
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <Header
          title={title}
          title2={title2}
          subtitle={subtitle}
          backText={backText}
          onBackClick={onBackClick}
        />

        <div className={styles.sticky}>
          <div className={cn(styles.filters, styles.shadow)}>
            {filters && <div className={styles.filtersRow}>{filters}</div>}

            {pills && <div className={styles.pillsRow}>{pills}</div>}

            {summary && <div className={styles.summaryRow}>{summary}</div>}
          </div>
        </div>

        <div className={styles.content} style={{ borderTop: 0 }}>
          {children}
        </div>

        {!!toastContext?.toast && <Toast {...toastContext.toast} />}
      </div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Pulse />
        </div>
      )}
    </div>
  );
}

export function SimpleLayout({
  title,
  backText,
  onBackClick,
  children,
  isLoading,
  raw,
}: {
  title?: string;
  backText?: string;
  onBackClick?: () => void;
  children: React.ReactNode;
  raw?: React.ReactNode;
  isLoading?: boolean;
}) {
  const { scrollPosition } = useScrollPosition();

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div
          className={cn(
            styles.simpleFilters,
            styles.sticky,
            scrollPosition > 0 && styles.shadow
          )}
        >
          <Header
            title2={title}
            backText={backText}
            onBackClick={onBackClick}
          />

          <div className={styles.raw}>{raw}</div>
        </div>

        <Gap size={20} vertical />

        <div className={styles.content}>{children}</div>
      </div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Pulse />
        </div>
      )}
    </div>
  );
}
