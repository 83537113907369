import React, { useEffect, useMemo, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import queryString from "query-string";
import Table, { EmptyMessage } from "components/Table";
import {
  Column,
  useSortBy,
  useTable,
  usePagination,
  Row,
  useRowSelect,
} from "react-table";
import {
  DecisionType,
  OutcomeRequestTypes,
  LawFirmType,
  JudgeType,
  DecisionTypes,
  fetchDecisions,
  fetchJudges,
  fetchLawFirms,
  fetchCustomerNumbers,
  downloadPdfFiles,
  FetchDecisionsArgsType,
  STATUSES,
} from "domain/ptabDecisions";
import {
  DictionaryItemType,
  Gap,
  getParams,
  getReactSelectDefaultProps,
  isNumber,
  safe,
  withNumber,
  RaceConditionGuard,
} from "../components/utils";
import ReactSelect from "react-select";
import Button from "components/Button";
import TextInput, { Label } from "../components/TextInput";
import Text from "components/Text";
import Link from "components/Link";
import styles from "./module.sass";
import Checkbox from "components/Checkbox";
import { PdfIcon } from "components/Icons";
import { useLocation, useHistory } from "react-router-dom";
import Pill from "components/Pill";
import useDocumentTitle from "hooks/useDocumentTitle";
import { NoResults } from "components/MySavedWork";
import { useToastContext } from "components/Toast";
import cn from "classnames";
import { PTAB_DECISIONS_SEARCH_KEY } from "../PTABDecisionsPage";
import TruncatedText from "atoms/TruncatedText";
import { makeNumberReadable } from "domain/humanize";

const lawFirmsRaceConditionGuard = new RaceConditionGuard();
const judgesRaceConditionGuard = new RaceConditionGuard();

const getOutcomeLabel = (value?: string): string => {
  switch (value) {
    case OutcomeRequestTypes.ExaminerAffirmed:
      return "Affirmed";
    case OutcomeRequestTypes.ExaminerAffirmedInPart:
      return "Affirmed in Part";
    case OutcomeRequestTypes.ExaminerReversed:
      return "Reversed";
    case OutcomeRequestTypes.NotAvailable:
      return "Not Available";
  }

  return value ?? "";
};

const OUTCOME_REQUEST_TYPES = [
  {
    label: getOutcomeLabel(OutcomeRequestTypes.ExaminerAffirmed),
    value: OutcomeRequestTypes.ExaminerAffirmed,
  },
  {
    label: getOutcomeLabel(OutcomeRequestTypes.ExaminerAffirmedInPart),
    value: OutcomeRequestTypes.ExaminerAffirmedInPart,
  },
  {
    label: getOutcomeLabel(OutcomeRequestTypes.ExaminerReversed),
    value: OutcomeRequestTypes.ExaminerReversed,
  },
  {
    label: getOutcomeLabel(OutcomeRequestTypes.NotAvailable),
    value: OutcomeRequestTypes.NotAvailable,
  },
];

const CellArray = ({
  value,
  row,
}: {
  value?: Array<string>;
  row: Row<DecisionType>;
}) => {
  const inputArray = value
    ? value.map((x) => x.trim()).filter((x) => x.length > 0)
    : [];

  if (inputArray?.length === 0) {
    return <Text>-</Text>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {inputArray?.map((x: string, i: number) => (
        <Text key={`${row.id} ${i} ${x}`}>
          <TruncatedText style={{ maxWidth: "inherit" }}>{x}</TruncatedText>
        </Text>
      ))}
    </div>
  );
};

const buildColumns = (): Column<DecisionType>[] => {
  return [
    {
      Header: "Issues",
      accessor: "issues",
      Cell: CellArray,
    },
    {
      Header: "Case Name",
      accessor: "case_name",
      Cell: ({ value, row }) => (
        <Link href={`/app.php#decision/${row.original.id}`} dark>
          {value}
        </Link>
      ),
    },
    {
      Header: "Application #",
      accessor: "application_number_link",
      Cell: ({ value, row }) => (
        <Link
          key={`application_number_link${row.original.id}`}
          href={value}
          dark
          openInNewTab
        >
          {row.original.application_number}
        </Link>
      ),
    },
    {
      Header: "Outcome",
      accessor: "outcomes",
      Cell: ({ value, row }) => (
        <CellArray value={value?.map(getOutcomeLabel)} row={row} />
      ),
    },
    {
      Header: "Decision Date",
      accessor: "date",
      Cell: ({ value, row }) => row.original.decision_date,
    },
    { Header: "Type", accessor: "type" },
    {
      Header: "Judges",
      accessor: "judges_names",
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return <CellArray value={value} row={row} />;
      },
    },
  ];
};

const DocumentsDownloadToastMessage = ({
  downloadedDocuments = [],
  unavailableDocuments = [],
}: {
  downloadedDocuments: Array<string> | undefined;
  unavailableDocuments: Array<string> | undefined;
}) => {
  const downloadedCnt = downloadedDocuments.length;
  const unavailableCnt = unavailableDocuments.length;
  const total = downloadedCnt + unavailableCnt;

  const p1 =
    downloadedCnt < 2
      ? `${downloadedCnt} of ${total} was downloaded. `
      : `${downloadedCnt} of ${total} were downloaded. `;

  return (
    <Text>
      {p1}
      {unavailableDocuments.map((x, index, arr) => (
        <>
          <b key={index}>{x}</b>
          {index < unavailableCnt - 1 && " and "}
        </>
      ))}
      {unavailableCnt < 2 ? " is not available." : " are not available."}
    </Text>
  );
};

type PararmsType = {
  outcomeRequest?: OutcomeRequestTypes;
  decisionSearch?: string;
  judgeName?: string;
  lawFirmId?: string;
  lawFirmName?: string;
  customerNumber?: string;
  year?: string;
  examinerId?: string;
  artUnitValue?: string;
  tags?: string;
  issues?: string;
  decisionType?: DecisionTypes;
};

const DecisionsByIssuePage = () => {
  useDocumentTitle("Decisions By Issue - PatentAdvisor®");

  const location = useLocation<{ from: string }>();
  const history = useHistory();

  const {
    year: _year,
    artUnitValue: _artUnitValue,
    examinerId: _examinerId,
    tags: _tags,
    issues: _issues,
    outcomeRequest: _outcomeRequest,
    judgeName: _judgeName,
    decisionSearch: _decisionSearch,
    lawFirmId: _lawFirmId,
    lawFirmName: _lawFirmName,
    customerNumber: _customerNumber,
    decisionType: _decisionType,
  }: PararmsType = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [artUnitValue] = useState<string>(withNumber(_artUnitValue) ?? "");
  const [year] = useState<string>(withNumber(_year) ?? "");
  const [decisionType] = useState(_decisionType);
  const [examinerId] = useState<string>(withNumber(_examinerId) ?? "");
  const [tags] = useState<Array<number>>(
    !!_tags ? safe(() => JSON.parse(_tags), []) : []
  );
  const [issues] = useState<Array<number>>(
    !!_issues ? safe(() => JSON.parse(_issues), []) : []
  );

  const [decisions, setDecisions] = useState<Array<DecisionType>>([]);

  const [outcomeRequest, setOutcomeRequest] =
    useState<DictionaryItemType | null>(
      !!_outcomeRequest
        ? OUTCOME_REQUEST_TYPES.find((x) => x.value === _outcomeRequest) ?? null
        : null
    );
  const [decisionSearch, setDecisionSearch] = useState<string>(
    _decisionSearch ?? ""
  );
  const [judgeName, setJudgeName] = useState<string>("");
  const [judge, setJudge] = useState<{ value: string; label: string } | null>(
    !!_judgeName ? { value: _judgeName, label: _judgeName } : null
  );
  const [judges, setJudges] = useState<Array<JudgeType>>([]);
  const [isJudgesLoading, setJudgesLoading] = useState<boolean>(false);

  const [customerNumberOrLawFirmValue, setCustomerNumberOrLawFirmValue] =
    useState<string>(_lawFirmName ?? _customerNumber ?? "");

  const [customerNumberOrLawFirmItem, setCustomerNumberOrLawFirmItem] =
    useState<DictionaryItemType | null>(
      !!_lawFirmId && isNumber(_lawFirmId) && !!_lawFirmName
        ? { value: _lawFirmId, label: _lawFirmName }
        : !!_customerNumber && isNumber(_customerNumber)
        ? { value: _customerNumber, label: _customerNumber }
        : null
    );
  const [lawFirms, setLawFirms] = useState<Array<LawFirmType>>([]);
  const [customerNumbers, setCustomerNumbers] = useState<Array<number>>([]);
  const [isLawFirmsLoading, setLawFirmsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toastContext = useToastContext();

  const columns = useMemo(() => {
    return buildColumns();
  }, []);

  useEffect(() => {
    if (isNumber(customerNumberOrLawFirmValue)) {
      setLawFirmsLoading(true);
      lawFirmsRaceConditionGuard
        .getGuardedPromise(
          fetchCustomerNumbers({ customerNumber: customerNumberOrLawFirmValue })
        )
        .then((res) => {
          setCustomerNumbers(res);
        })
        .finally(() => {
          setLawFirmsLoading(false);
        });
    } else if (customerNumberOrLawFirmValue?.length >= 3) {
      setLawFirmsLoading(true);
      lawFirmsRaceConditionGuard
        .getGuardedPromise(
          fetchLawFirms({ lawFirmName: customerNumberOrLawFirmValue })
        )
        .then((res) => {
          setLawFirms(res);
        })
        .finally(() => {
          setLawFirmsLoading(false);
        });
    } else {
      lawFirmsRaceConditionGuard.getGuardedPromise(
        new Promise(() => {
          setLawFirms([]);
          setLawFirmsLoading(false);
        })
      );
    }
  }, [customerNumberOrLawFirmValue, setLawFirms]);

  useEffect(() => {
    if (judgeName?.length > 2) {
      setJudgesLoading(true);
      judgesRaceConditionGuard
        .getGuardedPromise(fetchJudges(judgeName))
        .then((items) => {
          setJudges(items);
        })
        .finally(() => {
          setJudgesLoading(false);
        });
    } else {
      judgesRaceConditionGuard.getGuardedPromise(
        new Promise(() => {
          setJudges([]);
          setJudgesLoading(false);
        })
      );
    }
  }, [judgeName]);

  const [args, setArgs] = useState<
    FetchDecisionsArgsType & {
      lawFirmName?: string;
    }
  >({
    year: year,
    examinerId: examinerId,
    issues: issues,
    tags: tags,
    artUnitValue: artUnitValue,
    outcomeRequest: outcomeRequest?.value,
    judgeName: judge?.value,
    decisionSearch: decisionSearch,
    lawFirmId: isNumber(customerNumberOrLawFirmItem?.label)
      ? undefined
      : customerNumberOrLawFirmItem?.value,
    lawFirmName: isNumber(customerNumberOrLawFirmItem?.label)
      ? undefined
      : customerNumberOrLawFirmItem?.label,
    customerNumber: isNumber(customerNumberOrLawFirmItem?.label)
      ? customerNumberOrLawFirmItem?.value
      : undefined,
    decisionType: decisionType,
  });

  useEffect(() => {
    setIsLoading(true);

    history.replace({
      search: getParams({
        ...args,
        issues: JSON.stringify(args.issues),
        tags: JSON.stringify(args.tags),
      }).toString(),
    });

    fetchDecisions(args)
      .then((x: any) => {
        setDecisions(x ?? []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [artUnitValue, issues, tags, examinerId, year, args]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.scrollY;

      if (scrollTop > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const table = useTable(
    {
      columns: columns,
      data: decisions,
      initialState: {
        pageSize: 50,
        sortBy: useMemo(
          () => [
            {
              id: "date",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({
            toggleAllPageRowsSelected,
            getToggleAllPageRowsSelectedProps,
          }) => {
            const { checked, indeterminate } =
              getToggleAllPageRowsSelectedProps();

            return (
              <Checkbox
                isChecked={checked === undefined ? false : checked}
                isIndeterminate={indeterminate}
                onChange={toggleAllPageRowsSelected}
              />
            );
          },
          disableSortBy: true,
          accessor: "id",
          Cell: ({ value, row }) => {
            return (
              <Checkbox
                key={`checkbox${row.original.id}`}
                isChecked={row.isSelected}
                onChange={row.toggleRowSelected}
              />
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const buttonRef = useRef<HTMLButtonElement>(null);

  const isPillsAvailable =
    !!args?.outcomeRequest ||
    !!args?.judgeName ||
    !!args?.lawFirmName ||
    !!args?.customerNumber ||
    !!args?.decisionSearch;

  const onFilterClick = () => {
    buttonRef.current?.focus();

    const isCustomerNumberEntered = isNumber(
      customerNumberOrLawFirmItem?.label
    );
    setArgs({
      ...args,
      outcomeRequest: outcomeRequest?.value,
      decisionSearch,
      judgeName: judge?.value,
      lawFirmId: isCustomerNumberEntered
        ? undefined
        : customerNumberOrLawFirmItem?.value,
      lawFirmName: isCustomerNumberEntered
        ? undefined
        : customerNumberOrLawFirmItem?.label ?? undefined,
      customerNumber: isCustomerNumberEntered
        ? customerNumberOrLawFirmItem?.value
        : undefined,
    });
  };

  const ptabDecisionsSearch: string | null = window.sessionStorage.getItem(
    PTAB_DECISIONS_SEARCH_KEY
  );

  return (
    <Layout
      backText={"PTAB Decisions"}
      title2={"Decisions by Issue"}
      onBackClick={
        ptabDecisionsSearch && ptabDecisionsSearch?.length > 0
          ? () => {
              history.push(`/ptab-decisions${ptabDecisionsSearch}`);
            }
          : undefined
      }
      isLoading={isLoading}
      filters={
        <>
          <Label label={"Outcome"} className={styles.outcomeRequest}>
            <ReactSelect
              placeholder={"Select Outcome"}
              {...getReactSelectDefaultProps({
                hasValue: outcomeRequest !== null,
                hasOptions: true,
              })}
              onChange={setOutcomeRequest}
              value={outcomeRequest}
              options={OUTCOME_REQUEST_TYPES}
              isClearable={true}
              isSearchable={false}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <Label label={"Judge"} className={styles.judge}>
            <ReactSelect
              {...getReactSelectDefaultProps({
                hasValue: !!judge,
                hasOptions: false,
              })}
              placeholder={""}
              inputValue={judgeName ?? ""}
              onInputChange={setJudgeName}
              value={judge}
              onChange={setJudge}
              options={judges?.map((x) => ({ value: x.name, label: x.name }))}
              isLoading={isJudgesLoading}
              isSearchable={true}
              isClearable={true}
              filterOption={() => true}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <Label
            label={"Customer Number / Law Firm"}
            className={styles.lawFirm}
          >
            <ReactSelect
              {...getReactSelectDefaultProps({
                hasValue: !!customerNumberOrLawFirmItem,
                hasOptions: false,
              })}
              value={customerNumberOrLawFirmItem}
              inputValue={customerNumberOrLawFirmValue}
              onChange={setCustomerNumberOrLawFirmItem}
              onInputChange={setCustomerNumberOrLawFirmValue}
              options={
                isNumber(customerNumberOrLawFirmValue)
                  ? customerNumbers?.map((x) => ({
                      value: `${x}`,
                      label: `${x}`,
                    }))
                  : lawFirms?.map((x) => ({
                      value: `${x.id}`,
                      label: x.name,
                    }))
              }
              isClearable={true}
              isSearchable={true}
              placeholder={""}
              isLoading={isLawFirmsLoading}
              filterOption={() => true}
              isDisabled={isLoading}
            />
          </Label>

          <Gap />

          <TextInput
            className={styles.decisionSearch}
            value={decisionSearch ?? ""}
            label={"Search Decisions"}
            placeholder={"Enter Keywords"}
            onChange={setDecisionSearch}
            onEnterClick={onFilterClick}
            isReadOnly={isLoading}
          />

          <Gap />

          <Button
            ref={buttonRef}
            size={"sm"}
            onClick={onFilterClick}
            variant={"contained"}
            className={styles.filterButton}
          >
            Filter
          </Button>
        </>
      }
      pills={
        isPillsAvailable && (
          <>
            {!!args?.outcomeRequest && (
              <Pill
                className={styles.pill}
                label={"Outcome"}
                value={getOutcomeLabel(args?.outcomeRequest)}
                onClear={() => {
                  setOutcomeRequest(null);
                  const newArgs = { ...args };
                  delete newArgs.outcomeRequest;
                  setArgs(newArgs);
                }}
              />
            )}

            {!!args?.judgeName && (
              <Pill
                className={styles.pill}
                label={"Judge"}
                value={args?.judgeName ?? ""}
                onClear={() => {
                  setJudgeName("");
                  setJudge(null);
                  const newArgs = { ...args };
                  delete newArgs.judgeName;
                  setArgs(newArgs);
                }}
              />
            )}

            {!!args?.lawFirmName && (
              <Pill
                className={styles.pill}
                label={"Law Firm"}
                value={args?.lawFirmName ?? ""}
                onClear={() => {
                  setCustomerNumberOrLawFirmValue("");
                  setCustomerNumberOrLawFirmItem(null);
                  const newArgs = { ...args };
                  delete newArgs.lawFirmId;
                  delete newArgs.lawFirmName;
                  setArgs(newArgs);
                }}
              />
            )}

            {!!args?.customerNumber && (
              <Pill
                className={styles.pill}
                label={"Cust. Number"}
                value={args?.customerNumber ?? ""}
                onClear={() => {
                  setCustomerNumberOrLawFirmValue("");
                  setCustomerNumberOrLawFirmItem(null);
                  const newArgs = { ...args };
                  delete newArgs.customerNumber;
                  setArgs(newArgs);
                }}
              />
            )}

            {!!args?.decisionSearch && (
              <Pill
                className={styles.pill}
                label={"Search"}
                value={args?.decisionSearch}
                onClear={() => {
                  setDecisionSearch("");
                  const newArgs = { ...args };
                  delete newArgs.decisionSearch;
                  setArgs(newArgs);
                }}
              />
            )}
          </>
        )
      }
      summary={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <span style={{ display: "flex", flex: 1 }}>
            <Text>
              {(decisions?.length ?? 0) === 1
                ? "1 decision"
                : `${makeNumberReadable(decisions?.length ?? 0)} decisions`}
            </Text>
            <Gap strip />
            <Text>{`${table.selectedFlatRows?.length ?? 0} selected`}</Text>
          </span>

          <Button
            isDisabled={!(table.selectedFlatRows?.length > 0)}
            variant={"link"}
            size={"xxs"}
            onClick={() => {
              setIsLoading(true);
              downloadPdfFiles(
                table.selectedFlatRows?.map((x) => x.original.id)
              )
                .then(
                  ({
                    message,
                    status,
                    downloadedDocuments,
                    unavailableDocuments,
                  }) => {
                    if (
                      (downloadedDocuments?.length ?? 0) +
                        (unavailableDocuments?.length ?? 0) >
                      0
                    ) {
                      if (status === STATUSES.ERROR) {
                        toastContext?.showCustomToast(
                          <DocumentsDownloadToastMessage
                            downloadedDocuments={downloadedDocuments}
                            unavailableDocuments={unavailableDocuments}
                          />,
                          "error"
                        );
                        return;
                      } else if (status === STATUSES.WARNING) {
                        toastContext?.showCustomToast(
                          <DocumentsDownloadToastMessage
                            downloadedDocuments={downloadedDocuments}
                            unavailableDocuments={unavailableDocuments}
                          />,
                          "warning"
                        );
                        return;
                      }
                    }

                    if (!!message) {
                      if (status === STATUSES.ERROR) {
                        toastContext?.showErrorToast(message);
                      } else if (status === STATUSES.WARNING) {
                        toastContext?.showWarningToast(message);
                      } else {
                        toastContext?.showInfoToast(message);
                      }
                    }
                  }
                )
                .finally(() => {
                  setIsLoading(false);
                });
            }}
            className={styles.downloadButton}
          >
            <PdfIcon />
            <Gap size={8} />
            Download Decisions
          </Button>
        </div>
      }
    >
      <Table
        table={table}
        tableClassName={styles.decissionsByIssues}
        emptyMessage={
          <EmptyMessage colSpan={8} style={{ borderTop: "1px solid #acbac9" }}>
            <NoResults />
          </EmptyMessage>
        }
        tableHeaderRowClassName={cn(
          styles.tableStickyHeaderRow,
          isSticky && styles.tableStickyHeaderRow$scroll,
          isPillsAvailable && styles.tableStickyHeaderRow$pills
        )}
        onPageChange={() => window.scroll(0, 100)}
      />
    </Layout>
  );
};

export default DecisionsByIssuePage;
