class FileSize {
  private _bytes: number;

  constructor(bytes: number) {
    this._bytes = bytes;
  }

  public static fromBytes(bytes: number) {
    return new FileSize(bytes);
  }

  public static compare(a: FileSize, b: FileSize) {
    if (a.bytes === b.bytes) {
      return 0;
    }
    return a.bytes < b.bytes ? -1 : 1;
  }

  get bytes() {
    return this._bytes;
  }

  get kilobytes() {
    return this._bytes / 1024;
  }

  get megabytes() {
    return this._bytes / (1024 * 1024);
  }

  get gigabytes() {
    return this._bytes / (1024 * 1024 * 1024);
  }

  public format() {
    if (this._bytes < 1024) {
      return `${this._bytes.toFixed(2)} B`;
    }
    if (this._bytes < 1024 * 1024) {
      return `${this.kilobytes.toFixed(2)} KB`;
    }
    if (this._bytes < 1024 * 1024 * 1024) {
      return `${this.megabytes.toFixed(2)} MB`;
    }
    return `${this.gigabytes.toFixed(2)} GB`;
  }
}

export { FileSize };
