import React, { ReactNode, RefObject } from "react";
import reactStringReplace from "react-string-replace";

import Text from "atoms/Text";
import Highlight from "atoms/Highlight";

import Input from "./Input";
import Dropdown from "./Dropdown";
import { Option, Values } from "./options";

export interface Props<T = undefined> {
  label: ReactNode;
  query: string;
  options: Option<T>[];
  selectedValues: Values;
  onQueryChange: (query: string) => void;
  onSelect: (newValues: Values) => void;

  ref?: RefObject<HTMLInputElement>;
  placeholder?: string;
  formattedSelectedValues?: string;
  error?: string;
  isLoading?: boolean;
}

export default function ComboBox<T>({
  ref,
  label,
  query,
  options,
  selectedValues,
  onSelect,
  onQueryChange,
  placeholder = "",
  formattedSelectedValues = Array.from(selectedValues).join(", "),
  error = "",
  isLoading = false,
}: Props<T>) {
  const hasSelectedValues = selectedValues.size > 0;
  const hasQuery = query.length > 0;
  const minimumQueryLength = 2;
  const isQueryLongEnough = query.length >= minimumQueryLength;
  const shouldShowOptions = !hasSelectedValues && isQueryLongEnough;

  function handleSelect(newValues: Values) {
    onSelect(newValues);
  }

  function handleClear() {
    onQueryChange("");
    onSelect(new Set());
  }

  return (
    <Text>
      <Input
        ref={ref}
        label={label}
        value={hasSelectedValues ? formattedSelectedValues : query}
        disabled={hasSelectedValues}
        placeholder={placeholder}
        error={error}
        isClearable={hasQuery || hasSelectedValues}
        onChange={(query) => onQueryChange(query)}
        onClear={handleClear}
      />
      {shouldShowOptions && (
        <Dropdown
          options={options}
          isLoading={isLoading}
          onSelect={handleSelect}
        />
      )}
    </Text>
  );
}

export interface HighlightedLabelProps extends Pick<Props, "query" | "label"> {}

/**
 * Highlights occurances of the query in labels.
 * Can be used for `Option.label`.
 */
export function HighlightedLabel({ query, label }: HighlightedLabelProps) {
  return (
    <>
      {reactStringReplace(
        [label],
        new RegExp(`(${query})`, "gi"),
        (match, i) => (
          <Highlight key={i} color="strongYellow" isUnderlined>
            {match}
          </Highlight>
        )
      )}
    </>
  );
}
