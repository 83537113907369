import { format as formatDate } from "domain/dates";
import { nullAssignee } from "domain/assignees";

import { Citation } from ".";

export function formatAsCSV(citations: Citation[]): string {
  const csvSeparatorRow = "Sep=,\r\n";
  const csvHeaderRow = [
    "Cited Assignee",
    "Cited Document",
    "Citation Count",
    "Title",
    "Rejected Assignee",
    "Rejected Application",
    "Status",
    "Rejection Type",
    "Examiner Rejection",
    "Is rejection final?",
    "Mail Room Date",
  ]
    .join(",")
    .concat("\r\n");
  const csvRecords = citations
    .map((citation) => {
      const { citedApplication, rejectedApplication } = citation;

      return [
        citedApplication.assignee,
        String(citedApplication.citedDocument),
        citationCount(citedApplication.applicationNumber, citations),
        String(citedApplication.title),
        (rejectedApplication.assignee || nullAssignee).name,
        String(rejectedApplication.applicationNumber),
        String(rejectedApplication.status),
        citation.rejectionType
          .split(", ")
          .map((r) => "§" + r)
          .join(", "),
        String(citation.rejectionDocumentUrl),
        citation.isRejectionFinal ? "Final" : "Non-Final",
        formatDate(citation.citationDate, "Y-m-d"),
      ];
    })
    .map((values) => values.map((v) => `"${v.replace('"', '""')}"`))
    .map((values) => values.join(","))
    .join("\r\n");

  return csvSeparatorRow + csvHeaderRow + csvRecords;
}

function citationCount(searchAppNumber: number, citations: Citation[]): string {
  var countCitation = citations.reduce(function (n, citation) {
    return citation.citedApplication.applicationNumber === searchAppNumber
      ? n + 1
      : n;
  }, 0);

  return String(countCitation);
}
