import React, { ReactNode, ReactNodeArray, useEffect, useRef } from "react";
import reactStringReplace from "react-string-replace";

import { ReconcilableTextChunk } from "domain/search";
import Highlight, { Props as HighlightProps } from "atoms/Highlight";
import Text from "atoms/Text";
import colors from "style/colors.module.scss";

export type Color = HighlightProps["color"];

export interface HighlightedTextChunk extends ReconcilableTextChunk {
  color: Color;
}

export type Props = {
  document: string;
  highlights: HighlightedTextChunk[];

  focusedTextOffset?: number | null;
  isHighlightUnderlined?: (highlight: HighlightedTextChunk) => boolean;
};

/**
 * Highlights shouldn't overlap.
 */
export default function DocumentViewer({
  document,
  highlights,
  focusedTextOffset = null,
  isHighlightUnderlined = (highlight) => false,
}: Props) {
  const focusedRef = useRef<HTMLSpanElement>(null);

  let processedDocument = document;
  let result: ReactNode[] = [];
  let shiftedAmount = 0;

  highlights
    .sort((a, b) => a.start - b.start)
    .forEach((highlight) => {
      const { start, end } = highlight;

      const actualStart = start - shiftedAmount;
      const actualEnd = end - shiftedAmount;
      const previousPart = processedDocument.slice(0, actualStart);
      const highlightedPart = processedDocument.slice(actualStart, actualEnd);

      processedDocument = processedDocument.slice(actualEnd);
      shiftedAmount += actualEnd;

      const isUnderlined = isHighlightUnderlined(highlight);

      result.push(
        previousPart,
        <Highlight
          ref={isUnderlined ? focusedRef : undefined}
          color={highlight.color}
          isUnderlined={isUnderlined}
          style={{ fontSize: 18, lineHeight: "24px", letterSpacing: "0.15px" }}
        >
          {addNewlines(highlightedPart)}
        </Highlight>
      );
    });

  result.push(processedDocument);

  useEffect(() => {
    focusedRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [focusedTextOffset]);

  return (
    <Text
      as="section"
      size={18}
      style={{
        height: "100%",
        overflowY: "auto",
        padding: 16,
        border: `1px solid ${colors.grey350}`,
        lineHeight: "22px",
        letterSpacing: "0.15px",
        boxSizing: "border-box",
      }}
    >
      {/* Add newlines to all elements which are strings -- highlights already
        have the newlines added at this point. reactStringReplace ignores non-strings. */}
      {addNewlines(result)}
    </Text>
  );
}

function addNewlines(input: string | ReactNodeArray) {
  return reactStringReplace(input, /(\r\n|\n)/g, () => <br />);
}
