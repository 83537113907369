import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Setup from "templates/LicensingReports/Setup";
import { LicensingReportRequest } from "domain/licensingReports";
import useUser from "hooks/useUser";
import PageLoader from "molecules/PageLoader";
import Navbar, { EmptyNavbar } from "components/Navbar";
import { format as formatDate } from "domain/dates";
import { redirectToLogin } from "integration/login";
import useAssigneeSearch from "hooks/useAssigneeSearch";

export default function LicensingReportSetupPage() {
  const { status: userStatus, user, isLoggedOut } = useUser();
  if (isLoggedOut) {
    redirectToLogin();
  }

  const [assigneeQuery, setAssigneeQuery] = useState("");
  const {
    assignees: assigneeSuggestions,
    isLoading: areAssigneeSuggestionsLoading,
  } = useAssigneeSearch(assigneeQuery);

  const location = useLocation();
  const query = queryString.parse(location.search, { parseBooleans: true });
  const shouldReuseLastSearch = Boolean(query?.reuseLastSearch ?? false);

  function handleSubmit(request: LicensingReportRequest) {
    const searchParams = new URLSearchParams({
      assigneeIds: request.assigneeIds.join(","),
      citationDateFrom: formatDate(request.citationDateFrom, "Y-m-d"),
      citationDateTo: request.citationDateTo
        ? formatDate(request.citationDateTo, "Y-m-d")
        : "",
      reportName: request.reportName || "",
      shouldInclude102Rejections: String(request.shouldInclude102Rejections),
      shouldInclude103Rejections: String(request.shouldInclude103Rejections),
      shouldIncludeAbandonedApps: String(request.shouldIncludeAbandonedApps),
      shouldIncludeSelfCitations: String(request.shouldIncludeSelfCitations),
    });
    window.location.href = `/app.php#licensing-reports/view?${searchParams.toString()}`;
  }

  return (
    <>
      <PageLoader isLoading={userStatus === "loading"} />
      <Setup
        assigneeQuery={assigneeQuery}
        assigneeSuggestions={assigneeSuggestions}
        navbar={
          user ? (
            <Navbar user={user} position="relative" />
          ) : (
            <EmptyNavbar position="relative" />
          )
        }
        backgroundImageUrl="/assets/img/wave-background.png"
        areAssigneeSuggestionsLoading={areAssigneeSuggestionsLoading}
        shouldReuseLastSearch={shouldReuseLastSearch}
        onAssigneeQueryChange={(query) => setAssigneeQuery(query)}
        onSubmit={handleSubmit}
      />
    </>
  );
}
