import React from "react";

import useReleaseNotes from "hooks/useReleaseNotes";
import ReleaseNotes from "organisms/ReleaseNotes";
import Navbar, { EmptyNavbar } from "components/Navbar";
import Footer from "components/Footer";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import useUser from "hooks/useUser";
import PageLoader from "molecules/PageLoader";
import SkipNavigationLink from "atoms/SkipNavigationLink";

import styles from "./module.sass";

export default function ReleaseNotesPage() {
  const { status: isLoggedInStatus, isLoggedIn } = useIsLoggedIn();
  if (isLoggedInStatus === "success" && !isLoggedIn) {
    redirectToLogin();
  }
  const { status: userStatus, user } = useUser();
  const { status: releaseNotesStatus, releaseNotes } = useReleaseNotes();

  const isLoading =
    userStatus !== "success" ||
    isLoggedInStatus !== "success" ||
    releaseNotesStatus !== "success";

  return (
    <div className={styles.page}>
      <SkipNavigationLink />
      <PageLoader isLoading={isLoading} />
      {user ? <Navbar user={user} /> : <EmptyNavbar />}
      <main className={styles.container}>
        <ReleaseNotes releaseNotes={releaseNotes} />
      </main>
      <Footer variant="white" position="static" />
    </div>
  );
}

function redirectToLogin() {
  window.location.href = "/login.php";
}
