import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import { User, SearchPermissions, hasMySavedWork } from "domain/users";
import { LinkType } from "domain/api";
import Link from "components/Link";
import { Container } from "components/Layout";
import Modal from "components/Modal";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import PatentAdvisorLogo from "components/Icons/PatentAdvisorLogo";

import menuStyles from "./Menu.module.sass";
import AppDrawer from "./AppDrawer";
import Menu from "./Menu";
import Item from "./Item";
import { recordMenuItemClick } from "./analytics";

import styles from "./module.sass";

export interface Props {
  user: User;

  position?: "fixedWhenLong" | "relative";
}

export default function Navbar({ user, position = "fixedWhenLong" }: Props) {
  const { pathname } = useLocation();

  return (
    <div className={cn(styles.navbar, styles[`navbar$${position}`])}>
      <Container>
        <nav className={styles.nav}>
          <div className={styles.half}>
            <AppDrawer />
            <Item variant="padded">
              <Link
                href="/#"
                onBeforeNavigate={() => {
                  recordMenuItemClick("patentadvisor_logo");
                }}
              >
                <PatentAdvisorLogo />
              </Link>
            </Item>
          </div>
          <div className={styles.half}>
            <SearchMenu user={user} />
            <BusinessDevelopmentMenu user={user} />
            {hasMySavedWork(user) && (
              <Item isActive={pathname === "/my-saved-work"}>
                <Link
                  href="/#my-saved-work"
                  isFluid
                  style={{ fontSize: 16 }}
                  onBeforeNavigate={() => {
                    recordMenuItemClick("my_saved_work");
                  }}
                >
                  My Saved Work
                </Link>
              </Item>
            )}
            {(user.viewMenu.some((m) => m.href === "/browser-extension.php") ||
              user.groups.browserExtensionOnly) && (
              <Item
                isActive={window.location.pathname.startsWith(
                  "/browser-extension"
                )}
              >
                <Link
                  href="/browser-extension.php"
                  isFluid
                  style={{ fontSize: 16 }}
                  onBeforeNavigate={() => {
                    recordMenuItemClick("browser_extension");
                  }}
                >
                  Browser Extension
                </Link>
              </Item>
            )}
            <AdminMenu user={user} />
            <CustomAdminMenu user={user} />
            <ViewMenu user={user} />
            <HamburgerMenu user={user} />
          </div>
        </nav>
      </Container>
    </div>
  );
}

export const EmptyNavbar = ({
  position = "fixedWhenLong",
}: Pick<Props, "position">) => (
  <div className={cn(styles.navbar, styles[`navbar$${position}`])}>
    <Container>
      <nav className={styles.nav}>
        <div className={styles.half}>
          <Item variant="padded">
            <Link href="/#">
              <PatentAdvisorLogo />
            </Link>
          </Item>
        </div>
        <div className={styles.half}></div>
      </nav>
    </Container>
  </div>
);

function SearchMenu({ user }: { user: User }) {
  const hasAnyPermission = searchMenuLinks.some(
    ({ permission }) => user.permissions.search[permission]
  );
  if (!hasAnyPermission) {
    return null;
  }

  return (
    <Menu
      trigger={<Item isActive={isMenuActive(searchMenuLinks)}>Search</Item>}
    >
      <Menu.List>
        {searchMenuLinks.map(({ href, name, permission }) =>
          user.permissions.search[permission] ? (
            <Menu.ListItem
              key={href}
              href={href}
              onClick={() => {
                recordMenuItemClick(name);
              }}
            >
              {name}
            </Menu.ListItem>
          ) : null
        )}
      </Menu.List>
    </Menu>
  );
}

function BusinessDevelopmentMenu({ user }: { user: User }) {
  const hasAnyVisibleMenuLinks = businessDevelopmentMenuLinks.some((link) =>
    link.isVisible(user.permissions)
  );
  if (!hasAnyVisibleMenuLinks) {
    return null;
  }

  return (
    <Menu
      trigger={
        <Item isActive={isMenuActive(businessDevelopmentMenuLinks)}>
          Business Development
        </Item>
      }
    >
      <Menu.List>
        {businessDevelopmentMenuLinks
          .filter(({ isVisible }) => isVisible(user.permissions))
          .map(({ href, name }) => (
            <Menu.ListItem
              key={name}
              href={href}
              onClick={() => {
                recordMenuItemClick(name);
              }}
            >
              {name}
            </Menu.ListItem>
          ))}
      </Menu.List>
    </Menu>
  );
}

function CustomAdminMenu({ user }: { user: User }) {
  if (!user.customAdminMenu.length) {
    return null;
  }

  return (
    <Menu trigger={<Item>Admin</Item>}>
      <Menu.List>
        {user.customAdminMenu.map(({ name, href }: LinkType) => (
          <Menu.ListItem key={name} href={href}>
            {name}
          </Menu.ListItem>
        ))}
      </Menu.List>
    </Menu>
  );
}

function AdminMenu({ user }: { user: User }) {
  const movedItems: LinkType["href"][] = [
    "/training.php",
    "/multi-user-management.php",
  ];
  const filteredItems = user.adminMenu.filter(
    (m) => !movedItems.includes(m.href)
  );

  if (user.permissions.releaseNotesAdmin) {
    filteredItems.push({
      name: "Release Notes Admin",
      href: "/app.php#release-notes-admin",
    });
  }
  if (user.permissions.trialUserApproval) {
    filteredItems.push({
      name: "Trial User Approval",
      href: "/app.php#trial-user-approval",
    });
  }

  if (!filteredItems.length) {
    return null;
  }

  return (
    <Menu trigger={<Item isActive={isMenuActive(filteredItems)}>Admin</Item>}>
      <Menu.List>
        {filteredItems.map(({ name, href }: LinkType) => (
          <Menu.ListItem key={name} href={href}>
            {name}
          </Menu.ListItem>
        ))}
      </Menu.List>
    </Menu>
  );
}

function ViewMenu({ user }: { user: User }) {
  const movedItems: LinkType["href"][] = [
    "/browser-extension.php",
    "/metadata-management.php",
    "/usage-reports.php",
    "/private_management.php",
  ];
  const removedItems: LinkType["href"][] = [
    "/ppm.php",
    "/custom-statistics.php",
  ];
  const filteredItems = user.viewMenu.filter(
    (m) => ![...movedItems, ...removedItems].includes(m.href)
  );

  if (!filteredItems.length) {
    return null;
  }

  return (
    <Menu trigger={<Item isActive={isMenuActive(filteredItems)}>View</Item>}>
      <Menu.List>
        {filteredItems.map(({ name, href }: LinkType) => (
          <Menu.ListItem key={name} href={href}>
            {name}
          </Menu.ListItem>
        ))}
      </Menu.List>
    </Menu>
  );
}

function HamburgerMenu({ user }: { user: User }) {
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  return (
    <>
      <Menu
        dropdownPosition="bottomLeft"
        trigger={
          <Item variant="thin">
            <Menu.Icon variant="more" />
          </Item>
        }
      >
        <Menu.List>
          {user.isWamUser ? (
            <Menu.ListItem
              isExternalLink={true}
              href="https://signin.lexisnexis.com/lnaccess/updatep?aci=lnpa&cls=y"
              onClick={() => {
                recordMenuItemClick("my_profile");
              }}
            >
              My Profile
            </Menu.ListItem>
          ) : (
            <Menu.ListItem
              href="/profile.php"
              onClick={() => {
                recordMenuItemClick("my_profile");
              }}
            >
              My Profile
            </Menu.ListItem>
          )}
          {user.permissions.matterNumber && (
            <Menu.ListItem
              href="/matter-number.php"
              onClick={() => {
                recordMenuItemClick("matter_number");
              }}
            >
              Matter number
            </Menu.ListItem>
          )}
          {user.whatsNewUrl ? (
            <Menu.ListItem
              href={user.whatsNewUrl}
              isExternalLink
              onClick={() => {
                recordMenuItemClick("whats_new");
              }}
            >
              What's new?
            </Menu.ListItem>
          ) : null}
          {user.viewMenu.some((m) => m.name === "Metadata Management") && (
            <Menu.ListItem
              href="/metadata-management.php"
              onClick={() => {
                recordMenuItemClick("metadata_management");
              }}
            >
              Metadata Management
            </Menu.ListItem>
          )}
          {user.permissions.privateManagement && (
            <>
              <Menu.ListItem
                href="/private_management.php"
                onClick={() => {
                  recordMenuItemClick("private_management");
                }}
              >
                Private Management
              </Menu.ListItem>
            </>
          )}
          {user.permissions.userManagement && (
            <>
              <Menu.Divider />
              <Menu.ListItem
                href="/multi-user-management.php"
                onClick={() => {
                  recordMenuItemClick("user_management");
                }}
              >
                User Management
              </Menu.ListItem>
            </>
          )}
          {user.isWamAdmin && (
            <Menu.ListItem
              isExternalLink={true}
              href="https://accountcenter.lexisnexis.com/"
              onClick={() => {
                recordMenuItemClick("lexisnexis_account_center");
              }}
            >
              LexisNexis® Account Center
            </Menu.ListItem>
          )}
          {user.permissions.companyLoginHistory && (
            <>
              <Menu.ListItem
                href="/usage-reports.php"
                onClick={() => {
                  recordMenuItemClick("usage_reports");
                }}
              >
                Usage Reports
              </Menu.ListItem>
            </>
          )}

          {user.permissions.ptabDecisionReview && (
            <>
              <Menu.ListItem
                href="/#monthly-review"
                onClick={() => {
                  recordMenuItemClick("ptab_decision_review");
                }}
              >
                PTAB Decision Review
              </Menu.ListItem>
            </>
          )}

          <Menu.Divider />
          <Menu.ListItem
            href="https://www.lexisnexisip.com/virtual-onboarding-webinars/#patentadvisor"
            isExternalLink
            onClick={() => {
              recordMenuItemClick("training");
            }}
          >
            Training
          </Menu.ListItem>
          <Menu.ListItem
            href="https://www.surveymonkey.co.uk/r/T7S5BR9"
            isExternalLink
            onClick={() => {
              recordMenuItemClick("feedback");
            }}
          >
            Feedback
          </Menu.ListItem>
          <Menu.ListItem
            href="/app.php#release-notes"
            onClick={() => {
              recordMenuItemClick("release_notes");
            }}
          >
            Release Notes
          </Menu.ListItem>
          {user.hasActiveBrowserPlugin ? (
            <li>
              <button
                className={cn(menuStyles.listItem, menuStyles.listItem$button)}
                type="button"
                onClick={() => {
                  recordMenuItemClick("sign_out");
                  setIsLogoutDialogOpen(true);
                }}
              >
                Sign Out
              </button>
            </li>
          ) : (
            <Menu.ListItem
              href="/logout.php"
              onClick={() => {
                recordMenuItemClick("sign_out");
              }}
            >
              Sign Out
            </Menu.ListItem>
          )}
        </Menu.List>
      </Menu>

      <Modal
        title="Confirm"
        ariaLabel="Confirm"
        isOpen={isLogoutDialogOpen}
        onDismiss={() => setIsLogoutDialogOpen(false)}
        footer={
          <>
            <Button
              variant="outlined"
              size="xs"
              onClick={() => setIsLogoutDialogOpen(false)}
            >
              <span style={{ minWidth: 50 }}>Keep me signed in</span>
            </Button>
            <span style={{ paddingLeft: 6 }} />
            <Anchor href="/logout.php" style={{ textDecoration: "none" }}>
              <Button variant="contained" size="xs">
                Sign out
              </Button>
            </Anchor>
          </>
        }
      >
        By signing out of LexisNexis PatentAdvisor, you are disabling the
        ability to
        <br />
        automatically log in via the PatentAdvisor extension. The next time you
        visit
        <br />
        PatentAdvisor, you’ll have to manually log in.
      </Modal>
    </>
  );
}

type SearchLinkType = LinkType & {
  permission: keyof SearchPermissions;
};

const searchMenuLinks: SearchLinkType[] = [
  { href: "/examiner-search.php", name: "Examiner", permission: "examiner" },
  { href: "/quickpair/", name: "QuickPAIR", permission: "quickPAIR" },
  { href: "/art-unit-search.php", name: "Art Unit", permission: "artUnit" },
  {
    href: "/assignee-tree-search.php",
    name: "Assignee",
    permission: "assigneeApplicant",
  },
  {
    href: "/law-firm-tree-search.php",
    name: "Law Firm",
    permission: "lawFirm",
  },
  {
    href: "/tech-center-navigator/",
    name: "Tech Center Navigator",
    permission: "pathways",
  },
  {
    href: "/file-wrappers.php",
    name: "File Wrappers",
    permission: "fileWrapper",
  },
  {
    href: "/#ptab-decisions",
    name: "PTAB Decisions",
    permission: "ptab",
  },
];

type BusinessDevelopmentLinkType = LinkType & {
  isVisible: (userPermissions: User["permissions"]) => boolean;
};

const businessDevelopmentMenuLinks: BusinessDevelopmentLinkType[] = [
  {
    href: "/smart-reports/law-firm-performance.php",
    name: "Law Firm Report Card",
    isVisible: (permissions) => permissions.smartReports,
  },
  {
    href: "/smart-reports/benchmarking-report.php?type=outside",
    name: "Outside Counsel Evaluation",
    isVisible: (permissions) => permissions.smartReports,
  },
  {
    href: "/smart-reports/benchmarking-report.php?type=tech-space",
    name: "Tech Space Benchmarking",
    isVisible: (permissions) => permissions.smartReports,
  },
  {
    href: "/app.php#licensing-reports/setup",
    name: "Examiner Rejection Analysis",
    isVisible: (permissions) => permissions.licensingReports,
  },
];

function isMenuActive(menuLinks: LinkType[]) {
  const currentHref = window.location.href;
  return menuLinks.some((link) => currentHref.includes(link.href));
}
