import React from "react";
import { useQuery } from "react-query";

import { Briefcase, fetchAppCounts } from "domain/briefcases";

export default function useBriefcasesAppCounts(
  briefcases: Briefcase[] = [],
  isEnabled = true
) {
  const queryKey = briefcases
    .map((b) => b.uuid)
    .sort()
    .join(",");

  const { data: appCounts = {} } = useQuery(
    ["briefcasesAppCounts", queryKey],
    () => fetchAppCounts(briefcases),
    { enabled: isEnabled }
  );

  return appCounts;
}
