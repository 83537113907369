import React, { useState } from "react";

import Text from "atoms/Text";
import TextArea from "molecules/TextArea";
import Button from "components/Button";
import { search as searchPathways } from "integration/pathways";
import { getAnalytics } from "domain/analytics";

import { TabPanel } from ".";

export interface Props {}

export default function TechCenterNavigatorForm({}: Props) {
  const [inventionText, setInventionText] = useState("");

  const submit = () => {
    if (!inventionText) {
      return;
    }

    recordFormSubmission(inventionText);
    searchPathways(inventionText);
  };

  return (
    <TabPanel>
      <div style={{ display: "flex", flexDirection: "column", width: 600 }}>
        <Text as="h2" size={21} style={{ lineHeight: "38px" }}>
          <b>Tech Center Navigator</b>
        </Text>
        <Text style={{ marginBottom: 8, lineHeight: "21px" }}>
          Tech Center Navigator uses the information you provide about your
          invention to predict which tech centers your application may be
          assigned to. Draft your application with the intention of finding a
          favorable Tech Center Group. For the most accurate results, provide as
          much information as you can.
        </Text>
        <TextArea
          label=""
          placeholder="Enter information about the invention (title, abstract, claims, entire application, etc.)"
          value={inventionText}
          onChange={(e) => setInventionText(e.target.value)}
          rows={10}
          style={{ marginBottom: 40, resize: "none" }}
        />
        <Button
          id="homepageTechCenterNavBtnSubmit"
          leftIcon="magnifier"
          type="submit"
          variant="brand"
          isDisabled={!inventionText}
          style={{ marginLeft: "auto" }}
          onClick={submit}
        >
          Search
        </Button>
      </div>
    </TabPanel>
  );
}

function recordFormSubmission(query: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "query_tech_center_navigator",
    interfaceValue: "search",
    action: "click",
    context: { queryLength: query.length },
  });
}
