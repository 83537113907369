import React, { ReactNode } from "react";
import cn from "classnames";

import Menu from "./Menu";
import Item from "./Item";
import styles from "./AppDrawer.module.sass";
import { recordMenuItemClick } from "./analytics";

const bigLinks = [
  {
    href: "https://go.patentsight.com/BI2.0/bi/public/login.html",
    analyticsLabel: "patent_sight",
    children: (
      <>
        PatentSight<sup className={styles.sup$big}>&reg;</sup>
      </>
    ),
  },

  {
    href: "https://signin.lexisnexis.com/lnaccess/app/signin?aci=rt&back=http://www.totalpatentone.com%2F",
    analyticsLabel: "total_patent_one",
    children: (
      <>
        TotalPatent One<sup className={styles.sup$big}>&reg;</sup>
      </>
    ),
  },
  {
    href: "https://www.patentoptimizer.com",
    analyticsLabel: "patent_optimizer",
    children: (
      <>
        PatentOptimizer<sup className={styles.sup$big}>&reg;</sup>
      </>
    ),
  },
  {
    href: "https://app.cipher.ai",
    analyticsLabel: "cipher",
    children: (
      <>
        Classification
      </>
    ),
  },
  {
    href: "https://platform.iplytics.com/login",
    analyticsLabel: "iplytics",
    children: (
      <>
        IPlytics<sup className={styles.sup$big}>&trade;</sup>
      </>
    ),
  },
  {
    href: "https://td.lexisnexisip.com/",
    analyticsLabel: "techdiscovery",
    children: (
      <>
        TechDiscovery
      </>
    ),
  },
];
const smallLinks = [
  {
    href: "https://www.lexisnexisip.com/solutions/ip-datadirect/",
    analyticsLabel: "ip_data_direct",
    children: "IP DataDirect",
  },
  {
    href: "https://www.law360.com/ip",
    analyticsLabel: "law_360",
    children: (
      <>
        Law360<sup className={styles.sup$small}>&reg;</sup>
      </>
    ),
  },
  {
    href: "https://plus.lexis.com/",
    analyticsLabel: "lexis+",
    children: (
      <>
        Lexis+<sup className={styles.sup$small}>&reg;</sup>
      </>
    ),
  },
  {
    href: "https://lexmachina.com/",
    analyticsLabel: "lex_machina",
    children: (
      <>
        Lex Machina<sup className={styles.sup$small}>&reg;</sup>
      </>
    ),
  },
  {
    href: "https://www.lexisnexisip.com/solutions/patent-prosecution/pair-watch/",
    analyticsLabel: "pair_watch",
    children: "PAIR Watch",
  },
];

const AppDrawerLink = ({
  isBig,
  href,
  children,
  onBeforeNavigate,
}: {
  href: string;
  children: ReactNode;
  isBig?: boolean;
  onBeforeNavigate?: () => void;
}) => (
  <li
    key={href}
    className={cn(styles.listItem, isBig && styles.listItem$big)}
    onClick={onBeforeNavigate}
  >
    <a href={href} className={styles.listItemLink} target="_blank">
      {children}
    </a>
  </li>
);

const AppDrawerDropdown = () => (
  <section className={styles.appDrawer}>
    <ul className={styles.list}>
      {bigLinks.map((l) => (
        <AppDrawerLink
          href={l.href}
          isBig
          onBeforeNavigate={() => {
            recordMenuItemClick(l.analyticsLabel);
          }}
        >
          {l.children}
        </AppDrawerLink>
      ))}
    </ul>
    <Menu.Divider isVertical />
    <ul className={styles.list}>
      {smallLinks.map((l) => (
        <AppDrawerLink
          href={l.href}
          onBeforeNavigate={() => {
            recordMenuItemClick(l.analyticsLabel);
          }}
        >
          {l.children}
        </AppDrawerLink>
      ))}
    </ul>
  </section>
);

const AppDrawer = () => (
  <Menu
    trigger={
      <Item variant="thin">
        <Menu.Icon variant="appDrawer" />
      </Item>
    }
    dropdownPosition="bottomRight"
  >
    <AppDrawerDropdown />
  </Menu>
);

export default AppDrawer;
