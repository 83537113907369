import React from "react";
import cn from "classnames";

import LNIPLogo from "components/LNIPLogo";
import RELXLogo from "components/RELXLogo";
import { getAnalytics } from "domain/analytics";

import styles from "./module.sass";

export type Props = {
  variant?: "grey" | "white";
  position?: "static" | "absolute";
};

const Footer = ({ variant = "grey", position = "absolute" }: Props) => (
  <div
    className={cn(
      styles.row,
      styles[`row$${variant}`],
      styles[`row$${position}`]
    )}
  >
    <footer className={styles.footer}>
      <div className={styles.quarter}>
        <LNIPLogo variant="base" />
      </div>
      <div className={styles.quarter}>
        <ul className={styles.list}>
          <li>
            <a
              href="https://www.lexisnexis.com/en-us/terms/privacy-policy.page"
              className={styles.link}
              target="_blank"
              onClick={() => {
                recordLinkClick("privacy_policy");
              }}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              className={cn("ot-sdk-show-settings", styles.link)}
              onClick={() => {
                recordLinkClick("cookies");
              }}
            >
              Cookies
            </a>
          </li>
          <li>
            <a
              href="https://www.lexisnexis.com/terms/"
              className={styles.link}
              target="_blank"
              onClick={() => {
                recordLinkClick("website_terms_and_conditions");
              }}
            >
              Website Terms and Conditions
            </a>
          </li>
          <li>
            <a
              href="https://go.lexisnexisip.com/patadv_terms"
              className={styles.link}
              target="_blank"
              onClick={() => {
                recordLinkClick("patent_advisor_terms_and_conditions");
              }}
            >
              PatentAdvisor Terms and Conditions
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.quarter}>
        <strong className={styles.support}>Customer support</strong>
        <ul className={styles.list}>
          <li>1-877-412-3987</li>
          <li>
            <a
              href="mailto:support@lexisnexisip.com"
              className={styles.link}
              onClick={() => {
                recordLinkClick("support@lexisnexisip.com");
              }}
            >
              support@lexisnexisip.com
            </a>
          </li>
          <li>
            <a
              href="https://www.lexisnexisip.com/contact/"
              className={styles.link}
              target="_blank"
              onClick={() => {
                recordLinkClick("contact_us");
              }}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.quarter}>
        <small className={styles.copyright}>
          Copyright &copy; {new Date().getFullYear()} LexisNexis&reg;
          <br />
          All Rights Reserved
        </small>
        <div className={styles.relxHolder}>
          <RELXLogo />
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;

function recordLinkClick(label: string) {
  getAnalytics().recordEvent({
    pageName: "footer",
    interfaceEvent: "link_click",
    interfaceValue: label,
    action: "click",
  });
}
