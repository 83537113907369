import React, {
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  RefObject,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";

import Text, { Props as TextProps } from "atoms/Text";
import Rectangle from "atoms/Rectangle";
import {
  AppealEvent,
  DetaillessEvent,
  Event,
  OfficeActionEvent,
} from "domain/applications";
import Popover from "atoms/Popover";
import useClickOutside from "hooks/useClickOutside";
import { format as formatDate } from "domain/dates";
import HorizontalRule from "atoms/HorizontalRule";

import { useLabelWidthCorrection } from "../hooks";
import { ITimelineViewModel, TimelineViewModel } from "../models/timeline";
import { createEventViewModel } from "../models/events";
import styles from "./module.sass";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  timelineViewModel: ITimelineViewModel;
}

export default function ExpandedLine({ timelineViewModel, ...rest }: Props) {
  const [expandedEvent, setExpandedEvent] = useState<Event | null>(null);
  const [isExaminerPopoverExpanded, setIsExaminerPopoverExpanded] =
    useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleExpandedEventChange = (event: Event | null) => {
    setExpandedEvent(event);

    const isNowExpanded = event !== null;
    if (isNowExpanded) {
      setIsExaminerPopoverExpanded(false);
    }
  };
  const handleIsExaminerExpandedChange = (isExpanded: boolean) => {
    setIsExaminerPopoverExpanded(isExpanded);

    if (isExpanded) {
      setExpandedEvent(null);
    }
  };

  useClickOutside(popoverRef, () => {
    setExpandedEvent(null);
    setIsExaminerPopoverExpanded(false);
  });

  return (
    <div {...rest}>
      <OfficeActionsSwimlane
        popoverRef={popoverRef}
        timelineViewModel={timelineViewModel}
        expandedEvent={expandedEvent}
        onExpandedEventChange={handleExpandedEventChange}
      />
      <RCEsSwimlane
        popoverRef={popoverRef}
        timelineViewModel={timelineViewModel}
        expandedEvent={expandedEvent}
        onExpandedEventChange={handleExpandedEventChange}
      />
      <AppealsSwimlane
        popoverRef={popoverRef}
        timelineViewModel={timelineViewModel}
        expandedEvent={expandedEvent}
        onExpandedEventChange={handleExpandedEventChange}
      />
      <InterviewsSwimlane
        popoverRef={popoverRef}
        timelineViewModel={timelineViewModel}
        expandedEvent={expandedEvent}
        onExpandedEventChange={handleExpandedEventChange}
      />
      <ExaminersAverageTimeToAllowanceSwimlane
        popoverRef={popoverRef}
        timelineViewModel={timelineViewModel}
        isExpanded={isExaminerPopoverExpanded}
        onIsExpandedChange={handleIsExaminerExpandedChange}
      />
    </div>
  );
}

function OfficeActionsSwimlane({
  timelineViewModel,
  expandedEvent,
  popoverRef,
  onExpandedEventChange,
}: {
  timelineViewModel: ITimelineViewModel;
  expandedEvent: Event | null;
  popoverRef: RefObject<HTMLDivElement>;
  onExpandedEventChange: (event: Event | null) => void;
}) {
  const officeActionEvents = timelineViewModel.events.filter(
    (e): e is OfficeActionEvent => e.name === "officeAction"
  );
  const labelRef = useRef<HTMLDivElement>(null);
  const firstRectangleRef = useRef<HTMLDivElement>(null);
  const labelWidthCorrection = useLabelWidthCorrection(
    labelRef,
    firstRectangleRef
  );

  return (
    <Swimlane>
      <SwimlaneLabel
        ref={labelRef}
        title={`Office Actions (${officeActionEvents.length})`}
        style={{ width: `calc(107px - ${labelWidthCorrection}px)` }}
      >
        Office Actions ({officeActionEvents.length})
      </SwimlaneLabel>
      {officeActionEvents.map((event, i) => {
        const eventViewModel = createEventViewModel(event);
        const eventOrdinalNumber =
          timelineViewModel.getEventOrdinalNumberAmongSameType(event);

        const { rejectionDate, responseDate } = event;
        const nextDate =
          timelineViewModel.getEventWithRangeRightBoundaryDate(event);
        const rejectionRatio = responseDate
          ? (responseDate.getTime() - rejectionDate.getTime()) /
            (nextDate.getTime() - rejectionDate.getTime())
          : 1;

        return (
          <div
            key={i}
            style={{
              position: "absolute",
              top: 0,
              left: timelineViewModel.getEventOffset(event),
              width: timelineViewModel.getEventWithRangeWidth(event),
              cursor: "pointer",
            }}
          >
            <SwimlanePopover
              ref={expandedEvent === event ? popoverRef : undefined}
              content={() =>
                eventViewModel.renderPopoverContent(eventOrdinalNumber)
              }
              isVisible={expandedEvent === event}
              onDismiss={() => onExpandedEventChange(null)}
            >
              <Rectangle
                ref={i === 0 ? firstRectangleRef : undefined}
                style={{
                  width: "100%",
                  height: 20,
                  background: `linear-gradient(to right, var(--rejection-color) ${
                    rejectionRatio * 100
                  }%, var(--response-color) ${rejectionRatio * 100}%`,
                }}
                className={cn(styles.rectangle, styles.rectangle$officeAction)}
                onClick={() => onExpandedEventChange(event)}
              />
            </SwimlanePopover>
          </div>
        );
      })}
    </Swimlane>
  );
}

function RCEsSwimlane({
  timelineViewModel,
  expandedEvent,
  popoverRef,
  onExpandedEventChange,
}: {
  timelineViewModel: ITimelineViewModel;
  expandedEvent: Event | null;
  popoverRef: RefObject<HTMLDivElement>;
  onExpandedEventChange: (event: Event | null) => void;
}) {
  const rceEvents = timelineViewModel.events.filter(
    (e): e is DetaillessEvent & { name: "RCE" } => e.name === "RCE"
  );
  const labelRef = useRef<HTMLDivElement>(null);
  const firstRectangleRef = useRef<HTMLDivElement>(null);
  const labelWidthCorrection = useLabelWidthCorrection(
    labelRef,
    firstRectangleRef
  );

  return (
    <Swimlane>
      <SwimlaneLabel
        ref={labelRef}
        title={`Requests for Continued Examination (${rceEvents.length})`}
        style={{ width: `calc(52px - ${labelWidthCorrection}px)` }}
      >
        RCEs ({rceEvents.length})
      </SwimlaneLabel>
      {rceEvents.map((event, i) => {
        const eventViewModel = createEventViewModel(event);
        const eventOrdinalNumber =
          timelineViewModel.getEventOrdinalNumberAmongSameType(event);

        return (
          <div
            key={i}
            style={{
              position: "absolute",
              top: 0,
              left: timelineViewModel.getEventOffset(event),
              width: timelineViewModel.getEventWithRangeWidth(event),
              cursor: "pointer",
            }}
          >
            <SwimlanePopover
              ref={expandedEvent === event ? popoverRef : undefined}
              content={() =>
                eventViewModel.renderPopoverContent(eventOrdinalNumber)
              }
              isVisible={expandedEvent === event}
              onDismiss={() => onExpandedEventChange(null)}
            >
              <Rectangle
                ref={i === 0 ? firstRectangleRef : undefined}
                style={{
                  width: "100%",
                  height: 20,
                  backgroundColor: "var(--bg-color)",
                }}
                className={cn(styles.rectangle, styles.rectangle$rce)}
                onClick={() => onExpandedEventChange(event)}
              />
            </SwimlanePopover>
          </div>
        );
      })}
    </Swimlane>
  );
}

function AppealsSwimlane({
  timelineViewModel,
  expandedEvent,
  popoverRef,
  onExpandedEventChange,
}: {
  timelineViewModel: ITimelineViewModel;
  expandedEvent: Event | null;
  popoverRef: RefObject<HTMLDivElement>;
  onExpandedEventChange: (event: Event | null) => void;
}) {
  const appealEvents = timelineViewModel.events.filter(
    (e): e is AppealEvent => e.name === "appeal"
  );
  const labelRef = useRef<HTMLDivElement>(null);
  const firstRectangleRef = useRef<HTMLDivElement>(null);
  const labelWidthCorrection = useLabelWidthCorrection(
    labelRef,
    firstRectangleRef
  );

  return (
    <Swimlane>
      <SwimlaneLabel
        ref={labelRef}
        title={`Appeals (${appealEvents.length})`}
        style={{ width: `calc(69px - ${labelWidthCorrection}px)` }}
      >
        Appeals ({appealEvents.length})
      </SwimlaneLabel>
      {appealEvents.map((event, i) => {
        const eventViewModel = createEventViewModel(event);
        const eventOrdinalNumber =
          timelineViewModel.getEventOrdinalNumberAmongSameType(event);

        return (
          <div
            key={i}
            style={{
              position: "absolute",
              top: 0,
              left: timelineViewModel.getEventOffset(event),
              width: timelineViewModel.getEventWithRangeWidth(event),
              cursor: "pointer",
            }}
          >
            <SwimlanePopover
              ref={expandedEvent === event ? popoverRef : undefined}
              content={() =>
                eventViewModel.renderPopoverContent(eventOrdinalNumber)
              }
              isVisible={expandedEvent === event}
              onDismiss={() => onExpandedEventChange(null)}
            >
              <Rectangle
                ref={i === 0 ? firstRectangleRef : undefined}
                className={cn(styles.rectangle, styles.rectangle$appeal)}
                style={{
                  width: "100%",
                  height: 20,
                  backgroundColor: "var(--bg-color)",
                }}
                onClick={() => onExpandedEventChange(event)}
              />
            </SwimlanePopover>
          </div>
        );
      })}
    </Swimlane>
  );
}

function InterviewsSwimlane({
  timelineViewModel,
  expandedEvent,
  popoverRef,
  onExpandedEventChange,
}: {
  timelineViewModel: ITimelineViewModel;
  expandedEvent: Event | null;
  popoverRef: RefObject<HTMLDivElement>;
  onExpandedEventChange: (event: Event | null) => void;
}) {
  const interviewEvents = timelineViewModel.events.filter(
    (e) => e.name === "interview"
  );
  const labelRef = useRef<HTMLDivElement>(null);
  const firstRectangleRef = useRef<HTMLDivElement>(null);
  const labelWidthCorrection = useLabelWidthCorrection(
    labelRef,
    firstRectangleRef
  );

  return (
    <Swimlane>
      <SwimlaneLabel
        ref={labelRef}
        title={`Interviews (${interviewEvents.length})`}
        style={{ width: `calc(85px - ${labelWidthCorrection}px)` }}
      >
        Interviews ({interviewEvents.length})
      </SwimlaneLabel>
      {interviewEvents.map((event, i) => {
        const eventViewModel = createEventViewModel(event);
        const eventOrdinalNumber =
          timelineViewModel.getEventOrdinalNumberAmongSameType(event);

        return (
          <div
            key={i}
            style={{
              position: "absolute",
              top: 2,
              left: timelineViewModel.getEventOffset(event, "6px"),
              cursor: "pointer",
            }}
          >
            <SwimlanePopover
              ref={expandedEvent === event ? popoverRef : undefined}
              content={() =>
                eventViewModel.renderPopoverContent(eventOrdinalNumber)
              }
              isVisible={expandedEvent === event}
              onDismiss={() => onExpandedEventChange(null)}
            >
              <Rectangle
                ref={i === 0 ? firstRectangleRef : undefined}
                rotation={45}
                className={cn(styles.rectangle, styles.rectangle$interview)}
                style={{ backgroundColor: "var(--bg-color)" }}
                onClick={() => onExpandedEventChange(event)}
              />
            </SwimlanePopover>
          </div>
        );
      })}
    </Swimlane>
  );
}

function ExaminersAverageTimeToAllowanceSwimlane({
  timelineViewModel,
  popoverRef,
  isExpanded,
  onIsExpandedChange,
}: {
  timelineViewModel: ITimelineViewModel;
  popoverRef: RefObject<HTMLDivElement>;
  isExpanded: boolean;
  onIsExpandedChange: (isExpanded: boolean) => void;
}) {
  const { expectedApprovalWindow, readableExaminersExpectedApprovalWindow } =
    timelineViewModel;
  if (!expectedApprovalWindow) {
    return (
      <Swimlane>
        <SwimlaneLabel>Examiner's avg. time to allowance</SwimlaneLabel>
      </Swimlane>
    );
  }

  const labelRef = useRef<HTMLDivElement>(null);
  const firstRectangleRef = useRef<HTMLDivElement>(null);
  const labelWidthCorrection = useLabelWidthCorrection(
    labelRef,
    firstRectangleRef
  );
  const { start, middle, end } = expectedApprovalWindow;

  const isRangeShown = start.getTime() !== end.getTime();

  return (
    <Swimlane className={styles.examinerSwimlane}>
      <SwimlaneLabel
        ref={labelRef}
        title="Examiner's average time to allowance"
        style={{
          width: `calc(205px - ${labelWidthCorrection}px)`,
        }}
      >
        Examiner's avg. time to allowance
      </SwimlaneLabel>
      {isRangeShown && (
        <Rectangle
          ref={firstRectangleRef}
          title="The Estimated Approval Window represents one standard deviation from the examiner's average"
          style={{
            position: "absolute",
            top: 0,
            left: timelineViewModel.getDateOffset(start),
            width: timelineViewModel.getWidth(start, end),
            boxSizing: "border-box",
            height: 20,
            backgroundColor: "#e1f7fa",
            borderStyle: "solid",
            borderTop: 1,
            borderBottom: 1,
            borderColor: "#21bbd2",
            cursor: "pointer",
          }}
          onClick={() => onIsExpandedChange(true)}
        />
      )}

      <div
        style={{
          position: "absolute",
          top: 2,
          left: timelineViewModel.getDateOffset(middle, "7px"),
          cursor: "pointer",
        }}
      >
        <SwimlanePopover
          ref={isExpanded ? popoverRef : undefined}
          content={() => (
            <ExaminerAverageTimeToAllowancePopover
              readableExaminersExpectedApprovalWindow={
                readableExaminersExpectedApprovalWindow
              }
            />
          )}
          isVisible={isExpanded}
          onDismiss={() => onIsExpandedChange(false)}
        >
          <Rectangle
            rotation={45}
            className={cn(
              styles.rectangle,
              styles.rectangle$averageApprovalTime
            )}
            style={{ backgroundColor: "var(--bg-color)" }}
            onClick={() => onIsExpandedChange(true)}
          />
        </SwimlanePopover>
      </div>
    </Swimlane>
  );
}

function ExaminerAverageTimeToAllowancePopover({
  readableExaminersExpectedApprovalWindow,
}: {
  readableExaminersExpectedApprovalWindow: TimelineViewModel["readableExaminersExpectedApprovalWindow"];
}) {
  if (!readableExaminersExpectedApprovalWindow) {
    return <></>;
  }

  const { lowerBoundary, average, upperBoundary } =
    readableExaminersExpectedApprovalWindow;

  return (
    <div style={{ width: 241 }}>
      <Text
        size={16}
        weight="bold"
        style={{ marginBottom: 12, lineHeight: "20px" }}
      >
        Examiner's Average Time to Allowance
      </Text>
      <Text
        as="div"
        size={14}
        style={{ marginBottom: 16, lineHeight: "21px", whiteSpace: "nowrap" }}
      >
        <b>Average: </b>
        {average}
        {/* {formatDate(middle, "Y-m-d")} */}
      </Text>
      <HorizontalRule style={{ marginBottom: 10 }} />

      <Text as="div" size={14} style={{ lineHeight: "21px" }}>
        <b>Estimated Approval Window:</b>
        <br />
        {lowerBoundary} – {upperBoundary}
        {/* {formatDate(start, "Y-m-d")} — {formatDate(end, "Y-m-d")} */}
      </Text>
    </div>
  );
}

interface SwimlanePopoverProps {
  content: ReactNode;
  children: ReactElement;
  isVisible: boolean;
  onDismiss: () => void;
}

const SwimlanePopover = forwardRef<HTMLDivElement, SwimlanePopoverProps>(
  (props, ref) => (
    <div>
      <Popover
        ref={ref}
        offset={[0, 16]}
        style={{ cursor: "auto", maxWidth: "auto", backgroundColor: "white" }}
        isDismissable
        rendersWhenInvisible={false}
        {...props}
      />
    </div>
  )
);

function Swimlane(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "stretch",
        height: 20,
        marginBottom: 9,
        // backgroundColor: "#eee",
        backgroundColor: "rgba(170, 170, 170, 0.2)",
        // zIndex: -2,
        zIndex: 1,
      }}
      {...props}
    />
  );
}

const SwimlaneLabel = forwardRef<HTMLDivElement, TextProps>(
  ({ children, style, ...rest }, ref) => (
    <Text
      size={14}
      style={{
        backgroundColor: "white",
        padding: "0 6px 30px 0",
        width: "max-content",
        overflow: "hidden",
        ...style,
      }}
      {...rest}
    >
      <div
        ref={ref}
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Text>
  )
);
