import React, { HTMLAttributes } from "react";

import { Back as BackIcon } from "components/Icons";

import styles from "./module.sass";

export type Props = HTMLAttributes<HTMLButtonElement>;

export default function Back({ children, ...rest }: Props) {
  return (
    <button type="button" className={styles.back} {...rest}>
      <BackIcon className={styles.arrow} />
      <span className={styles.text}>{children || "Back"}</span>
    </button>
  );
}
