import { basicFetch, SERVER_RESPONSE, STATUSES } from "../ptabDecisions/index";

import {
  DecisionDetailsType,
  IssueSetType,
  DailyReviewType,
  MonthlyReviewType,
  TagType,
  ExtTagType,
  ApplicationLookupType,
} from "./models";
import { JudgeType } from "../ptabDecisions/models";
import { DictionaryItemType } from "components/PTABDecisions/components/utils";

export const fetchDecisionDetails = async (
  decision_id: string
): Promise<DecisionDetailsType | null> => {
  let formData = new FormData();
  formData.append("function", "decision_details");
  formData.append("decision_id", decision_id);

  const resp = await basicFetch(formData);
  return resp?.data;
};

const toFormData = (data: { [x: string]: any }) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export const makeDecisionReviewed = async ({
  userId,
  decisionId,
}: {
  userId?: number;
  decisionId?: number;
}) => {
  if (userId && decisionId) {
    await basicFetch(
      toFormData({
        function: "update_decision_status_reviewed",
        user_id: userId,
        decision_id: decisionId,
      })
    );
  }
};

export const editDecisionDetails = async ({
  details,
  userId,
}: {
  details: DecisionDetailsType;
  userId?: number;
}): Promise<boolean> => {
  const { decision_id, ...rest } = details; //fix: api crashes if there is any of these props

  const resp = await basicFetch(
    toFormData({
      function: "edit_decision",
      decision_id: `${decision_id}`,
      arr_data: JSON.stringify(rest),
    })
  );

  if (userId && resp?.status === STATUSES.OK) {
    await makeDecisionReviewed({ decisionId: decision_id, userId: userId });
  }

  return resp?.status === STATUSES.OK;
};

export const findDecisionId = async (
  applicationNumber: string
): Promise<Array<ApplicationLookupType>> => {
  let formData = new FormData();
  formData.append("function", "decision_id_by_app_num");
  formData.append("application_number", `${applicationNumber}`);

  const resp = await basicFetch(formData);

  if (resp?.status !== STATUSES.OK || !resp.data) {
    return [];
  } else {
    return resp?.data;
  }
};

export const getPdfBlob = async (decisionId: string): Promise<string> => {
  let formData = new FormData();
  formData.append("function", "get_pdf_blob");
  formData.append("decision_id", `${decisionId}`);

  const resp = await basicFetch(formData);
  return resp?.data;
};

export const fetchIssueSets = async (
  decisionId: string
): Promise<Array<IssueSetType>> => {
  let formData = new FormData();
  formData.append("function", "issue_set");
  formData.append("decision_id", `${decisionId}`);

  const resp = await basicFetch(formData);
  return resp?.data;
};

export const fetchTagSuggestions = async ({
  tagName,
  existingIssue,
}: {
  tagName: string;
  existingIssue?: string;
}): Promise<Array<TagType>> => {
  let formData = new FormData();
  formData.append("function", "claims_of_issue_tags_suggestion");
  formData.append("tag_name", `${tagName}`);
  existingIssue && formData.append("existing_issue", `${existingIssue}`);

  const resp = await basicFetch(formData);
  const res: Array<TagType> = resp?.data ? Object.values(resp?.data) : [];
  return res;
};

export const fetchExistingIssueOptions = async (): Promise<Array<string>> => {
  let formData = new FormData();
  formData.append("function", "existing_issue_options");

  const resp = await basicFetch(formData);
  const data: Array<{ id: string; issue: string }> = resp?.data ?? [];
  return data.map((x) => x.issue);
};

export const fetchOutcomeOptions = async (): Promise<
  Array<DictionaryItemType>
> => {
  let formData = new FormData();
  formData.append("function", "suggestion_list");

  const resp = await basicFetch(formData);
  if (resp?.data) {
    return Object.entries(resp.data).map((entry) => {
      const [key, value] = entry;
      return { value: `${value}`, label: `${key}` };
    });
  } else {
    return [];
  }
};

export const fetchTags = async (
  issueSetId: number
): Promise<Array<ExtTagType>> => {
  let formData = new FormData();
  formData.append("function", "issue_set_tags");
  formData.append("issue_set_id", issueSetId.toString());

  const resp = await basicFetch(formData);
  return resp?.data ?? [];
};

export const editTag = async ({
  tag,
  issueSetId,
}: {
  tag: ExtTagType;
  issueSetId: number;
}): Promise<void> => {
  const { id, tag_id: tagId, dispositive: isDispositive, legalSupport } = tag;
  let formData = new FormData();
  formData.append("function", "edit_issueset_tags");
  formData.append("tag_id", tagId.toString());
  formData.append("decision_issueset_id", issueSetId.toString());
  formData.append("decision_issueset_tag_id", id?.toString() ?? "");
  formData.append("dispositive", isDispositive ? "1" : "0");
  formData.append("legal_support", legalSupport ?? "");

  await basicFetch(formData);
};

export const createTag = async ({
  issueSetId,
  tagId,
  dispositive,
  legalSupport,
}: {
  issueSetId: number;
  tagId: number;
  dispositive: boolean;
  legalSupport: string;
}): Promise<void> => {
  let formData = new FormData();
  formData.append("function", "create_issue_set_tag");
  formData.append("tag_id", tagId.toString());
  formData.append("decision_issueset_id", issueSetId.toString());
  formData.append("dispositive", dispositive.toString());
  formData.append("legal_support", legalSupport);

  await basicFetch(formData);
};

export const deleteTag = async ({
  tag,
  issueSetId,
}: {
  tag: ExtTagType;
  issueSetId: number;
}): Promise<boolean> => {
  const { id, tag_id: tagId } = tag;
  let formData = new FormData();
  formData.append("function", "delete_issue_set_tags");
  formData.append("tag_id", tagId.toString());
  formData.append("decision_issueset_id", issueSetId.toString());
  formData.append("decision_issueset_tag_id", id?.toString() ?? "");

  const resp = await basicFetch(formData);

  return resp?.status === STATUSES.OK;
};

export const editIssueSet = async ({
  decision_id,
  data,
}: {
  decision_id: number;
  data: IssueSetType;
}): Promise<void> => {
  const decision_issueset_id = data?.issue_set_id?.toString() ?? "";

  let formData = new FormData();
  formData.append("function", "edit_issue_set");
  formData.append("decision_issueset_id", decision_issueset_id);

  const { non_substantive, claims_of_issue, issue, disposition } = data;

  formData.append(
    "arr_data",
    JSON.stringify({
      decision_id,
      non_substantive,
      claims_of_issue,
      issue,
      disposition,
      tag: [],
    })
  );

  await basicFetch(formData);
};

export const createIssueSet = async ({
  decision_id,
  data,
  tags,
}: {
  decision_id: number;
  data: IssueSetType;
  tags?: Array<{ tag_id: number; dispositive: boolean; legalSupport?: string }>;
}): Promise<void> => {
  let formData = new FormData();
  formData.append("function", "new_issue_set");

  const { non_substantive, claims_of_issue, issue, disposition } = data;

  formData.append(
    "arr_data",
    JSON.stringify({
      decision_id: decision_id,
      non_substantive,
      claims_of_issue,
      issue,
      disposition,
      tag:
        tags?.map((tag) => ({
          id: tag.tag_id,
          legal_support: tag.legalSupport,
          dispositive: tag.dispositive,
        })) ?? [],
    })
  );

  await basicFetch(formData);
};

export const deleteIssueSet = async ({
  issueSetId,
  userId,
  decisionId,
}: {
  issueSetId: number;
  userId?: number;
  decisionId?: number;
}): Promise<boolean> => {
  let formData = new FormData();
  formData.append("function", "delete_issue_set");
  formData.append("decision_issueset_id", `${issueSetId}`);

  const resp = await basicFetch(formData);

  if (userId && resp?.status === STATUSES.OK) {
    await makeDecisionReviewed({ decisionId: decisionId, userId: userId });
  }

  return resp?.status === STATUSES.OK;
};

export const fetchDecisionJudges = async (
  decisionId: string
): Promise<Array<JudgeType>> => {
  let formData = new FormData();
  formData.append("function", "judge_list");
  formData.append("decision_id", `${decisionId}`);

  const res = await basicFetch(formData);

  if (res && res.data) return res.data;
  else return [];
};

export const createDecisionJudge = async ({
  decisionId,
  userId,
  judge,
}: {
  decisionId?: number;
  userId?: number;
  judge: JudgeType;
}): Promise<SERVER_RESPONSE | undefined> => {
  let formData = new FormData();
  formData.append("function", "add_judge_to_decision");
  formData.append("decision_id", `${decisionId}`);
  formData.append("judge_id", `${judge.id}`);
  formData.append("judge_name", `${judge.name}`);

  const resp = await basicFetch(formData);

  if (userId && resp?.status === STATUSES.OK) {
    await makeDecisionReviewed({ decisionId: decisionId, userId: userId });
  }

  return resp;
};

export const deleteDecisionJudge = async ({
  decisionId,
  userId,
  judgeId,
}: {
  decisionId?: number;
  userId?: number;
  judgeId: number;
}): Promise<boolean> => {
  let formData = new FormData();
  formData.append("function", "delete_judge");
  formData.append("decision_id", `${decisionId}`);
  formData.append("judge_id", `${judgeId}`);

  const resp = await basicFetch(formData);

  if (userId && resp?.status === STATUSES.OK) {
    await makeDecisionReviewed({ decisionId: decisionId, userId: userId });
  }

  return resp?.status === STATUSES.OK;
};

export const fetchMonthlyReviews = async ({
  month,
  year,
}: {
  month?: string;
  year?: string;
}): Promise<Array<MonthlyReviewType>> => {
  let formData = new FormData();
  formData.append("function", "monthly_review_import");
  formData.append("year", `${year}`);
  formData.append("month", `${month}`);

  const res = await basicFetch(formData);

  if (res && res.data) return res.data;
  else return [];
};

export const fetchDailyReviews = async ({
  decisionDate,
}: {
  decisionDate?: string;
}): Promise<Array<DailyReviewType>> => {
  let formData = new FormData();
  formData.append("function", "daily_review");
  formData.append("date", `${decisionDate}`);
  const res = await basicFetch(formData);

  if (res && res.status === STATUSES.OK) {
    return res.data;
  } else {
    return [];
  }
};
