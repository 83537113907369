import React, { CSSProperties, ReactNode } from "react";
import useIsLoggedIn from "hooks/useIsLoggedIn";

import MainContainer from "atoms/Layout";
import SignedIn from "./SignedIn";
import SignedOut from "./SignedOut";

import styles from "./module.sass";
import PageLoader from "molecules/PageLoader";

export default function ThankYouPage() {
  const { isLoggedIn, status } = useIsLoggedIn();

  return (
    <MainContainer variant="lightGrey">
      {status === "loading" ? (
        <PageLoader isLoading={status === "loading"} />
      ) : (
        <>{isLoggedIn ? <SignedIn /> : <SignedOut />}</>
      )}
    </MainContainer>
  );
}

export const Row = ({
  subtitleStyle,
  subtitle,
  children,
  image,
}: {
  subtitleStyle: CSSProperties;
  subtitle: string;
  children: ReactNode;
  image: string;
}) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "429px 440px",
      gridGap: "48px",
      fontSize: "15px",
    }}
  >
    <div style={{ letterSpacing: 0 }}>
      <h2 className={styles.subtitle} style={subtitleStyle}>
        {subtitle}
      </h2>
      {children}
    </div>
    <div style={{ textAlign: "center" }}>
      <img src={image} alt={`PatentAdvisor Extension - ${subtitle}`} />
    </div>
  </div>
);

export const Divider = ({ style }: { style: string }) => (
  <div style={{ height: "1px", background: "#d5d5d5", margin: style }}></div>
);
