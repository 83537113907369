import zxcvbn from "zxcvbn";

enum PasswordStrength {
  None = 0,
  Low,
  Medium,
  High,
  VeryHigh,
}

class Password {
  private value: string;
  private blacklistedTokens: string[] = [];

  constructor(value: string, blacklist: string[] = []) {
    this.value = value;

    blacklist
      .map((b) => b.toLowerCase())
      .forEach((b) => {
        const words = b.match(/\b(\w{5,})\b/g) ?? [];
        this.blacklistedTokens.push(...words);
      });
  }

  get strength(): PasswordStrength {
    const result = zxcvbn(this.value);
    return result.score;
  }

  get isBlacklisted(): boolean {
    const lowercaseValue = this.value.toLowerCase();
    return this.blacklistedTokens.some((t) => lowercaseValue.includes(t));
  }
}

export { PasswordStrength, Password };
