import React, { useReducer } from "react";

import { SubmittedTrialRequest } from "domain/trial";
import PageTitle from "components/PageTitle";
import PendingTrialRequests from "organisms/PendingTrialRequests";
import ReviewedTrialRequests from "organisms/ReviewedTrialRequests";
import TrialRequestReviewDialog from "organisms/TrialRequestReviewDialog";

import styles from "./module.sass";

export type Props = {
  trialRequests: SubmittedTrialRequest[];
  onReject: (trialRequest: SubmittedTrialRequest, note: string) => void;
  onApprove: (trialRequest: SubmittedTrialRequest, note: string) => void;
  onHide: (trialRequest: SubmittedTrialRequest, note: string) => void;
};

export default function TrialUserApproval({
  trialRequests = [],
  onReject = () => {},
  onApprove = () => {},
  onHide = () => {},
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleReview = (trialRequest: SubmittedTrialRequest, note: string) => {
    if (state.reviewMode === "approving") {
      onApprove(trialRequest, note);
      dispatch({ type: "stopReviewing" });
      return;
    }

    if (state.reviewMode === "hiding") {
      onHide(trialRequest, note);
      dispatch({ type: "stopReviewing" });
      return;
    }

    onReject(trialRequest, note);
    dispatch({ type: "stopReviewing" });
  };

  return (
    <div className={styles.trialUserApproval}>
      <PageTitle style={{ marginBottom: 12 }}>Trial User Approval</PageTitle>
      <PendingTrialRequests
        trialRequests={trialRequests}
        onApprove={(trialRequest) => {
          dispatch({ type: "startApproving", trialRequest });
        }}
        onReject={(trialRequest) => {
          dispatch({ type: "startRejecting", trialRequest });
        }}
        onHide={(trialRequest) => {
          dispatch({ type: "startHiding", trialRequest });
        }}
        style={{ marginBottom: 80 }}
      />
      <ReviewedTrialRequests trialRequests={trialRequests} />
      {state.trialRequestBeingReviewed !== null && (
        <TrialRequestReviewDialog
          trialRequest={state.trialRequestBeingReviewed}
          action={getReviewActionName(state)}
          onDismiss={() => {
            dispatch({ type: "stopReviewing" });
          }}
          onReview={handleReview}
        />
      )}
    </div>
  );
}

type State = {
  trialRequestBeingReviewed: SubmittedTrialRequest | null;
  reviewMode: "approving" | "rejecting" | "hiding";
};

type Action =
  | { type: "startApproving"; trialRequest: SubmittedTrialRequest }
  | { type: "startRejecting"; trialRequest: SubmittedTrialRequest }
  | { type: "startHiding"; trialRequest: SubmittedTrialRequest }
  | { type: "stopReviewing" };

const initialState: State = {
  trialRequestBeingReviewed: null,
  reviewMode: "approving",
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "startApproving":
      return {
        trialRequestBeingReviewed: action.trialRequest,
        reviewMode: "approving",
      };
    case "startRejecting":
      return {
        trialRequestBeingReviewed: action.trialRequest,
        reviewMode: "rejecting",
      };
    case "startHiding":
      return {
        trialRequestBeingReviewed: action.trialRequest,
        reviewMode: "hiding",
      };
    case "stopReviewing":
      return {
        ...state,
        trialRequestBeingReviewed: null,
      };
    default:
      throw new Error();
  }
}

function getReviewActionName(state: State) {
  if (state.reviewMode === "approving") {
    return "approve";
  }
  if (state.reviewMode === "hiding") {
    return "hide";
  }
  return "reject";
}
