import { SortByFn } from "react-table";

import { Citation } from "domain/licensingReports";
import { nullAssignee } from "domain/assignees";

export const citationDateSortOrder = [{ id: "citationDate", desc: true }];
export const citationCountSortOrder = [{ id: "citationCount", desc: true }];

export const sortByCitationDate: SortByFn<Citation> = (rowA, rowB) => {
  const a = rowA.original.citationDate;
  const b = rowB.original.citationDate;
  return compare(a, b);
};

export const sortByRejectedAssignee: SortByFn<Citation> = (rowA, rowB) => {
  const a = (rowA.original.rejectedApplication.assignee || nullAssignee).name;
  const b = (rowB.original.rejectedApplication.assignee || nullAssignee).name;
  return compare(a, b);
};

export const sortByRejectionDocument: SortByFn<Citation> = (rowA, rowB) => {
  const a = rowA.original.isRejectionFinal ? "Final" : "Non-Final";
  const b = rowB.original.isRejectionFinal ? "Final" : "Non-Final";
  return compare(a, b);
};

export const sortByRejectedReadableApplicationNumber: SortByFn<Citation> = (
  rowA,
  rowB
) => {
  const a = rowA.original.rejectedApplication.readableApplicationNumber;
  const b = rowB.original.rejectedApplication.readableApplicationNumber;
  return compare(a, b);
};

function compare(a: any, b: any): number {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}
