import React from "react";
import Pulse from "atoms/Pulse";

import styles from "./module.sass";

const PageLoader = ({ isLoading }: { isLoading: boolean }) => (
  <>
    {isLoading && (
      <div className={styles.pageLoaderContainer}>
        <Pulse variant="small" />
      </div>
    )}
  </>
);

export default PageLoader;
