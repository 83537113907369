import * as FullStory from "@fullstory/browser";

import { Environment, getEnvironment } from "domain/environment";
import { WamId } from "domain/users";

/**
 * Is idempotent.
 */
export function initializeExperienceTracking(
  user: User,
  environment = getEnvironment(),
  fullStory = FullStory
) {
  if (environment === Environment.Production) {
    initializeProductionFullStory(user, fullStory);
    return;
  }
  if (environment === Environment.Development) {
    initializeLoggingExperienceTracking(user, environment);
    return;
  }
}

export interface User {
  userId: number;
  wamId: WamId;
  isInternal: boolean;
}

/**
 * Is idempotent.
 */
function initializeProductionFullStory(
  user: User,
  fullStory: typeof FullStory
) {
  if (!fullStory.isInitialized()) {
    fullStory.init({ orgId: "o-1MWZRS-na1" });
  }
  fullStory.setUserVars({ isInternal: user.isInternal });
}

function initializeLoggingExperienceTracking(
  user: User,
  environment: Environment
) {
  console.log("[Experience tracking] Would've initialized with:", {
    user,
    environment,
  });
}
