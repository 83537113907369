import React, { ReactNode } from "react";

import { ReleaseNote as ReleaseNoteType } from "domain/releaseNotes";
import Text from "atoms/Text";
import { Bin, Pen } from "components/Icons";
import Button from "components/Button";

import styles from "./module.sass";

export type Props = ReleaseNoteType;

export default function ReleaseNote({
  releaseDate,
  overview,
  productUpdates,
  bugFixes,
}: Props) {
  return (
    <article>
      <Text
        as="p"
        size={21}
        weight="light"
        color="grey900"
        style={{ marginBottom: 14 }}
      >
        {releaseDate.toLocaleString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
      </Text>
      <Text as="p" size={17} style={{ marginBottom: 16, lineHeight: 1.3 }}>
        {overview}
      </Text>
      {productUpdates.length !== 0 && (
        <>
          <Text
            as="p"
            weight="bold"
            color="blue900"
            style={{ marginBottom: 12 }}
          >
            Product updates
          </Text>
          <ul style={{ marginTop: 0, marginBottom: 20, paddingLeft: 32 }}>
            {productUpdates.map((productUpdate, i) => (
              <li key={`${i}.${productUpdate}`} style={{ marginBottom: 12 }}>
                {productUpdate}
              </li>
            ))}
          </ul>
        </>
      )}
      {bugFixes.length !== 0 && (
        <>
          <Text
            as="p"
            weight="bold"
            color="blue900"
            style={{ marginBottom: 12 }}
          >
            Bug Fixes
          </Text>
          <ul style={{ marginTop: 0, marginBottom: 16, paddingLeft: 32 }}>
            {bugFixes.map((bugFix, i) => (
              <li key={`${i}.${bugFix}`} style={{ marginBottom: 12 }}>
                {bugFix}
              </li>
            ))}
          </ul>
        </>
      )}
    </article>
  );
}

export type EditableProps = {
  releaseNote: ReleaseNoteType;
  onEdit: (releaseNote: ReleaseNoteType) => void;
  onDelete: (releaseNote: ReleaseNoteType) => void;
};

export function EditableReleaseNote({
  releaseNote,
  onEdit,
  onDelete,
}: EditableProps) {
  const {
    creationDate,
    releaseDate,
    overview,
    productUpdates,
    bugFixes,
  } = releaseNote;

  return (
    <article>
      <header className={styles.editableHeader}>
        <Text color="grey900">
          {creationDate.toLocaleString("default", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </Text>
        <div style={{ display: "flex" }}>
          <Button size="xxs" tooltip="Edit" onClick={() => onEdit(releaseNote)}>
            <Pen />
          </Button>
          <Button
            size="xxs"
            tooltip="Delete"
            onClick={() => onDelete(releaseNote)}
          >
            <Bin />
          </Button>
        </div>
      </header>
      <table>
        <tbody>
          <tr>
            <HeadingCell>Release date:</HeadingCell>
            <Cell>
              {releaseDate.toLocaleString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Cell>
          </tr>
          <tr>
            <HeadingCell>Overview:</HeadingCell>
            <Cell>{overview}</Cell>
          </tr>
          <tr>
            <HeadingCell>Product Updates:</HeadingCell>
            <Cell>
              {productUpdates.map((productUpdate, i) => {
                const isLast = i === productUpdates.length - 1;
                return isLast ? (
                  <Text key={`${i}.${productUpdate}`}>{productUpdate}</Text>
                ) : (
                  <Text
                    key={`${i}.${productUpdate}`}
                    style={{ marginBottom: 12 }}
                  >
                    {productUpdate}
                  </Text>
                );
              })}
            </Cell>
          </tr>
          <tr>
            <HeadingCell>Bug Fixes:</HeadingCell>
            <Cell>
              {bugFixes.map((bugFix, i) => {
                const isLast = i === bugFixes.length - 1;
                return isLast ? (
                  <Text key={`${i}.${bugFix}`}>{bugFix}</Text>
                ) : (
                  <Text key={`${i}.${bugFix}`} style={{ marginBottom: 12 }}>
                    {bugFix}
                  </Text>
                );
              })}
            </Cell>
          </tr>
        </tbody>
      </table>
    </article>
  );
}

const HeadingCell = ({ children }: { children: ReactNode }) => (
  <Text
    as="th"
    weight="bold"
    style={{
      marginRight: 14,
      paddingBottom: 20,
      display: "flex",
      justifyContent: "end",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </Text>
);

const Cell = ({ children }: { children: ReactNode }) => (
  <Text
    as="td"
    style={{
      paddingBottom: 20,
    }}
  >
    {children}
  </Text>
);
