import React, { useEffect, useState } from "react";

import { SubmittedTrialRequest } from "domain/trial";
import Button from "components/Button";
import Modal from "components/Modal";
import TextArea from "molecules/TextArea";

export type Props = {
  action: "approve" | "reject" | "hide";
  trialRequest: SubmittedTrialRequest;
  onDismiss: () => void;
  onReview: (trialRequest: SubmittedTrialRequest, note: string) => void;
};

export default function TrialRequestReviewDialog({
  action,
  trialRequest,
  onDismiss,
  onReview,
}: Props) {
  const [note, setNote] = useState("");

  useEffect(() => {
    setNote("");
  }, [trialRequest, action]);

  return (
    <Modal
      title="Please confirm"
      ariaLabel="Trial request review"
      onDismiss={onDismiss}
      footer={
        <>
          <Button size="sm" variant="outlined" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={() => onReview(trialRequest, note)}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </Button>
        </>
      }
    >
      <div style={{ marginTop: 10, marginBottom: 14 }}>
        Do you really want to {action} {trialRequest.name}?
      </div>
      <TextArea
        label="Add Notes"
        value={note}
        rows={3}
        onChange={(e) => setNote(e.target.value)}
      />
    </Modal>
  );
}
