import React from "react";
import { FieldHookConfig, useField } from "formik";

import PasswordInput, { Props as InputProps } from "molecules/PasswordInput";

export type Props = InputProps &
  FieldHookConfig<string> & { popoverPosition: "top" | "right" };

export default function PasswordField(props: Props) {
  const [field, meta, helpers] = useField<string>(props.name);

  return (
    <div style={{ marginBottom: 14 }}>
      <PasswordInput
        {...field}
        label={props.label}
        variant={props.variant}
        blacklist={props.blacklist}
        error={meta.touched && meta.error ? meta.error : undefined}
        autoComplete={props.autoComplete}
        popoverPosition={props.popoverPosition}
      />
    </div>
  );
}
