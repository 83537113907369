import React from "react";
import { useQuery } from "react-query";

import { fetchUser } from "domain/users";

export default function useUser() {
  const { status, error, data: user } = useQuery("user", fetchUser, {
    retry: (failureCount, error: any) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount >= 3;
    },
  });

  const isLoggedOut = error?.response?.status === 404;

  return { status, error, user, isLoggedOut };
}
