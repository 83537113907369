import React from "react";

const Back = ({
  color,
  className = "",
  width = 14.19,
  height = 17.54,
}: {
  color?: string;
  className?: string;
  width?: number;
  height?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17.44 14.91"
    className={className}
    fill={color}
  >
    <path d="M15.15 4.85H2.91L6 1.24 5 0 0 5.75l5 5.42 1-1.24-3.07-3.4h12.2a.87.87 0 01.79.94v7.44h1.51V7.48a2.49 2.49 0 00-2.28-2.63z" />
  </svg>
);

export default Back;
