import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import Layout, { Title as PageTitle, Pill } from "../components/Layout";
import styles from "./module.sass";
import queryString from "query-string";
import Button from "components/Button";
import cn from "classnames";
import Text from "components/Text";
import { default as TextAtom } from "atoms/Text";
import {
  DictionaryItemType,
  Gap,
} from "components/PTABDecisions/components/utils";
import {
  fetchIssueSets,
  fetchDecisionJudges,
  getPdfBlob,
  createDecisionJudge,
  deleteDecisionJudge,
  deleteIssueSet,
  fetchDecisionDetails,
  editDecisionDetails,
  DecisionDetailsType,
  IssueSetType,
} from "domain/ptabAdmin";
import {
  JudgeType,
  fetchJudges,
  SERVER_RESPONSE,
  STATUSES,
} from "domain/ptabDecisions";
import EditIssueSetForm from "./EditIssueSetForm";
import { Chevron, OpenInNewWindow, Checkmark } from "components/Icons";
import SelectWithSuggestions from "./SelectWithSuggestions";
import { DecisionFlags } from "./DecisionDetails";
import { Partition, SmallButton, Title } from "./components";
import useDocumentTitle from "hooks/useDocumentTitle";
import { PAGE_TITLE, useScrollToTop } from "../utils";
import Tabs from "../components/Tabs";
import { useToastContext } from "components/Toast";
import { useOutcomeOptions } from "../ApplicationLookupPage";
import { User } from "domain/users";

export type ParamsType = {
  decisionId?: string;
};

const IssueSet = ({
  data,
  onEditClick,
  onDeleteClick,
  findOutcomeLabel,
}: {
  data: IssueSetType;
  onEditClick: (issueSet: IssueSetType) => void;
  onDeleteClick: (id: number) => void;
  findOutcomeLabel: (disposition?: string) => string | null;
}) => {
  const {
    issue_set_id,
    claims_of_issue,
    issue,
    disposition,
    non_substantive,
    tags,
  } = data;

  return (
    <Partition
      id={issue_set_id}
      overlay={
        <>
          {issue_set_id && (
            <SmallButton
              onClick={() => {
                onEditClick(data);
              }}
            >
              Edit
            </SmallButton>
          )}
          <Gap vertical size={8} />
          {issue_set_id && (
            <SmallButton
              className={styles.redButton}
              onClick={() => {
                onDeleteClick(issue_set_id);
              }}
            >
              Delete
            </SmallButton>
          )}
        </>
      }
      style={{ marginTop: 10 }}
    >
      <Text>
        <b>Claims of issue:</b> {claims_of_issue}
      </Text>

      <Gap vertical size={4} />

      <Text>
        <b>Issue:</b> §{issue}
      </Text>

      <Gap vertical size={4} />

      <Text>
        <b>Disposition:</b> {findOutcomeLabel(disposition)}
      </Text>

      <Gap vertical size={4} />

      <Text>
        <b>Non Substantive:</b> {non_substantive ? "True" : "False"}
      </Text>

      <Gap vertical size={4} />

      <Text>
        <b>Tags:</b>
      </Text>

      {tags?.map((tag, index) => (
        <span
          className={styles.tag}
          key={`tag-${issue_set_id}-${tag}-${index}`}
        >
          {tag.dispositive ? (
            <Checkmark className={styles.isDispositive} />
          ) : (
            <Gap size={10.4} />
          )}
          <Text>{tag.name}</Text>
        </span>
      ))}
    </Partition>
  );
};

const DecisionPage = ({ user }: { user?: User }) => {
  useDocumentTitle(PAGE_TITLE);
  useScrollToTop();

  const location = useLocation();
  const history = useHistory();

  const { decisionId: _decisionId }: ParamsType = queryString.parse(
    location.search
  );

  const toastContext = useToastContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [file, setFile] = useState("");
  const [isMinimised, setMinimised] = useState<Boolean>(false);

  const [judge, setJudge] = useState<DictionaryItemType | null>(null);
  const [title, setTitle] = useState(" ");

  const [activeClaimOfIssue, setActiveClaimOfIssue] = useState<
    IssueSetType | undefined
  >(undefined);

  const [issueSets, setIssueSets] = useState<Array<IssueSetType>>();

  const [judges, setJudges] = useState<Array<JudgeType>>([]);

  const [
    decisionDetails,
    setDecisionDetails,
  ] = useState<DecisionDetailsType | null>();

  const { findOutcomeOption } = useOutcomeOptions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEditMode]);

  useEffect(() => {
    if (_decisionId) {
      fetchDecisionDetails(_decisionId).then(
        (data: DecisionDetailsType | null) => {
          if (data) {
            setDecisionDetails(data);

            setTitle(`${data.application_number}`);
          }
        }
      );
    }
  }, []);

  const fetchIssueSetsCallback = useCallback(() => {
    if (_decisionId) {
      fetchIssueSets(_decisionId).then((data) => {
        setIssueSets(data);
      });
    }
  }, [_decisionId]);

  useEffect(() => {
    fetchIssueSetsCallback();
  }, []);

  useEffect(() => {
    if (_decisionId) {
      fetchDecisionJudges(_decisionId).then((data) => {
        setJudges(data);
      });
    }
  }, []);

  const editDecisionDetailsCallback = useCallback(() => {
    if (decisionDetails) {
      setIsLoading(true);

      editDecisionDetails({ details: decisionDetails, userId: user?.userId })
        .then((success) => {
          if (success) {
            toastContext?.showInfoToast("Your changes have been saved.");
            if (history.length > 2) {
              history.goBack();
            }
          } else {
            toastContext?.showWarningToast(
              "Something went wrong, changes haven't been saved."
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [decisionDetails, editDecisionDetails]);

  useEffect(() => {
    if (_decisionId) {
      getPdfBlob(_decisionId).then((data) => {
        if (data) {
          setFile(`data:application/pdf;base64,${data}`);
        }
      });
    }
  }, [decisionDetails, editDecisionDetails]);

  const SaveDecisionButton = () => (
    <SmallButton
      className={styles.greenButton}
      onClick={editDecisionDetailsCallback}
    >
      {user?.userId ? "Save Decision (Mark as Reviewed)" : "Save Decision"}
    </SmallButton>
  );

  const openInNewWindow = () => {
    let pdfWindow = window.open("", "_blank");

    const html =
      "<html><title>PDF preview</title><body width='100%' height='100%' style='margin: 0;'><iframe name='pdfframe' id='pdfframe' width='100%' height='100%' style='border: none;' src='" +
      file +
      "' type='application/pdf'></iframe></body></html>";

    pdfWindow?.document.write(html);
  };

  return (
    <>
      <Layout
        className={styles.layout}
        isLoading={isLoading}
        isWide={_decisionId !== undefined}
      >
        <section className={styles.left}>
          {!isEditMode ? (
            <>
              <div
                style={{ height: 80, display: "flex", alignItems: "center" }}
              >
                <TextAtom as="h2" size={30}>
                  {title}
                </TextAtom>
                {decisionDetails && (
                  <Pill
                    style={{ marginLeft: 10 }}
                    color={
                      decisionDetails["review_status"] === "Pending"
                        ? "yellow"
                        : "green"
                    }
                  >
                    {decisionDetails["review_status"]}
                  </Pill>
                )}
              </div>

              <Tabs titles={["FIELDS", "FLAGS"]}>
                <>
                  <SaveDecisionButton />
                  <div className={styles.claims}>
                    <Title>Claims of issue</Title>
                    <SmallButton
                      onClick={() => {
                        setActiveClaimOfIssue(undefined);
                        setIsEditMode(true);
                      }}
                    >
                      Create
                    </SmallButton>
                  </div>

                  {issueSets?.map((x) => (
                    <IssueSet
                      findOutcomeLabel={(disposition) =>
                        findOutcomeOption(disposition)?.label ?? null
                      }
                      key={x.issue_set_id}
                      data={x}
                      onEditClick={(issueSet) => {
                        setActiveClaimOfIssue(issueSet);
                        setIsEditMode(true);
                      }}
                      onDeleteClick={(id) => {
                        if (
                          _decisionId &&
                          confirm("Are you sure you want to delete this item?")
                        ) {
                          deleteIssueSet({
                            issueSetId: id,
                            decisionId: parseInt(_decisionId),
                            userId: user?.userId,
                          }).then((res) => {
                            setIssueSets(
                              issueSets?.filter((x) => x.issue_set_id !== id) ??
                                []
                            );
                          });
                        }
                      }}
                    />
                  ))}

                  <Gap size={30} vertical />

                  <Title>Panel Judges</Title>

                  <Gap size={8} vertical />

                  <SelectWithSuggestions
                    width={240}
                    maxMenuHeight={233}
                    placeholder={"Enter judge name"}
                    value={judge}
                    setValue={setJudge}
                    fetch={(value) => {
                      return (
                        fetchJudges(value).then((res) =>
                          res?.map(({ id, name }) => ({
                            label: `${name}`,
                            value: `${id}`,
                          }))
                        ) ?? []
                      );
                    }}
                  />

                  <Gap size={8} vertical />

                  <SmallButton
                    onClick={() => {
                      if (!!judge && _decisionId) {
                        const newJudge = { id: judge.value, name: judge.label };

                        createDecisionJudge({
                          decisionId: parseInt(_decisionId),
                          judge: newJudge,
                          userId: user?.userId,
                        }).then((resp: SERVER_RESPONSE | undefined) => {
                          if (resp?.status === STATUSES.OK) {
                            setJudge(null);
                            setJudges([...judges, newJudge]);
                          } else if (
                            resp?.status === STATUSES.WARNING &&
                            resp?.message
                          ) {
                            toastContext?.showWarningToast(resp.message);
                          } else if (
                            resp?.status === STATUSES.ERROR &&
                            resp?.message
                          ) {
                            toastContext?.showErrorToast(resp.message);
                          }
                        });
                      }
                    }}
                  >
                    Add Judge
                  </SmallButton>

                  {judges.length !== 0 && (
                    <Partition
                      id={"judges"}
                      style={{ padding: 0, marginTop: 18 }}
                    >
                      {judges?.map(({ name, id }) => (
                        <div
                          className={styles.judgeRow}
                          key={`judge-${id}-${name}`}
                          style={{ padding: "0 8px 0 12px" }}
                        >
                          <Text className={styles.judgeRowText}>{name}</Text>

                          <SmallButton
                            className={styles.redButton}
                            onClick={() => {
                              if (
                                id &&
                                _decisionId &&
                                confirm(
                                  "Are you sure you want to delete this item?"
                                )
                              ) {
                                deleteDecisionJudge({
                                  decisionId: parseInt(_decisionId),
                                  judgeId: id,
                                  userId: user?.userId,
                                }).then(() => {
                                  setJudges(judges?.filter((x) => x.id !== id));
                                });
                              }
                            }}
                          >
                            Delete
                          </SmallButton>
                        </div>
                      ))}
                    </Partition>
                  )}
                </>

                <>
                  <DecisionFlags
                    details={decisionDetails ?? undefined}
                    setDecisionDetails={setDecisionDetails}
                  />

                  <Gap vertical size={20} />

                  <SaveDecisionButton />

                  <Gap size={160} vertical />
                </>
              </Tabs>

              <Gap vertical size={60} />
            </>
          ) : (
            <>
              {_decisionId && (
                <EditIssueSetForm
                  userId={user?.userId}
                  decisionId={parseInt(_decisionId)}
                  onClose={() => {
                    setActiveClaimOfIssue(undefined);
                    setIsEditMode(false);

                    fetchIssueSetsCallback();
                  }}
                  issueSet={activeClaimOfIssue}
                />
              )}
            </>
          )}
        </section>

        <Gap size={20} />

        <section
          className={cn(styles.right, isMinimised && styles.right$minimised)}
        >
          <div
            className={cn(styles.extra, isMinimised && styles.extra$minimised)}
          >
            {!isMinimised && file !== "" && (
              <>
                <Button
                  size={"xxs"}
                  variant={"contained"}
                  onClick={openInNewWindow}
                  tooltip="Open in new window"
                  className={styles.newWindowButton}
                >
                  <OpenInNewWindow />
                </Button>

                <Gap size={20} />
              </>
            )}

            <Button
              size={"xxs"}
              variant={"contained"}
              className={styles.minimiseButton}
              onClick={() => {
                setMinimised(!isMinimised);
              }}
              tooltip={
                isMinimised ? "Expand pdf viewer" : "Compress pdf viewer"
              }
            >
              {isMinimised ? <Chevron left /> : <Chevron right />}
            </Button>
          </div>

          {!isMinimised && (
            <iframe
              name={"pdfframe"}
              id={"pdfframe"}
              src={file}
              height="100%"
              width="100%"
              style={{
                borderTop: "1px solid #fff",
                borderRight: "2px solid rgb(50 54 57)",
                borderBottom: "2px solid rgb(50 54 57)",
                borderLeft: "2px solid rgb(50 54 57)",
              }}
            />
          )}
        </section>
      </Layout>
    </>
  );
};

export default DecisionPage;
