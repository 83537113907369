import React, { forwardRef } from "react";

import { Checkmark, CheckmarkIndeterminate } from "components/Icons";

import styles from "./module.sass";
// @todo: remove
import { Gap } from "components/PTABDecisions/components/utils";
import cn from "classnames";

export type Props = {
  isChecked: boolean;

  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
  labelPosition?: "left" | "right";
  onChange?: (
    isChecked: boolean,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

/**
 * @todo Make the API for indeterminate/checked/unchecked states
 * more ergonomic.
 */
const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      isDisabled,
      isChecked,
      children,
      className,
      isIndeterminate,
      onChange,
      label = "",
      isBold = false,
      labelPosition = "right",
    },
    ref
  ) => (
    <label
      className={cn(
        styles.label,
        isDisabled && styles.label$disabled,
        labelPosition === "left" && styles.label$reversed,
        className
      )}
    >
      <input
        type="checkbox"
        className={styles.nativeCheckbox}
        checked={isChecked}
        onChange={onChange ? (e) => onChange(e.target.checked, e) : () => {}}
        disabled={isDisabled ?? false}
      />
      <div className={styles.checkmarkBox}>
        {isIndeterminate !== true && <Checkmark className={styles.checkmark} />}

        {isIndeterminate === true && <CheckmarkIndeterminate />}
      </div>

      {(!!label || !!children) && <Gap size={9} />}
      {!!label && (
        <span className={cn(isChecked && isBold && styles.textBold)}>
          {label}
        </span>
      )}
      {!!children && children}
    </label>
  )
);
export default Checkbox;
