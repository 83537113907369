import React from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import * as yup from "yup";

import { ReleaseNote } from "domain/releaseNotes";
import TextAreaField from "molecules/TextAreaField";
import DateField from "molecules/DateField";
import HorizontalRule from "atoms/HorizontalRule";
import Button from "components/Button";
import PageTitle from "components/PageTitle";

export type Props = {
  title: string;
  action: string;
  releaseDate?: Date;
  overview?: string;
  productUpdates?: string[];
  bugFixes?: string[];
  onSubmit: (releaseNote: Omit<ReleaseNote, "id" | "creationDate">) => void;
  onCancel: () => void;
};

const validationSchema = yup.object().shape({
  releaseDate: yup.date().required(),
  overview: yup.string(),
  productUpdates: yup.array().of(yup.string()),
  bugFixes: yup.array().of(yup.string()),
});

export default function ReleaseNotesForm({
  title,
  action,
  onSubmit,
  onCancel,
  releaseDate = new Date(),
  overview = "",
  productUpdates = ["", "", ""],
  bugFixes = ["", "", ""],
}: Props) {
  const handleSubmit = (
    releaseNote: Omit<ReleaseNote, "id" | "creationDate">
  ) => {
    const utcReleaseDate = new Date(
      Date.UTC(
        releaseNote.releaseDate.getFullYear(),
        releaseNote.releaseDate.getMonth(),
        releaseNote.releaseDate.getDate()
      )
    );

    onSubmit({
      ...releaseNote,
      releaseDate: utcReleaseDate,
      productUpdates: releaseNote.productUpdates.filter((v) => v !== ""),
      bugFixes: releaseNote.bugFixes.filter((v) => v !== ""),
    });
  };

  return (
    <Formik
      initialValues={{
        releaseDate,
        overview,
        productUpdates,
        bugFixes,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form style={{ width: "100%", maxWidth: "800px" }}>
          <PageTitle style={{ paddingLeft: 0 }}>{title}</PageTitle>
          <DateField name="releaseDate" label="Release Date" isFutureDisabled />
          <TextAreaField name="overview" label="Overview (optional)" rows={4} />
          <FieldArray name="productUpdates">
            {({ push }) => (
              <>
                {values.productUpdates.map((productUpdate, i) => (
                  <TextAreaField
                    key={i}
                    name={`productUpdates.${i}`}
                    label={`Product update ${i + 1}`}
                    rows={2}
                  />
                ))}
                <div style={{ marginBottom: 24 }}>
                  <Button size="xxs" onClick={() => push("")}>
                    Add Product Update
                  </Button>
                </div>
              </>
            )}
          </FieldArray>
          <FieldArray name="bugFixes">
            {({ push }) => (
              <>
                {values.bugFixes.map((bugFix, i) => (
                  <TextAreaField
                    key={i}
                    name={`bugFixes[${i}]`}
                    label={`Bug Fix ${i + 1}`}
                    rows={2}
                  />
                ))}
                <Button size="xxs" onClick={() => push("")}>
                  Add Bug Fix
                </Button>
              </>
            )}
          </FieldArray>

          <HorizontalRule style={{ marginTop: 24, marginBottom: 24 }} />

          <div style={{ display: "flex" }}>
            <Button
              type="submit"
              variant="brand"
              size="sm"
              isDisabled={
                values.overview === "" &&
                !values.productUpdates.some((v) => v !== "") &&
                !values.bugFixes.some((v) => v !== "")
              }
            >
              {action}
            </Button>
            <div style={{ marginLeft: 32 }}>
              <Button variant="outlined" size="sm" onClick={() => onCancel()}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
