import { LinkType } from "domain/api";

export function hasMySavedWork(user: User) {
  if (user.groups["superAdmin"]) {
    return true;
  }
  if (
    !user.isValid ||
    user.trialStatus === "start" ||
    user.groups["quickPairOnly"] ||
    user.groups["pathwaysOnly"]
  ) {
    return false;
  }
  return true;
}

export function getBriefcasesVariant(
  user: User
): "default" | "preview" | "readOnly" {
  if (!user.permissions.briefcaseAccess && user.trialStatus !== "basic") {
    return "readOnly";
  } else if (user.trialStatus === "basic") {
    return "preview";
  } else {
    return "default";
  }
}

export function getMonitorsVariant(
  user: User
): "default" | "preview" | "readOnly" {
  if (
    !user.permissions.prosecutionPatternMonitoring &&
    user.trialStatus !== "basic"
  ) {
    return "readOnly";
  } else if (user.trialStatus === "basic") {
    return "preview";
  } else {
    return "default";
  }
}

export type WamId = string | null;

export type User = CommonUser & {
  lastLogin: Date | null;
};

export type APIUser = CommonUser & {
  lastLogin: string;
};

type CommonUser = {
  userId: number;
  wamId: WamId;
  isWamUser: boolean;
  isWamAdmin: boolean;
  isValid: boolean;
  isInternal: boolean;

  whatsNewUrl: string;
  trialStatus: "none" | "start" | "basic" | "browserExtensionOnly";

  permissions: Permissions;
  groups: Groups;

  customAdminMenu: LinkType[];
  adminMenu: LinkType[];
  viewMenu: LinkType[];

  hasCompany: boolean;
  hasActiveBrowserPlugin: boolean;
};

export type Permissions = {
  search: SearchPermissions;
  licensingReports: boolean;
  smartReports: boolean;
  pathways: boolean;
  briefcaseAccess: boolean;
  briefcaseMetadataManagement: boolean;
  briefcaseHealth: boolean;
  briefcaseAttachedStorage: boolean;
  markApplicationsPrivate: boolean;
  prosecutionPatternMonitoring: boolean;
  matterNumber: boolean;
  companyLoginHistory: boolean;
  alignmentReport: boolean;
  autoFolders: boolean;
  budgetManagement: boolean;
  ptabDecisionReview: boolean;
  releaseNotesAdmin: boolean;
  trialUserApproval: boolean;
  privateManagement: boolean;

  [x: string]: boolean | SearchPermissions;
};

export type Groups = {
  superAdmin: boolean;
  quickPairOnly: boolean;
  pathwaysOnly: boolean;
  browserExtensionOnly: boolean;
};

export type SearchPermissions = {
  examiner: boolean;
  quickPAIR: boolean;
  fileWrapper: boolean;
  artUnit: boolean;
  assigneeApplicant: boolean;
  lawFirm: boolean;
  ptab: boolean;
  pathways: boolean;
};
