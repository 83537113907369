import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  MouseEvent,
  TouchEvent,
} from "react";
import OpposingArrows from "../../components/Icons/OpposingArrows";
import cn from "classnames";
import styles from "./module.sass";

export type Props = {
  beforeImageUrl: string;
  afterImageUrl: string;
  beforeImageAltText: string;
  afterImageAltText: string;
};

const BeforeAfter = ({
  beforeImageUrl,
  afterImageUrl,
  beforeImageAltText = "",
  afterImageAltText = "",
}: Props) => {
  const [position, setPosition] = useState("50%");
  const [isDraggable, setIsDraggable] = useState(false);
  const beforeAfterRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const x = e.clientX;

    if (beforeAfterRef.current) {
      const mousePosition = x - beforeAfterRef.current.offsetLeft;

      if (
        mousePosition >= 1 &&
        mousePosition <= beforeAfterRef.current.offsetWidth - 10
      ) {
        setPosition(`${mousePosition}px`);
      }
      return;
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    e.stopPropagation();

    const x = e.touches[0].clientX;

    if (beforeAfterRef.current) {
      const touchPosition = x - beforeAfterRef.current.offsetLeft;

      if (
        touchPosition >= 1 &&
        touchPosition <= beforeAfterRef.current.offsetWidth - 10
      ) {
        setPosition(`${touchPosition}px`);
      }
      return;
    }
  };

  const handleMouseOrTouchDown = useCallback(() => {
    setIsDraggable(true);
  }, []);

  const handleMouseOrTouchUp = () => {
    setIsDraggable(false);
  };

  const handleResize = () => {
    setPosition("50%");
  };

  useEffect(() => {
    document.addEventListener("touchend", handleMouseOrTouchUp);
    document.addEventListener("mouseup", handleMouseOrTouchUp);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("touchend", handleMouseOrTouchUp);
      document.removeEventListener("mouseup", handleMouseOrTouchUp);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleMouseOrTouchUp, handleResize]);

  return (
    <div
      ref={beforeAfterRef}
      className={cn(styles.beforeAfter)}
      onMouseMove={isDraggable ? handleMouseMove : undefined}
      onTouchMove={isDraggable ? handleTouchMove : undefined}
      draggable="false"
    >
      <div
        className={cn(styles.imageContainer)}
        style={{ width: position }}
        draggable="false"
      >
        <img
          className={styles.firstImage}
          src={beforeImageUrl}
          alt={beforeImageAltText}
          draggable="false"
        />
      </div>
      <img
        className={styles.secondImage}
        src={afterImageUrl}
        alt={afterImageAltText}
        draggable="false"
      />
      <div
        className={styles.separator}
        style={{ left: position }}
        onMouseDown={handleMouseOrTouchDown}
        onTouchStart={handleMouseOrTouchDown}
      >
        <OpposingArrows className={styles.separatorIcon} />
      </div>
    </div>
  );
};

export default BeforeAfter;
