import React, { SVGProps } from "react";

export default function PaginationChevron({
  direction = "right",
  width = 10,
  height = 10,
  ...rest
}: {
  direction?: "left" | "right";
} & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 10"
      width={width}
      height={height}
      transform={direction == "left" ? `rotate(180)` : ""}
      {...rest}
    >
      <path d="M1 .004c.26 0 .51.1.71.29l4 4c.39.39.39 1.02 0 1.41l-4 4A.996.996 0 1 1 .3 8.294l3.29-3.29-3.3-3.3A.996.996 0 0 1 1 .004z" />
    </svg>
  );
}
