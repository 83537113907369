import React, { SVGProps } from "react";

export default function Eye(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={14}
      viewBox="0 0 24 14"
      fill="#cdcdcd"
      {...props}
    >
      <path d="M12.025 0c-4.6 0-8.7 2.5-11.8 6.4-.3.3-.3.8 0 1.1 3.1 4 7.2 6.4 11.8 6.4 4.6 0 8.7-2.5 11.8-6.4.3-.3.3-.8 0-1.1-3-4-7.2-6.4-11.8-6.4zm.4 11.9c-2.7.1-5-2-5.2-4.7-.1-2.7 2-5 4.7-5.2 2.7-.1 5 2 5.2 4.7v.6c-.2 2.5-2.2 4.5-4.7 4.6zm-.2-2.3c-1.5.1-2.7-1.1-2.8-2.5-.1-1.5 1.1-2.7 2.5-2.8 1.5-.1 2.7 1.1 2.8 2.5v.3c0 1.4-1.1 2.5-2.5 2.5z" />
    </svg>
  );
}
