import React, { ReactNode, useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  CellProps,
  useExpanded,
  Row,
  SortByFn,
} from "react-table";

import {
  Citation,
  CitedApplicationWithCitations,
  LicensingReport,
  RejectedAssigneeWithCitations,
  groupByRejectedAssignee,
} from "domain/licensingReports";
import {
  isNullAssignee,
  linkToAssigneeStatistics,
  nullAssignee,
} from "domain/assignees";
import {
  default as BaseTable,
  Expander,
  ExtendedColumn,
  SubcomponentTableContainer,
  addExpanderCellProps,
} from "components/Table";
import { format as formatDate } from "domain/dates";
import Anchor from "atoms/Anchor";
import { capitalize } from "domain/strings";
import { PdfIcon } from "components/Icons";
import TruncatedText from "atoms/TruncatedText";
import colors from "style/colors.module.scss";
import {
  linkToApplicationDocument,
  linkToQuickPAIR,
} from "domain/applications";

import {
  citationCountSortOrder,
  citationDateSortOrder,
  sortByCitationDate,
  sortByRejectedReadableApplicationNumber,
  sortByRejectionDocument,
} from "./sorting";
import { Dropdown } from "./Dropdown";
import NoResults from "./NoResults";

export interface Props {
  licensingReport: LicensingReport;
  setCountText: (countText: ReactNode) => void;
}

export default function RejectedAssigneeTable({
  licensingReport,
  setCountText,
}: Props) {
  const groupedLicensingReport = useMemo(
    () => Object.values(groupByRejectedAssignee(licensingReport)),
    [licensingReport]
  );

  const table = useTable(
    {
      columns,
      data: groupedLicensingReport,
      disableSortRemove: true,
      initialState: {
        sortBy: citationCountSortOrder,
        pageSize: 25,
      },
    },
    useSortBy,
    useExpanded,
    usePagination
  );
  const { page, rows } = table;

  useEffect(() => {
    setCountText(
      <>
        Displaying <b>{page.length.toLocaleString()}</b> of{" "}
        <b>{rows.length.toLocaleString()}</b> Rejected Assignees Groups
      </>
    );
  }, [page.length, rows.length]);

  return (
    <BaseTable
      table={table}
      colorScheme="white"
      emptyMessage={
        <NoResults colSpan={3}>
          No citations were found. Try increasing the citation date range on the{" "}
          <Anchor href="/app.php#licensing-reports/setup?reuseLastSearch=true">
            previous screen.
          </Anchor>
        </NoResults>
      }
      renderRowSubcomponent={renderRowSubcomponent}
    />
  );
}

function renderRowSubcomponent({
  original: citedApplication,
}: Row<RejectedAssigneeWithCitations>) {
  return <RowSubcomponent citations={citedApplication.citations} />;
}

function renderRowSubcomponentPagination(pagination: ReactNode) {
  return <div style={{ padding: 20 }}>{pagination}</div>;
}

function RowSubcomponent({ citations }: { citations: Citation[] }) {
  const table = useTable(
    {
      columns: subcomponentsColumns,
      data: citations,
      disableSortRemove: true,
      initialState: {
        sortBy: citationDateSortOrder,
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <SubcomponentTableContainer>
      <BaseTable
        table={table}
        colorScheme="gray"
        renderPagination={renderRowSubcomponentPagination}
      />
    </SubcomponentTableContainer>
  );
}

const sortByRejectedAssignee: SortByFn<RejectedAssigneeWithCitations> = (
  rowA,
  rowB
) => {
  const a = (rowA.original || nullAssignee).name;
  const b = (rowB.original || nullAssignee).name;
  return a.localeCompare(b);
};

const columns: ExtendedColumn<RejectedAssigneeWithCitations>[] = [
  {
    id: "expander",
    addCellProps: addExpanderCellProps,
    Header: () => null,
    Cell: ({ row }: CellProps<CitedApplicationWithCitations>) => (
      <Expander isExpanded={row.isExpanded} />
    ),
  },
  {
    id: "rejectedAssignee",
    accessor: (rejectedAssignee) => rejectedAssignee,
    sortType: sortByRejectedAssignee,
    Header: "Rejected Assignee",
    Cell: ({ value: assignee }: CellProps<Citation>) =>
      isNullAssignee(assignee) ? (
        assignee.name
      ) : (
        <Anchor href={linkToAssigneeStatistics(assignee)} target="_blank">
          {assignee.name}
        </Anchor>
      ),
  },
  {
    id: "citationCount",
    accessor: (rejectedAssignee) =>
      rejectedAssignee.citations.length.toLocaleString(),
    addCellProps: () => ({ style: { textAlign: "right" } }),
    sortDescFirst: true,
    Header: "Citations",
  },
];

const subcomponentsColumns: ExtendedColumn<Citation>[] = [
  {
    id: "rejectedApplication.applicationNumber",
    accessor: (citation) => citation.rejectedApplication.applicationNumber,
    sortType: sortByRejectedReadableApplicationNumber,
    sortDescFirst: true,
    addCellProps: () => ({ style: { width: 140, paddingLeft: 60 } }),
    additionalHeaderProps: { style: { paddingLeft: 60 } },
    Header: "Rejected Document",
    Cell: ({
      row: {
        original: {
          rejectedApplication: { applicationNumber, readableApplicationNumber },
        },
      },
    }: CellProps<Citation>) => (
      <Dropdown
        detailsUrl={linkToQuickPAIR(applicationNumber)}
        pdfUrl={linkToApplicationDocument(applicationNumber)}
      >
        {readableApplicationNumber}
      </Dropdown>
    ),
  },
  {
    id: "rejectedApplication.status",
    accessor: (citation) => citation.rejectedApplication.status,
    addCellProps: () => ({ style: { width: 100 } }),
    Header: "Status",
    Cell: ({ value: status }: CellProps<Citation>) => capitalize(status),
  },
  {
    accessor: "rejectionType",
    addCellProps: () => ({ style: { width: 100 } }),
    Header: "Rejection Type",
  },
  {
    accessor: "rejectionDocumentUrl",
    sortType: sortByRejectionDocument,
    addCellProps: () => ({ style: { width: 120, whiteSpace: "nowrap" } }),
    Header: "Examiner rejection",
    Cell: ({
      row: {
        original: { rejectionDocumentUrl, isRejectionFinal },
      },
    }) => (
      <Anchor
        href={rejectionDocumentUrl}
        target="_blank"
        style={{ display: "flex", alignItems: "center" }}
      >
        {isRejectionFinal ? "Final" : "Non-Final"}{" "}
        <PdfIcon
          width={20}
          height={20}
          style={{ marginLeft: 4 }}
          hasHoverState={false}
        />
      </Anchor>
    ),
  },
  {
    id: "citationDate",
    accessor: "citationDate",
    sortType: sortByCitationDate,
    sortDescFirst: true,
    addCellProps: () => ({ style: { width: 120, whiteSpace: "nowrap" } }),
    Header: "Mail Room Date",
    Cell: ({ value }: CellProps<Citation>) => formatDate(value, "Y-m-d"),
  },
  {
    id: "citedApplication.assignee",
    accessor: (citation) => citation.citedApplication.assignee,
    additionalHeaderProps: {
      style: { borderLeft: `1px solid ${colors.blueGray400}` },
    },
    addCellProps: (cell) => ({
      title: cell.value,
      style: {
        width: 140,
        maxWidth: 180,
        borderLeft: `1px solid ${colors.blueGray400}`,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    }),
    Header: "Cited Assignee",
  },
  {
    id: "citedApplication.citedDocument",
    sortDescFirst: true,
    accessor: (citation) => citation.citedApplication.citedDocument,
    addCellProps: () => ({ style: { width: 140 } }),
    Header: "Cited Document",
    Cell: ({
      row: {
        original: {
          citedApplication: { citedDocument, applicationNumber },
        },
      },
    }: CellProps<Citation>) => (
      <Dropdown
        detailsUrl={linkToQuickPAIR(applicationNumber)}
        pdfUrl={linkToApplicationDocument(applicationNumber)}
        isCaretVisible={true}
      >
        {citedDocument}
      </Dropdown>
    ),
  },
  {
    id: "citedApplication.title",
    accessor: (citation) => citation.citedApplication.title,
    Header: "Title",
    addCellProps: (cell) => ({
      title: cell.value,
      style: {
        width: 1,
        maxWidth: 290,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    }),
  },
];
