import { findAll } from "highlight-words-core";

export interface TextChunk {
  start: number;
  end: number;
}

export interface ReconcilableTextChunk extends TextChunk {
  mergableData?: any[];
  priority?: number;
}

export function searchText(text: string, query: string): TextChunk[] {
  return findAll({
    searchWords: [query],
    textToHighlight: text,
  })
    .filter((c) => c.highlight)
    .map((c) => {
      return {
        start: c.start,
        end: c.end,
      };
    });
}

/**
 * Handle overlapping text chunks based on priority.
 */
export function reconcileTextChunks<T extends ReconcilableTextChunk>(
  textChunks: T[]
): (T | ReconcilableTextChunk)[] {
  let workingTextChunks: (T | ReconcilableTextChunk)[] = textChunks.sort(
    (a, b) => a.start - b.start
  );
  let areAllReconciled = false;

  while (!areAllReconciled) {
    areAllReconciled = true;

    for (let i = 1; i < workingTextChunks.length; i++) {
      const previous = workingTextChunks[i - 1];
      const current = workingTextChunks[i];

      if (!areOverlapping(previous, current)) {
        continue;
      }

      const newChunks = reconcileTextChunkPair(previous, current);
      const before = workingTextChunks.slice(0, i - 1);
      const after = workingTextChunks.slice(i + 1);
      workingTextChunks = before.concat(newChunks).concat(after);
      areAllReconciled = false;

      break;
    }
  }

  return workingTextChunks;
}

export function reconcileTextChunkPair<T extends ReconcilableTextChunk>(
  a: T,
  b: T
): (T | ReconcilableTextChunk)[] {
  if (!areOverlapping(a, b)) {
    return [a, b];
  }

  const areOfSamePriority = a.priority === b.priority;

  return areOfSamePriority
    ? reconcileSamePriorityTextChunkPair(a, b)
    : reconcileDifferingPriorityTextChunkPair(a, b);
}

function reconcileSamePriorityTextChunkPair<T extends ReconcilableTextChunk>(
  a: T,
  b: T
): ReconcilableTextChunk[] {
  const [first, second] = a.start < b.start ? [a, b] : [b, a];
  const [firstEnding, secondEnding] = a.end < b.end ? [a, b] : [b, a];

  if (haveIdenticalRange(first, second)) {
    return [
      {
        ...first,
        mergableData: [
          ...(first.mergableData ?? []),
          ...(second.mergableData ?? []),
        ],
      },
    ];
  }

  if (isContained(second, first)) {
    return [
      { ...first, end: second.start },
      {
        ...second,
        mergableData: [
          ...(first.mergableData ?? []),
          ...(second.mergableData ?? []),
        ],
      },
      { ...first, start: second.end },
    ];
  }

  if (overlapsStart(first, second)) {
    return [
      { ...first, end: second.start },
      {
        start: second.start,
        end: first.end,
        priority: first.priority,
        mergableData: [
          ...(first.mergableData ?? []),
          ...(second.mergableData ?? []),
        ],
      },
      { ...second, start: first.end },
    ];
  }

  if (isLeftExtension(first, second)) {
    return [
      { ...first, end: second.start },
      {
        ...second,
        mergableData: [
          ...(first.mergableData ?? []),
          ...(second.mergableData ?? []),
        ],
      },
    ];
  }

  if (isRightExtension(secondEnding, firstEnding)) {
    return [
      {
        ...firstEnding,
        mergableData: [
          ...(firstEnding.mergableData ?? []),
          ...(secondEnding.mergableData ?? []),
        ],
      },
      { ...secondEnding, start: firstEnding.end },
    ];
  }

  console.error("Couldn't reconcile chunks:", a, b);
  return [];
}

function reconcileDifferingPriorityTextChunkPair<
  T extends ReconcilableTextChunk
>(a: T, b: T): T[] {
  const [higherChunk, lowerChunk] =
    (a?.priority ?? 0) > (b?.priority ?? 0) ? [a, b] : [b, a];

  if (haveIdenticalRange(higherChunk, lowerChunk)) {
    return [higherChunk];
  }

  if (isContained(lowerChunk, higherChunk)) {
    return [higherChunk];
  }

  if (isContained(higherChunk, lowerChunk)) {
    return [
      { ...lowerChunk, end: higherChunk.start },
      higherChunk,
      {
        ...lowerChunk,
        start: higherChunk.end,
      },
    ];
  }

  if (overlapsStart(higherChunk, lowerChunk)) {
    return [
      higherChunk,
      {
        ...lowerChunk,
        start: higherChunk.end,
      },
    ];
  }

  if (overlapsEnd(higherChunk, lowerChunk)) {
    return [{ ...lowerChunk, end: higherChunk.start }, higherChunk];
  }

  if (isLeftExtension(higherChunk, lowerChunk)) {
    return [higherChunk];
  }

  if (isRightExtension(higherChunk, lowerChunk)) {
    return [higherChunk];
  }

  console.error("Couldn't reconcile chunks:", a, b);
  return [];
}

export function areOverlapping(a: TextChunk, b: TextChunk): boolean {
  return a.start < b.start ? b.start < a.end : a.start < b.end;
}

function haveIdenticalRange(a: TextChunk, b: TextChunk): boolean {
  return a.start === b.start && a.end === b.end;
}

function isContained(
  containedChunk: TextChunk,
  containerChunk: TextChunk
): boolean {
  return (
    containedChunk.start > containerChunk.start &&
    containedChunk.start < containerChunk.end &&
    containedChunk.end > containerChunk.start &&
    containedChunk.end < containerChunk.end
  );
}

function overlapsStart(
  overlapperChunk: TextChunk,
  overlappedChunk: TextChunk
): boolean {
  return (
    overlapperChunk.end > overlappedChunk.start &&
    overlapperChunk.end < overlappedChunk.end
  );
}

function overlapsEnd(
  overlapperChunk: TextChunk,
  overlappedChunk: TextChunk
): boolean {
  return (
    overlapperChunk.start > overlappedChunk.start &&
    overlapperChunk.start < overlappedChunk.end
  );
}

function isLeftExtension(extendee: TextChunk, extended: TextChunk): boolean {
  return extended.end === extendee.end && extended.start > extendee.start;
}

function isRightExtension(extendee: TextChunk, extended: TextChunk): boolean {
  return extended.start === extendee.start && extended.end < extendee.end;
}
