import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  useRef,
} from "react";

import useClickOutside from "hooks/useClickOutside";
import { LicensingReport, formatAsCSV } from "domain/licensingReports";
import SkipNavigationLink from "atoms/SkipNavigationLink";
import Back from "molecules/Back";
import HelpToggle from "atoms/HelpToggle";
import SimpleSelect from "molecules/SimpleSelect";
import Text from "atoms/Text";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import { SmallChevron } from "components/Icons";
import { BaseDropdown } from "molecules/SelectDropdown";
import { LinkOption } from "atoms/SelectOption";
import { downloadURIEncodedFile } from "domain/dom";
import HelpModal from "organisms/HelpModal";

import TableFactory, { Grouping } from "../TableFactory";
import styles from "./Viewer.module.sass";
import help from "./help.json";

export interface Props {
  navbar: ReactNode;
  licensingReport: LicensingReport;
  onBack: () => void;
  searchParams: String;
}

export default function Viewer({
  navbar,
  licensingReport,
  onBack,
  searchParams,
}: Props) {
  const [isHelpOn, setIsHelpOn] = useState(false);
  const [countText, setCountText] = useState<ReactNode>(<></>);
  const [groupedBy, setGroupedBy] = useState<Grouping>(Grouping.CitedDocument);

  return (
    <>
      <div>
        <SkipNavigationLink />

        {navbar}

        <main
          style={{
            maxWidth: 1600,
            padding: 30,
            boxSizing: "border-box",
            margin: "0 auto",
          }}
        >
          <Header
            title={licensingReport.name || "Examiner Rejection Analysis"}
            isHelpOn={isHelpOn}
            setIsHelpOn={setIsHelpOn}
            onBack={onBack}
          />

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <Text size={15} color="grey850">
              Group by
            </Text>
            <div style={{ marginLeft: 12, width: 200 }}>
              <SimpleSelect
                value={groupedBy}
                label=""
                options={[
                  {
                    value: Grouping.CitedDocument,
                    label: "Cited Document",
                  },
                  {
                    value: Grouping.RejectedAssignee,
                    label: "Rejected Assignee",
                  },
                  { value: Grouping.None, label: "None" },
                ]}
                onChange={(newGroupedBy) => setGroupedBy(newGroupedBy)}
              />
            </div>
            <Text size={15} style={{ marginLeft: 20 }}>
              {countText}
            </Text>
            <Dropdown
              searchParams={searchParams}
              licensingReport={licensingReport}
            >
              Export
            </Dropdown>
          </div>

          <TableFactory
            licensingReport={licensingReport}
            groupedBy={groupedBy}
            setCountText={setCountText}
          />
        </main>
      </div>
      <HelpModal
        {...help}
        isOpen={isHelpOn}
        onDismiss={() => setIsHelpOn(false)}
      />
    </>
  );
}

function Header({
  title,
  onBack,
  isHelpOn,
  setIsHelpOn,
}: {
  title: string;
  isHelpOn: boolean;
  setIsHelpOn: Dispatch<SetStateAction<boolean>>;
  onBack: () => void;
}) {
  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 34,
      }}
    >
      <Back onClick={onBack}>{title}</Back>
      <HelpToggle
        state={isHelpOn ? "on" : "off"}
        onToggle={(state) => setIsHelpOn(state === "on" ? true : false)}
      />
    </header>
  );
}

function exportToCsvFile(licensingReport: LicensingReport) {
  const csvContent = formatAsCSV(licensingReport.citations);
  const uriEncodedCSVFile =
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

  const now = new Date();
  const nowString = [
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
  ]
    .map((v) => String(v).padStart(2, "0"))
    .join("-");
  const fileName = [
    licensingReport.name || "Examiner-Rejection-Analysis",
    nowString,
  ].join("-");

  downloadURIEncodedFile(uriEncodedCSVFile, fileName);
}

function exportToExcelFile(searchParams: String) {
  window.open("/downloads.php?fn=LicensingReport&" + searchParams, "_blank");
}

interface DropdownProps {
  children: ReactNode;
  licensingReport: LicensingReport;
  searchParams: String;
  isCaretVisible?: boolean;
}

function Dropdown({
  children: label,
  isCaretVisible = true,
  licensingReport: LicensingReport,
  searchParams: SearchParams,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref} style={{ marginLeft: "auto" }}>
      <Button
        variant="outlined"
        size="sm"
        style={{ marginLeft: "auto" }}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {label}
        {isCaretVisible && (
          <SmallChevron
            width={11}
            height={6}
            direction={isOpen ? "up" : "down"}
            className={styles.chevron}
          />
        )}
      </Button>
      {isOpen && (
        <BaseDropdown style={{ width: 170 }}>
          <LinkOption
            onClick={() => exportToExcelFile(SearchParams)}
            style={{ fontSize: 14 }}
          >
            Export as Excel
          </LinkOption>
          <LinkOption
            onClick={() => exportToCsvFile(LicensingReport)}
            style={{ display: "flex", alignItems: "center", fontSize: 14 }}
          >
            Export as CSV
          </LinkOption>
        </BaseDropdown>
      )}
    </div>
  );
}
