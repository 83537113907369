import { Assignee, nullAssignee } from "domain/assignees";
import { LicensingReport, CitedApplication, Citation } from "./models";

export interface CitedApplicationWithCitations extends CitedApplication {
  citations: Omit<Citation, "citedApplication">[];
}

export function groupByCitedDocument(report: LicensingReport) {
  type CitedDocument = CitedApplication["citedDocument"];
  type Result = Record<CitedDocument, CitedApplicationWithCitations>;

  return report.citations.reduce<Result>((result, citation) => {
    const citedDocument = citation.citedApplication.citedDocument;

    if (result[citedDocument] === undefined) {
      result[citedDocument] = {
        ...citation.citedApplication,
        citations: [],
      };
    }

    // omit 'citedApplication'
    const { citedApplication, ...truncatedCitation } = citation;
    result[citedDocument].citations.push(truncatedCitation);

    return result;
  }, {});
}

export interface RejectedAssigneeWithCitations extends Assignee {
  citations: Citation[];
}

export function groupByRejectedAssignee(report: LicensingReport) {
  type Result = Record<Assignee["id"], RejectedAssigneeWithCitations>;

  return report.citations.reduce<Result>((result, citation) => {
    const rejectedAssignee = citation.rejectedApplication.assignee;
    const { id, name } = rejectedAssignee || nullAssignee;

    if (result[id] === undefined) {
      result[id] = { id, name, citations: [] };
    }

    result[id].citations.push(citation);

    return result;
  }, {});
}
