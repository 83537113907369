import React, { forwardRef, HTMLAttributes } from "react";

export interface Props extends HTMLAttributes<HTMLDivElement> {
  rotation?: 0 | 45;
}

const Rectangle = forwardRef<HTMLDivElement, Props>(
  ({ rotation = 0, style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        height: 14,
        width: 14,
        transform: rotation === 45 ? "rotate(45deg)" : undefined,
        ...style,
      }}
      {...rest}
    />
  )
);

export default Rectangle;
