import React, { ReactNode, useState } from "react";

import ArgumentFinderTemplate from "templates/ArgumentFinderTemplate";
import Navbar, { EmptyNavbar } from "components/Navbar";
import ArgumentFinder from "organisms/ArgumentFinder";
import useUser from "hooks/useUser";
import { User } from "domain/users";
import useDecision from "hooks/useDecision";
import PageLoader from "molecules/PageLoader";
import { downloadPdfFile } from "domain/ptabDecisions/api"; // TODO: move and change implementation

export interface Props {
  decisionId: number;
  onBack: (() => void) | null;
}

export default function ArgumentFinderPage({ decisionId, onBack }: Props) {
  const { status: userStatus, user, isLoggedOut } = useUser();
  if (isLoggedOut) {
    redirectToLogin();
  }

  const { status: decisionStatus, decision } = useDecision(decisionId);

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadDocument = () => {
    setIsDownloading(true);
    downloadPdfFile("", decisionId).finally(() => {
      setIsDownloading(false);
    });
  };

  const isSpinnerVisible =
    [userStatus, decisionStatus].includes("loading") || isDownloading;

  return (
    <>
      <PageLoader isLoading={isSpinnerVisible} />
      <ArgumentFinderTemplate
        navbar={getNavbar(user)}
        argumentFinder={
          decision && (
            <ArgumentFinder
              decision={decision}
              onBack={onBack}
              onDocumentDownload={downloadDocument}
            />
          )
        }
      />
    </>
  );
}

function redirectToLogin() {
  window.location.href = "/login.php";
}

function getNavbar(user?: User): ReactNode {
  return user ? (
    <Navbar user={user} position="relative" />
  ) : (
    <EmptyNavbar position="relative" />
  );
}
