import React, { useState, useEffect } from "react";
import axios from "axios";

import useDebounce from "hooks/useDebounce";
import { AssigneeWithCitationCount, searchAssignees } from "domain/assignees";

const minimumQueryLength = 2;

export default function useAssigneeSearch(nameFragment: string): {
  assignees: AssigneeWithCitationCount[];
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState(false);
  const debouncedQuery = useDebounce(nameFragment, 150);
  const [assignees, setAssignees] = useState<AssigneeWithCitationCount[]>([]);

  useEffect(() => {
    if (debouncedQuery.length < minimumQueryLength) {
      setAssignees([]);
      return;
    }

    const promise = searchAssignees(debouncedQuery);
    setIsLoading(true);

    promise
      .then((resp) => {
        setAssignees(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const wasCancelled = axios.isCancel(err);
        if (wasCancelled) {
          return;
        }

        setIsLoading(false);
        console.error(err);
      });

    return () => {
      promise.cancel();
    };
  }, [debouncedQuery]);

  return { assignees, isLoading };
}
