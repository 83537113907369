import React from "react";
import cn from "classnames";

import styles from "./module.sass";

const Pulse = ({ variant = "big" }: { variant?: "small" | "big" }) => (
  <div
    className={styles.pulse}
    style={{
      width: variant === "small" ? 36 : 60,
      height: variant === "small" ? 36 : 60,
    }}
  >
    <div className={cn(styles.circle, styles.circle$primary)}></div>
    <div className={cn(styles.circle, styles.circle$secondary)}></div>
  </div>
);

export default Pulse;
