import React, { ReactNode } from "react";

import { capitalize } from "domain/strings";
import { Event as TimelineEvent } from "domain/applications";
import Text from "atoms/Text";
import {
  AppealEvent,
  DetaillessEvent,
  OfficeActionEvent,
} from "domain/applications/events";
import Anchor from "atoms/Anchor";
import { PdfIcon as PDFIcon } from "components/Icons";

/**
 * @deprecated
 * This isn't really needed when we have the abstract EventViewModel.
 */
export interface IEventViewModel {
  readonly color: string;
  readonly size: "medium" | "large";
  readonly shortDate: string;
  getShortName: (ordinalNumber: number) => string;
  renderPopoverContent: (ordinalNumber: number) => ReactNode;
}

export function createEventViewModel(
  domainEvent: TimelineEvent
): IEventViewModel {
  const { name } = domainEvent;

  if (name === "appeal") {
    return new AppealEventViewModel(domainEvent as AppealEvent);
  }
  if (name === "officeAction") {
    return new OfficeActionEventViewModel(domainEvent as OfficeActionEvent);
  }
  if (name === "filing") {
    return new FilingEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "publication") {
    return new PublicationEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "RCE") {
    return new RCEEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "interview") {
    return new InterviewEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "abandonment") {
    return new AbandonmentEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "issuance") {
    return new IssuanceEventViewModel(domainEvent as DetaillessEvent);
  }
  if (name === "noticeOfAllowance") {
    return new NoticeOfAllowanceEventViewModel(domainEvent as DetaillessEvent);
  }

  throw new Error(`Can't create a view model for event with name ${name}.`);
}

abstract class EventViewModel<Event extends { name: string } = DetaillessEvent>
  implements IEventViewModel
{
  public readonly color: string = "#000000";
  public readonly size: "medium" | "large" = "medium";

  protected static shortDateFormatter = Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });
  protected static longDateFormatter = Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  });

  protected domainEvent: Event;

  constructor(domainEvent: Event) {
    this.domainEvent = domainEvent;
  }

  get shortDate() {
    return EventViewModel.shortDateFormatter.format(this.mainDate);
  }

  getShortName(ordinalNumber: number) {
    return capitalize(this.domainEvent.name);
  }

  protected getLongName(ordinalNumber: number): ReactNode {
    return this.getShortName(ordinalNumber);
  }

  protected get mainDate() {
    if (!("date" in this.domainEvent)) {
      throw new Error("`get mainDate()` unimplemented");
    }
    return (this.domainEvent as any).date;
  }

  protected get longDate() {
    return EventViewModel.longDateFormatter.format(this.mainDate);
  }

  renderPopoverContent(ordinalNumber: number): ReactNode {
    return (
      <div style={{ width: 211 }}>
        <Text
          size={16}
          weight="bold"
          style={{ lineHeight: "24px", whiteSpace: "nowrap" }}
        >
          {this.getLongName(ordinalNumber)}
        </Text>
        <Text
          as="div"
          size={14}
          style={{ lineHeight: "21px", whiteSpace: "nowrap" }}
        >
          {this.longDate}
        </Text>
      </div>
    );
  }
}

class FilingEventViewModel extends EventViewModel {
  public readonly color = "#607D8B";

  protected getLongName(ordinalNumber: number) {
    return `Filing Date`;
  }
}

class PublicationEventViewModel extends EventViewModel {
  public readonly color = "#90A4AE";

  protected getLongName(ordinalNumber: number) {
    return `Publication Date`;
  }
}

/**
 * Request For Continued Examination Event View Model
 */
class RCEEventViewModel extends EventViewModel {
  public readonly color = "#9C27B0";

  getShortName(ordinalNumber: number) {
    return `RCE ${ordinalNumber}`;
  }

  protected getLongName(ordinalNumber: number) {
    return `RCE ${ordinalNumber}`;
  }
}

class InterviewEventViewModel extends EventViewModel {
  public readonly color = "#FB8C00";

  getShortName(ordinalNumber: number) {
    return `Interview ${ordinalNumber}`;
  }

  protected getLongName(ordinalNumber: number) {
    return `Interview ${ordinalNumber}`;
  }
}

class AbandonmentEventViewModel extends EventViewModel {
  public readonly color = "#D60019";
  public readonly size = "large";

  protected getLongName(ordinalNumber: number) {
    return "Abandonment";
  }
}

class NoticeOfAllowanceEventViewModel extends EventViewModel {
  public readonly color = "#C0CA33";

  getShortName(ordinalNumber: number) {
    return "NOA";
  }

  protected getLongName(ordinalNumber: number) {
    return "Notice of Allowance";
  }
}

class IssuanceEventViewModel extends EventViewModel {
  public readonly color = "#43A047";
  public readonly size = "large";

  protected getLongName(ordinalNumber: number) {
    return "Issuance";
  }
}

class AppealEventViewModel extends EventViewModel<AppealEvent> {
  public readonly color = "#FFC107";

  private get hasDocs() {
    return [
      this.domainEvent.appealBriefLink,
      this.domainEvent.examinersAnswerLink,
      this.domainEvent.replyBriefLink,
      this.domainEvent.PTABCaseLink,
    ].some((link) => link !== null);
  }

  getShortName(ordinalNumber: number) {
    return `Appeal ${ordinalNumber}`;
  }

  renderPopoverContent(ordinalNumber: number) {
    const {
      appealBriefLink,
      examinersAnswerLink,
      replyBriefLink,
      PTABCaseLink,
    } = this.domainEvent;

    return (
      <div style={{ width: 211 }}>
        <Text
          size={16}
          weight="bold"
          style={{ lineHeight: "24px", whiteSpace: "nowrap" }}
        >
          {this.getLongName(ordinalNumber)}
        </Text>
        <Text
          as="div"
          size={14}
          style={{ marginBottom: 16, lineHeight: "21px", whiteSpace: "nowrap" }}
        >
          {this.longDate}
        </Text>
        {!this.hasDocs && (
          <Text size={14} color="grey600" style={{ fontStyle: "italic" }}>
            No documents available
          </Text>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {[
            [appealBriefLink, "Appeal Brief"],
            [examinersAnswerLink, "Examiner's Answer"],
            [replyBriefLink, "Reply Brief"],
            [PTABCaseLink, "PTAB Case"],
          ]
            .filter(([link, label]) => link !== null)
            .map(([link, label], i) => (
              <DocumentLink key={i} url={link as string}>
                {label as string}
              </DocumentLink>
            ))}
        </div>
      </div>
    );
  }

  protected getLongName(ordinalNumber: number) {
    return `Appeal ${ordinalNumber}`;
  }
}

class OfficeActionEventViewModel extends EventViewModel<OfficeActionEvent> {
  public readonly color = "#1E88E5";

  protected get mainDate() {
    return this.domainEvent.rejectionDate;
  }

  private get finality(): "Final" | "Non-Final" {
    return this.domainEvent.isFinal ? "Final" : "Non-Final";
  }

  private get responseDocs() {
    const { responseLink, claimsLink } = this.domainEvent;
    return [responseLink, claimsLink].filter(Boolean);
  }

  getShortName(ordinalNumber: number) {
    return `OA ${ordinalNumber}`;
  }

  protected getLongName(ordinalNumber: number) {
    return (
      <>
        {`Office Action ${ordinalNumber}`} <i>({this.finality})</i>
      </>
    );
  }

  renderPopoverContent(ordinalNumber: number) {
    const {
      rejectionLink,
      responseLink,
      claimsLink,
      rejectionDate,
      responseDate,
    } = this.domainEvent;

    const dateFormatter = OfficeActionEventViewModel.longDateFormatter;
    const hasResponseSection =
      responseDate !== null || this.responseDocs.length > 0;

    return (
      <div style={{ width: hasResponseSection ? 382 : 242 }}>
        <Text
          size={16}
          weight="bold"
          style={{ marginBottom: 24, lineHeight: "24px", whiteSpace: "nowrap" }}
        >
          {this.getLongName(ordinalNumber)}
        </Text>
        <div style={{ display: "flex", flex: 1 }}>
          <div
            style={{
              paddingLeft: 12,
              flex: 1,
              borderLeft: "8px solid #1e88e5",
            }}
          >
            <Text
              size={14}
              weight="bold"
              style={{ lineHeight: "12px", marginBottom: 6 }}
            >
              REJECTION
            </Text>
            <Text size={14} style={{ marginBottom: 18 }}>
              {dateFormatter.format(rejectionDate)}
            </Text>

            {rejectionLink ? (
              <DocumentLink url={rejectionLink}>
                {`${this.finality} Rejection`}
              </DocumentLink>
            ) : (
              <Text size={14} color="grey600" style={{ fontStyle: "italic" }}>
                No documents available
              </Text>
            )}
          </div>
          {responseDate && (
            <div
              style={{
                marginLeft: 14,
                paddingLeft: 12,
                flex: 1,
                borderLeft: "8px solid #0d47a1",
              }}
            >
              <Text
                size={14}
                weight="bold"
                style={{ lineHeight: "12px", marginBottom: 6 }}
              >
                RESPONSE
              </Text>
              {responseDate && (
                <Text size={14} style={{ marginBottom: 18 }}>
                  {dateFormatter.format(responseDate)}
                </Text>
              )}
              {!this.responseDocs.length && (
                <Text size={14} color="grey600" style={{ fontStyle: "italic" }}>
                  No documents available
                </Text>
              )}
              {[
                [responseLink, "Response"],
                [claimsLink, "Claims"],
              ]
                .filter(([link, label]) => link !== null)
                .map(([link, label], i) => (
                  <DocumentLink key={i} url={link as string}>
                    {label as string}
                  </DocumentLink>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function DocumentLink({
  children: label,
  url,
}: {
  children: string;
  url: string;
}) {
  return (
    <Text
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 4,
        fontSize: 14,
      }}
    >
      {label}
      <Anchor href={url as string} target="_blank">
        <PDF />
      </Anchor>
    </Text>
  );
}

function PDF() {
  return <PDFIcon width={16} height={16} style={{ marginLeft: 4 }} />;
}
