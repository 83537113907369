import React, { MouseEventHandler, useState } from "react";

import Modal from "components/Modal";
import Button from "components/Button";
import DatePicker from "components/DatePicker";

export type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  onGenerate: (dateFrom: Date, dateTo: Date | null) => void;
};

export default function PPRDialog({ isOpen, onDismiss, onGenerate }: Props) {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateFromError, setDateFromError] = useState("");

  const validate = () => {
    setDateFromError("");
    if (!dateFrom) {
      setDateFromError("This field is required.");
      return false;
    }

    return true;
  };
  const handleGenerate: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!validate()) {
      return;
    }
    onGenerate(dateFrom as Date, dateTo);
  };

  return (
    <Modal
      title="Portfolio Performance Report"
      ariaLabel="Portfolio Performance Report"
      footer={
        <>
          <Button variant="outlined" size="xs" onClick={onDismiss}>
            <span style={{ minWidth: 50 }}>Close</span>
          </Button>
          <span style={{ paddingLeft: 6 }} />
          <Button variant="contained" size="xs" onClick={handleGenerate}>
            <span style={{ minWidth: 50 }}>Generate</span>
          </Button>
        </>
      }
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <div style={{ display: "flex" }}>
        <DatePicker
          label="Date from"
          date={dateFrom}
          error={dateFromError}
          isFutureDisabled
          onChange={(value) => setDateFrom(value)}
        />
        <span style={{ paddingLeft: 16 }} />
        <DatePicker
          label="Date to"
          date={dateTo}
          isFutureDisabled
          onChange={(value) => setDateTo(value)}
        />
      </div>
    </Modal>
  );
}
