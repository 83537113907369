import React from "react";
import useBrowserExtensionSettings from "hooks/useBrowserExtensionSettings";
import Anchor from "atoms/Anchor";

export const About = () => {
  const { supportDocumentsUrl } = useBrowserExtensionSettings();

  const etaUrl = supportDocumentsUrl?.data.etaUrl;

  return (
    <ul style={{ marginTop: "14px", paddingLeft: "18px", fontSize: 16 }}>
      <li>Examiner Allowance Rate</li>
      <li>
        PatentAdvisor Examiner{" "}
        {etaUrl !== "#" ? (
          <>
            <Anchor href={etaUrl} target="_blank">
              ETA™
            </Anchor>
          </>
        ) : (
          "ETA™"
        )}
      </li>
      <li>Art Unit Allowance Rate</li>
      <li>Average number of office actions to allowance</li>
      <li>The PatentAdvisor Application Report PDF</li>
      <li>Links directly back to Examiner &amp; Art Unit Reports</li>
      <li>Direct link to search the File Wrapper limited to your examiner</li>
    </ul>
  );
};
