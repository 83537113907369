import {
  AppealEvent,
  AppStatus,
  DetaillessEvent,
  Event,
  OfficeActionEvent,
} from "domain/applications";
import { Examiner } from "domain/examiners";
import { getDaysInMonth } from "domain/dates";

export type EventWithRange =
  | OfficeActionEvent
  | AppealEvent
  | (DetaillessEvent & { name: "RCE" });

export interface ITimelineViewModel {
  applicationStatus: AppStatus;
  today: Date;
  events: Event[];
  firstYear: number;
  lastYear: number;
  expectedApprovalWindow: { start: Date; middle: Date; end: Date } | null;
  readableExaminersExpectedApprovalWindow: {
    lowerBoundary: string;
    average: string;
    upperBoundary: string;
  } | null;
  getDateOffset(date: Date, correction?: string): string;
  getWidth(start: Date, end: Date): string;
  getEventOffset(event: Event, correction?: string): string;
  getEventWithRangeWidth(event: EventWithRange): string;
  getEventWithRangeRightBoundaryDate(event: EventWithRange): Date;
  getEventOrdinalNumberAmongSameType(event: Event): number;
}

export class TimelineViewModel implements ITimelineViewModel {
  public readonly today;
  public readonly events;
  public readonly applicationStatus;
  public readonly expectedApprovalWindow;

  private examinersExpectedApprovalWindow;
  private dates;

  constructor(
    applicationStatus: AppStatus,
    events: Event[],
    today = new Date(),
    examinersExpectedApprovalWindow: Examiner["expectedApprovalWindow"] = null
  ) {
    this.applicationStatus = applicationStatus;
    this.today = today;
    this.events = events.sort((a, b) =>
      this.getEventMainDate(a) < this.getEventMainDate(b) ? -1 : 1
    );

    // temporal coupling between all three statements below
    this.examinersExpectedApprovalWindow = examinersExpectedApprovalWindow;
    this.expectedApprovalWindow = this.getExpectedApprovalWindow();
    this.dates = this.getDates();
  }

  get firstYear() {
    return this.firstDate ? this.firstDate.getFullYear() : this.currentYear - 1;
  }

  get lastYear() {
    if (!this.lastDate) {
      return this.currentYear;
    }

    const isCloseToEnd = this.lastDate.getMonth() === 11;
    const isTodayOrApprovalWindow = [
      this.today,
      this.expectedApprovalWindow?.end,
    ].some((d) => d?.getTime() === this.lastDate.getTime());
    const lastDateYear = this.lastDate.getFullYear();

    return isCloseToEnd && isTodayOrApprovalWindow
      ? lastDateYear + 1
      : lastDateYear;
  }

  get readableExaminersExpectedApprovalWindow() {
    if (!this.examinersExpectedApprovalWindow) {
      return null;
    }

    const { lowerBoundaryInMonths, averageInMonths, upperBoundaryInMonths } =
      this.examinersExpectedApprovalWindow;

    return {
      lowerBoundary: this.formatMonthsAsReadable(lowerBoundaryInMonths),
      average: this.formatMonthsAsReadable(averageInMonths),
      upperBoundary: this.formatMonthsAsReadable(upperBoundaryInMonths),
    };
  }

  getDateOffset(date: Date, correction: string = "0px") {
    const { monthOffset, dayOfMonthOffset } = this.getDetailedDateOffset(date);
    return `calc(${monthOffset} + ${dayOfMonthOffset} - ${correction})`;
  }

  getEventOffset(event: Event, correction: string = "0px") {
    const date = this.getEventMainDate(event);
    return this.getDateOffset(date, correction);
  }

  getWidth(start: Date, end: Date, correction: string = "0px") {
    const startOffset = this.getDetailedDateOffset(start);
    const endOffset = this.getDetailedDateOffset(end);

    const formattedStartOffset = `(${startOffset.monthOffset} + ${startOffset.dayOfMonthOffset})`;
    const formattedEndOffset = `(${endOffset.monthOffset} + ${endOffset.dayOfMonthOffset})`;

    return `calc(${formattedEndOffset} - ${formattedStartOffset} - ${correction})`;
  }

  getEventWithRangeWidth(event: EventWithRange) {
    const start = this.getEventMainDate(event);
    const end = this.getEventWithRangeRightBoundaryDate(event);

    const rightBoundary = this.getEventWithRangeRightBoundary(event);
    const correction = event.name === rightBoundary.name ? "2px" : "0px";

    return this.getWidth(start, end, correction);
  }

  /**
   * @see this.delineatingEvents
   */
  getEventWithRangeRightBoundaryDate(event: EventWithRange): Date {
    const nextEvent = this.getEventWithRangeRightBoundary(event);
    return nextEvent.name === "today"
      ? this.today
      : this.getEventMainDate(nextEvent);
  }

  /**
   * @see this.delineatingEvents
   */
  private getEventWithRangeRightBoundary(
    event: EventWithRange
  ): Event | { name: "today"; date: Date } {
    const index = this.delineatingEvents.indexOf(event);
    const nextEvent = this.delineatingEvents[index + 1];

    return nextEvent ?? { name: "today", date: this.today };
  }

  getEventOrdinalNumberAmongSameType(event: Event) {
    return this.events.filter((e) => e.name === event.name).indexOf(event) + 1;
  }

  /**
   * Delineating events are those which can be the right border
   * of office actions, RCEs and approval events (so called events with range)
   * on the extended timeline where those three are shown as time ranges.
   */
  private get delineatingEvents() {
    return this.events.filter((e) => e.name !== "interview");
  }

  private get monthLengthInCSS() {
    return `${this.yearLengthInCSS} / 12`;
  }

  private get yearLengthInCSS() {
    return `100% / ${this.yearCount}`;
  }

  private get yearCount() {
    return this.lastYear - this.firstYear + 1;
  }

  private get firstDate() {
    return this.dates?.[0] ?? null;
  }

  private get lastDate() {
    return this.dates?.[this.dates.length - 1] ?? null;
  }

  private get currentYear() {
    return this.today.getFullYear();
  }

  private get filingDate() {
    return (
      this.events.find((e): e is DetaillessEvent => e.name === "filing")
        ?.date ?? null
    );
  }

  private getDetailedDateOffset(date: Date) {
    const start = new Date(this.firstYear, 0, 1);

    const diffInMonths = this.getDiffInMonths(start, date);
    const monthOffset = `(${this.monthLengthInCSS} * ${diffInMonths})`;

    const daysInMonth = getDaysInMonth(date);
    const dayOfMonthOffset = `(
      ${date.getDate() - 1}
      * ${this.monthLengthInCSS}
      / ${daysInMonth}
    )`;

    return { monthOffset, dayOfMonthOffset };
  }

  private getExpectedApprovalWindow() {
    if (!this.filingDate || !this.examinersExpectedApprovalWindow) {
      return null;
    }

    const { lowerBoundaryInMonths, averageInMonths, upperBoundaryInMonths } =
      this.examinersExpectedApprovalWindow;
    const filingTime = this.filingDate.getTime();

    return {
      start: new Date(
        filingTime + this.convertMonthsToMiliseconds(lowerBoundaryInMonths)
      ),
      middle: new Date(
        filingTime + this.convertMonthsToMiliseconds(averageInMonths)
      ),
      end: new Date(
        filingTime + this.convertMonthsToMiliseconds(upperBoundaryInMonths)
      ),
    };
  }

  private getDates() {
    return this.events
      .map(this.getEventMainDate)
      .concat(this.applicationStatus === "pending" ? [this.today] : [])
      .concat(
        this.expectedApprovalWindow
          ? Object.values(this.expectedApprovalWindow)
          : []
      )
      .sort((a, b) => (a < b ? -1 : 1));
  }

  private getDiffInMonths(start: Date, end: Date) {
    return (
      12 * (end.getFullYear() - start.getFullYear()) +
      end.getMonth() -
      start.getMonth()
    );
  }

  private getEventMainDate(event: Event) {
    return "rejectionDate" in event ? event.rejectionDate : event.date;
  }

  private convertMonthsToMiliseconds(months: number): number {
    return months * 30.4 * 24 * 60 * 60 * 1000;
  }

  private formatMonthsAsReadable(months: number) {
    const years = Math.floor(months / 12);
    const leftoverMonths = months % 12;
    const yearsPart = years ? `${years} ${years === 1 ? "year" : "years"}` : "";
    const monthsPart = leftoverMonths
      ? `${leftoverMonths} ${leftoverMonths === 1 ? "month" : "months"}`
      : "";

    return `${yearsPart}${
      yearsPart && leftoverMonths ? ", " : ""
    }${monthsPart}`;
  }
}
