import React, { useState, useEffect } from "react";

import useDebounce from "hooks/useDebounce";
import { searchExaminers, APIExaminer } from "domain/examiners";

/**
 * Returns null if the results haven't been fetched, otherwise returns the results.
 */
export default function useExaminerAutocomplete(
  nameFragment: string
): APIExaminer[] | null {
  const debouncedQuery = useDebounce(nameFragment, 150);
  const [suggestions, setSuggestions] = useState<APIExaminer[] | null>(null);

  useEffect(() => {
    if (debouncedQuery.length < 3) {
      setSuggestions(null);
      return;
    }

    const promise = searchExaminers(debouncedQuery);

    promise
      .then((resp) => {
        if (!resp.data.data || resp.data.status === "error") {
          setSuggestions([]);
          return;
        }

        setSuggestions(resp.data.data);
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      promise.cancel();
    };
  }, [debouncedQuery]);

  return suggestions;
}
