import React from "react";
import { useQuery } from "react-query";

import { findLatest } from "domain/releaseNotes";

export default function useReleaseNotes({
  isEnabled = true,
}: { isEnabled?: boolean } = {}) {
  const { status, data: releaseNotes = [], error } = useQuery(
    "latestReleaseNotes",
    findLatest,
    {
      enabled: isEnabled,
    }
  );

  return { status, releaseNotes, error };
}
