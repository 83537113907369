import React, { HTMLAttributes } from "react";

import Separator from "components/Separator";
import Button from "components/Button";
import { SmallChevron, X } from "components/Icons";
import colors from "style/colors.module.scss";
import Text from "atoms/Text";

import styles from "./module.sass";

export type Props = {
  query: string;
  placeholder: string;
  focusedItem: number;
  itemCount: number;
  onChange: (query: string) => void;
  onFocusedItemChange: (focusedItem: number) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange">;

export default function FinderInput({
  query,
  placeholder,
  focusedItem,
  itemCount,
  onChange,
  onFocusedItemChange,
  ...rest
}: Props) {
  const canClear = query.length !== 0;
  const canGoToPrevious = itemCount > 0;
  const canGoToNext = itemCount > 0;

  const isFirstItemFocused = focusedItem === 1;
  const isUnfocused = focusedItem === 0;
  const isLastItemFocused = focusedItem === itemCount;

  const previousItem =
    isFirstItemFocused || isUnfocused ? itemCount : focusedItem - 1;
  const nextItem = isLastItemFocused ? 1 : focusedItem + 1;

  return (
    <div className={styles.finderInput} {...rest}>
      <input
        className={styles.input}
        value={query}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      <Text color="grey900" style={{ marginLeft: 12 }}>
        {focusedItem}/{itemCount}
      </Text>
      <Separator style={{ marginLeft: 16, marginRight: 12 }} />
      <Button
        size="xxxs"
        style={{ marginLeft: 4 }}
        isDisabled={!canGoToPrevious}
        onClick={() => onFocusedItemChange(previousItem)}
      >
        <SmallChevron
          direction="up"
          style={{ fill: getIconColor(!canGoToPrevious) }}
        />
      </Button>
      <Button
        size="xxxs"
        style={{ marginLeft: 4 }}
        isDisabled={!canGoToNext}
        onClick={() => onFocusedItemChange(nextItem)}
      >
        <SmallChevron
          direction="down"
          style={{ fill: getIconColor(!canGoToNext) }}
        />
      </Button>
      <Button
        size="xxxs"
        style={{ marginLeft: 4 }}
        isDisabled={!canClear}
        onClick={() => onChange("")}
      >
        <X width={10} height={10} style={{ fill: getIconColor(!canClear) }} />
      </Button>
    </div>
  );
}

function getIconColor(isDisabled: boolean) {
  return isDisabled ? colors.grey350 : colors.grey850;
}
