import React from "react";

const Plus = () => (
  <svg
    width="16"
    height="16"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <path d="M13.1 0h3.8v30h-3.8z" />
    <path d="M30 13.1v3.8H0v-3.8z" />
  </svg>
);

export default Plus;
