import React, { HTMLAttributes } from "react";

export type Props = HTMLAttributes<HTMLElement>;

const SmallReg = ({ style, ...rest }: Props) => (
  <sup
    style={{
      fontSize: 10,
      top: "-.75em",
      ...style,
    }}
    {...rest}
  >
    &reg;
  </sup>
);

export default SmallReg;
