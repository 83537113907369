import React, { ReactNode, useState } from "react";
import cn from "classnames";
import styles from "./module.sass";

const Tabs = ({
  titles,
  children,
}: {
  children: Array<ReactNode>;
  titles: Array<string>;
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <>
      <div className={styles.controls}>
        {titles?.map((title, index) => {
          return (
            <button
              key={`tab-${title}-${index}`}
              className={cn(
                styles.button,
                index === currentTabIndex && styles.button$active
              )}
              type={"button"}
              onClick={() => {
                setCurrentTabIndex(index);
              }}
            >
              {title}
            </button>
          );
        })}
      </div>

      {React.Children.toArray(children)[currentTabIndex]}
    </>
  );
};

export default Tabs;
