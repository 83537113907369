import React from "react";
import { useQuery } from "react-query";

import {
  fetchApplicationById,
  fetchApplicationByNumber,
} from "domain/applications/api";

export type Options =
  | {
      applicationNumber: number;
      applicationId: null;
      isEnabled?: boolean;
    }
  | {
      applicationNumber: null;
      applicationId: number;
      isEnabled?: boolean;
    };

export default function useApplication({
  applicationNumber,
  applicationId,
  isEnabled = true,
}: Options) {
  const queryId = applicationNumber
    ? ["applicationWithNumber", applicationNumber]
    : ["applicationWithId", applicationId];

  const {
    status,
    data: application,
    error,
  } = useQuery(
    queryId,
    ({ queryKey }) => {
      const [name, applicationNumberOrId] = queryKey;

      if (name === "applicationWithNumber") {
        const applicationNumber = Number(applicationNumberOrId);
        return fetchApplicationByNumber(applicationNumber);
      }

      const applicationId = Number(applicationNumberOrId);
      return fetchApplicationById(applicationId);
    },
    { enabled: isEnabled }
  );

  return { status, application, error };
}
