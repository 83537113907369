import React from "react";

const OpposingArrows = ({ className = "" }: { className?: string }) => (
  <svg
    width={24}
    height={10.5}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 7"
    className={className}
  >
    <path d="M0 3.5l4.3 2.7V.8zM16 3.5L11.7.8v5.4z" />
  </svg>
);

export default OpposingArrows;
