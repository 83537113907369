import React, { SVGProps } from "react";

export default function SmallChevron({
  direction = "down",
  width = 10.34,
  height = 5.57,
  ...rest
}: {
  direction?: "up" | "down" | "right";
} & SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.34 5.57"
      width={width}
      height={height}
      transform={directionToTransform[direction]}
      {...rest}
    >
      <g>
        <polygon points="5.17 5.57 0 1.09 0.94 0 5.17 3.66 9.4 0 10.34 1.09 5.17 5.57" />
      </g>
    </svg>
  );
}

const directionToTransform = {
  down: "",
  up: "rotate(180)",
  right: "rotate(270)",
};
