import React, { useCallback, useState } from "react";
import cn from "classnames";
import styles from "./module.sass";
import {
  capitalise,
  timeoutAsync,
} from "components/PTABDecisions/components/utils";
import Text from "components/Text";

export type ToastType = {
  variant: "error" | "warning" | "info" | "success";
  content?: any;
  message: string;
};

const Toast = ({ message, content, variant }: ToastType) => {
  return (
    <div className={styles.toastContainer}>
      <div className={cn(styles.toast, styles[`toast$${variant}`])}>
        {!!content && content}
        {!content && <Text>{capitalise(message)}</Text>}
      </div>
    </div>
  );
};

const TOAST_DELAY = 8000;

export const useToast = () => {
  const [toast, setToast] = useState<ToastType | undefined>();

  const setExpiringToast = useCallback((toast: ToastType) => {
    setToast(toast);

    timeoutAsync(TOAST_DELAY).then(() => {
      setToast(undefined);
    });
  }, []);

  const showErrorToast = useCallback((message) => {
    setExpiringToast({ message, variant: "error" });
  }, []);

  const showWarningToast = useCallback((message) => {
    setExpiringToast({ message, variant: "warning" });
  }, []);

  const showInfoToast = useCallback((message) => {
    setExpiringToast({ message, variant: "info" });
  }, []);

  const showSuccessToast = useCallback((message) => {
    setExpiringToast({ message, variant: "success" });
  }, []);

  const showCustomToast = useCallback(
    (content: any, variant: "error" | "warning" | "info" | "success") => {
      setExpiringToast({ variant: variant, content: content, message: "" });
    },
    []
  );

  return {
    toast,
    showErrorToast,
    showWarningToast,
    showInfoToast,
    showSuccessToast,
    showCustomToast,
  };
};

export type ToastContextType =
  | {
      toast: ToastType | undefined;
      showErrorToast: (message: string) => void;
      showWarningToast: (message: string) => void;
      showInfoToast: (message: string) => void;
      showSuccessToast: (message: string) => void;
      showCustomToast: (
        content: any,
        variant: "error" | "warning" | "info" | "success"
      ) => void;
    }
  | undefined;

export const ToastContext = React.createContext<ToastContextType>(undefined);

export const useToastContext = (): ToastContextType => {
  const context = React.useContext(ToastContext);
  return context;
};

export const ToastProvider = (props: any) => {
  const toast = useToast();
  return <ToastContext.Provider value={toast} {...props} />;
};

export default Toast;
