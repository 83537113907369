import React from "react";
import { useWindowWidth } from "@react-hook/window-size";

export type Breakpoint = "xs" | "md" | "lg" | "xl";

export default function useWidthBreakpoint() {
  const windowWidth: number = useWindowWidth();
  return getBreakpoint(windowWidth);
}

function getBreakpoint(windowWidth: number): Breakpoint {
  if (windowWidth < 886) {
    return "xs";
  } else if (windowWidth >= 886 && windowWidth < 1366) {
    return "md";
  } else if (windowWidth >= 1366 && windowWidth < 1600) {
    return "lg";
  } else {
    return "xl";
  }
}
