import React from "react";

import Popup from "organisms/Popup";
import Text from "atoms/Text";
import HorizontalRule from "atoms/HorizontalRule";

export interface Item {
  title: string;
  description: string;
}

export interface Props {
  title: string;
  items: Item[];

  intro?: string;
  isOpen?: boolean;
  onDismiss?: () => void;
}

export default function HelpModal({
  title,
  items,
  intro = "",
  isOpen = true,
  onDismiss = () => {},
}: Props) {
  return (
    <Popup title={title} isOpen={isOpen} onDismiss={onDismiss}>
      <Intro>{intro}</Intro>
      {items.map((item) => (
        <ItemComponent key={item.title} {...item} />
      ))}
    </Popup>
  );
}

interface IntroProps {
  children: string;
}

function Intro({ children: intro }: IntroProps) {
  if (intro === "") {
    return null;
  }
  return (
    <>
      <Text size={15} color="blueGray900" style={{ lineHeight: "22px" }}>
        {intro}
      </Text>
      <HorizontalRule style={{ margin: "28px 0" }} />
    </>
  );
}

function ItemComponent({ title, description }: Item) {
  return (
    <div style={{ marginBottom: 30, paddingRight: 18 }}>
      <Text as="header" size={18} weight="bold" style={{ marginBottom: 6 }}>
        {title}
      </Text>
      <Text size={15} color="blueGray900" style={{ lineHeight: "22px" }}>
        {description}
      </Text>
    </div>
  );
}
