import React, { HTMLAttributes } from "react";

import styles from "./List.module.sass";

export interface Props extends HTMLAttributes<HTMLUListElement> {}

export default function (props: Props) {
  return <ul className={styles.list} {...props} />;
}

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {}

export function ListItem(props: ListItemProps) {
  return <li className={styles.listItem} {...props} />;
}
