import React from "react";
import useBrowserExtensionSettings from "hooks/useBrowserExtensionSettings";
import PageTitle from "components/PageTitle";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import { About } from "./Copy";
import SupportList from "./SupportList";
import BeforeAfter from "molecules/BeforeAfter";

import styles from "./module.sass";
import cn from "classnames";

export type WithoutExtensionProps = {
  imageWithExtension: string;
  imageWithoutExtension: string;
};

const SettingsWithoutExtension = ({
  imageWithExtension,
  imageWithoutExtension,
}: WithoutExtensionProps) => {
  const { browserExtensionUrl, currentBrowser } = useBrowserExtensionSettings();

  return (
    <>
      <PageTitle>
        Browser Extension for Patent Center
      </PageTitle>

      <main className={cn(styles.grid, styles.grid$custom)}>
        <div>
          <div style={{ marginBottom: "35px", fontSize: 16 }}>
            Easily access the prosecution analytics that you rely on directly in
            Patent Center. 
            <br />
            Our browser extension embeds your favorite PatentAdvisor data
            like ETA and allowance rate right into each application in Patent
            Center.
          </div>

          {browserExtensionUrl?.data ? (
            <div style={{ marginBottom: "40px" }}>
              <div style={{ marginBottom: "10px", fontSize: 16 }}>
                Download the PatentAdvisor Extension:
              </div>
              <Anchor
                href={browserExtensionUrl?.data}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" size="sm">
                  Add to {currentBrowser?.data}
                </Button>
              </Anchor>
            </div>
          ) : null}

          <div style={{ marginBottom: "47px", fontSize: 16 }}>
            Once installed, you’ll have access to:
            <br />
            <About />
          </div>
          <div
            className={styles.footerLinks}
            style={{ padding: "45px 0 10px" }}
          >
            <SupportList hasBrowserExtension={false} />
          </div>
        </div>

        <div className={styles.beforeAfterContainer}>
          <BeforeAfter
            beforeImageUrl={imageWithExtension}
            afterImageUrl={imageWithoutExtension}
            beforeImageAltText="With Extension"
            afterImageAltText="Without Borwser Extension"
          />
          <div
            style={{
              margin: "4px 0 0",
              maxWidth: "684px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 14,
            }}
          >
            <span>With Extension Installed</span>
            <span>Without Extension</span>
          </div>
        </div>
      </main>
    </>
  );
};

export default SettingsWithoutExtension;
