import React from "react";
import { browserName } from "react-device-detect";

import { EmptyNavbar } from "components/Navbar";
import { Row, Divider } from ".";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import SkipNavigationLink from "atoms/SkipNavigationLink";

import styles from "./module.sass";

const SignedOut = () => {
  const isChrome = browserName == "Chrome" ? true : false;

  return (
    <>
      <SkipNavigationLink />
      <EmptyNavbar />

      <main className={styles.thankYouContainer}>
        <h1 className={styles.title} style={{ padding: "2px 0 63px" }}>
          Thank you for downloading the PatentAdvisor Extension!
        </h1>
        <Row
          subtitleStyle={{ margin: "12px 0 20px" }}
          subtitle="Get set up for free access"
          image="/assets/img/thankyou/image-pair-laptop.png"
        >
          <>
            <p style={{ margin: 0, lineHeight: "21px" }}>
              You are one step away from accessing the most essential examiner
              statistics right where you need them on the USPTO Patent Center
              and Private PAIR websites. Please click the link below to register
              for the free LexisNexis PatentAdvisor Extension.
            </p>
            <div style={{ margin: "20px 0 45px" }}>
              <Anchor
                href="/app.php#extension-registration"
                style={{ textDecoration: "none" }}
              >
                <Button variant={"brand"} size={"sm"}>
                  Get Set Up
                </Button>
              </Anchor>
            </div>
            <h3 style={{ fontSize: 18, marginBottom: 6 }}>
              Already have a PatentAdvisor account?
            </h3>
            <p style={{ margin: "0 0 2px", lineHeight: "21px" }}>
              <Anchor
                href="/login.php?back=%2Fbrowser-extension.php"
                style={{ fontSize: "inherit", lineHeight: "inherit" }}
              >
                Sign in
              </Anchor>{" "}
              to see the extension in action.
            </p>
          </>
        </Row>

        {isChrome && (
          <>
            <Divider style={"33px 0 41px"} />
            <Row
              subtitleStyle={{ margin: "-7px 0 20px" }}
              subtitle="Pin the extension"
              image="/assets/img/thankyou/image-pin-extension.png"
            >
              <>
                <p style={{ margin: 0, lineHeight: "21px" }}>
                  To make accessing the extension easier, please pin it by
                  following these steps:
                </p>
                <ol className={styles.list} style={{ marginBottom: "-4px" }}>
                  <li>Click the puzzle icon to next to your profile avatar.</li>
                  <li>In the drop-down menu, find PatentAdvisor Extension.</li>
                  <li>Click the pushpin icon so that it turns blue.</li>
                </ol>
              </>
            </Row>
          </>
        )}
      </main>
    </>
  );
};

export default SignedOut;
