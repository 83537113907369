import Text from "components/Text";
import React, { useCallback } from "react";
import styles from "./module.sass";
import Checkbox from "components/Checkbox";
import Highlighter from "react-highlight-words";
import { toPercent, Node as NodeType, split } from "./utils";
import { ArgumentType } from "domain/ptabDecisions";
import cn from "classnames";
import { Gap } from "../utils";
import colors from "style/colors.module.scss";

type Props = {
  items: Array<ArgumentType>;
  onSelectionChange: (id: number, childrenIds: Array<number>) => void;
  highlight?: string;
  placeholder?: React.ReactNode;
  openIds: Array<number>;
  onOpenChange: (id: number) => void;
};

export const Row = ({
  col1,
  col2,
  col3,
  col4,
  delimiter = true,
  color,
  accentCol4,
  variant,
  className,
}: {
  col1: React.ReactNode;
  col2?: String;
  col3?: String;
  col4?: String;
  delimiter?: boolean;
  color?: string;
  accentCol4?: boolean;
  variant?: "" | "bold";
  className?: string;
}) => {
  return (
    <div className={cn(styles.row, className)}>
      <div className={cn(styles.col1, delimiter && styles.colDelimiter)}>
        {col1}
      </div>

      <div className={cn(styles.colN, delimiter && styles.colDelimiter)}>
        {!!col2 && (
          <Text style={{ color }} variant={variant}>
            {col2}
          </Text>
        )}
      </div>

      <div className={cn(styles.colN, delimiter && styles.colDelimiter)}>
        {!!col3 && (
          <Text style={{ color }} variant={variant}>
            {col3}
          </Text>
        )}
      </div>

      <div
        className={cn(
          styles.colN,
          delimiter && styles.colDelimiter,
          accentCol4 && styles.colN$accent
        )}
      >
        {!!col4 && (
          <Text style={{ color }} variant={"bold"}>
            {col4}
          </Text>
        )}
      </div>
    </div>
  );
};

export const TreeHeader = ({ className }: { className?: string }) => {
  const color = colors.grey900;

  return (
    <Row
      color={color}
      col1={
        <Text style={{ color }} variant={"bold"}>
          {"Issues"}
        </Text>
      }
      col2={"Affirmed"}
      col3={"Affirmed in Part"}
      col4={"Reversed"}
      delimiter={false}
      className={cn(styles.header, className)}
      variant={"bold"}
    />
  );
};

const LeafElement = ({
  item,
  onSelectionChange,
  children,
  highlight,
  className,
}: {
  item: ArgumentType;
  children: Array<ArgumentType>;
  onSelectionChange: (id: number, childrenIds: Array<number>) => void;
  highlight?: string;
  className?: string;
}) => {
  const {
    name,
    decision_count,
    affirmed,
    affirmed_in_part,
    reversed,
    id,
    is_issue,
    isChecked,
  } = item;

  const highlightColor = "#fff176";

  const toggle = useCallback(() => {
    onSelectionChange &&
      onSelectionChange(
        id,
        (children ?? []).map((x) => x.id)
      );
  }, [children, onSelectionChange]);

  return (
    <Row
      className={className}
      col1={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            isChecked={isChecked ?? false}
            isIndeterminate={isChecked === undefined}
            onChange={toggle}
            className={styles.checkbox}
          />

          <Gap size={8} />

          <span className={styles.rowLabel}>
            <Highlighter
              searchWords={[highlight ?? ""]}
              autoEscape={true}
              textToHighlight={name}
              highlightStyle={{ backgroundColor: highlightColor }}
            />
            <Text
              color={"lightest"}
              variant={"small"}
              className={styles.decisionsLabel}
            >
              {decision_count === 1
                ? "(1 decision)"
                : `(${decision_count} decisions)`}
            </Text>
          </span>
        </div>
      }
      col2={toPercent(is_issue ? affirmed : undefined)}
      col3={toPercent(is_issue ? affirmed_in_part : undefined)}
      col4={toPercent(is_issue ? reversed : undefined)}
      accentCol4
    />
  );
};

type TreeRowProps = {
  node: NodeType;
  onSelectionChange: (id: number, children: Array<number>) => void;
  isOpen: (id: number) => boolean;
  onOpenChange: (id: number) => void;
  highlight?: string;
};

const HEIGHT = 40;
const PADDING_LEFT = 10;
const LIMB_SIZE = 20;
const NODE_OFFSET = LIMB_SIZE + PADDING_LEFT;

const TreeNode = ({
  node,
  onSelectionChange,
  isOpen,
  onOpenChange,
  highlight,
}: TreeRowProps) => {
  const { parent, children } = node;
  const { tree_lvl } = parent;
  const isLimb = children?.length > 0;
  const isLeaf = !isLimb;
  const parentId = parent.id;

  const isLimbOpen = isOpen(parentId) ?? false;

  return (
    <div
      key={`node:${parentId}`}
      className={cn(
        styles.node,
        tree_lvl === 0 && styles.node$root,
        isLimbOpen && styles.collapsed
      )}
    >
      {isLimb && isLimbOpen && (
        //vertical line
        <div className={styles.verticalLine} />
      )}

      <div className={styles.horizontalLine} />

      <div className={cn(styles.nodeContent)}>
        {isLeaf && (
          <LeafElement
            className={styles.leaf}
            highlight={highlight}
            item={parent}
            onSelectionChange={onSelectionChange}
            children={children}
          />
        )}

        {isLimb && (
          <>
            <div
              className={styles.more}
              onClick={() => {
                onOpenChange && onOpenChange(parentId);
              }}
            >
              {isLimbOpen ? "-" : "+"}
            </div>

            <LeafElement
              highlight={highlight}
              item={parent}
              onSelectionChange={onSelectionChange}
              children={children}
            />
          </>
        )}
      </div>

      {isLimb && (
        <div
          className={cn(styles.parentLine, isLeaf && styles.parentLine$leaf)}
        />
      )}

      {isLimbOpen &&
        isLimb &&
        split(children, tree_lvl + 1).map((node) => (
          <TreeNode
            node={node}
            onSelectionChange={onSelectionChange}
            onOpenChange={onOpenChange}
            isOpen={isOpen}
            highlight={highlight}
          />
        ))}
    </div>
  );
};

export default function Tree({
  onSelectionChange,
  items,
  highlight,
  placeholder,
  openIds,
  onOpenChange,
}: Props) {
  const isOpen = useCallback(
    (id: number): boolean => {
      return openIds.some((x) => x === id);
    },
    [openIds]
  );

  return (
    <div className={styles.root}>
      {items?.length > 0 &&
        split(items, 0).map((node) => (
          <TreeNode
            node={node}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onSelectionChange={onSelectionChange}
            highlight={highlight}
          />
        ))}

      {items.length === 0 && !!placeholder && placeholder}
    </div>
  );
}
