import React, {
  Dispatch,
  HTMLAttributes,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import SkipNavigationLink from "atoms/SkipNavigationLink";
import ComboBox, {
  Option as ComboBoxOption,
  HighlightedLabel,
} from "atoms/ComboBox";
import Text from "atoms/Text";
import SimpleSelect from "molecules/SimpleSelect";
import { Option } from "atoms/SelectOption";
import SimpleInput from "molecules/SimpleInput";
import Button from "components/Button";
import MonthPicker, {
  nullEarliestAllowedSelection,
} from "molecules/MonthPicker";
import { MonthOfYear } from "domain/dates";
import List, { ListItem } from "atoms/List";
import HelpToggle, { Props as HelpToggleProps } from "atoms/HelpToggle";
import { AssigneeWithCitationCount } from "domain/assignees";
import { LicensingReportRequest } from "domain/licensingReports";
import Checkbox from "components/Checkbox";
import colors from "style/colors.module.scss";
import HelpModal from "organisms/HelpModal";
import { flattenOptions } from "atoms/ComboBox";
import { getAnalytics } from "domain/analytics";

import {
  CitationDateRange,
  RejectionTypeFilter,
  SearchHistory,
  getDefaultSearch,
  nullCitationDate,
} from "./search";
import help from "./help.json";

export interface Props {
  navbar: ReactNode;
  assigneeQuery: string;
  assigneeSuggestions: AssigneeWithCitationCount[];
  areAssigneeSuggestionsLoading: boolean;
  shouldReuseLastSearch: boolean;
  onAssigneeQueryChange: (newQuery: string) => void;
  onSubmit: (request: LicensingReportRequest) => void;

  backgroundImageUrl?: string;
}

export default function Setup({
  navbar,
  assigneeQuery,
  assigneeSuggestions,
  areAssigneeSuggestionsLoading,
  shouldReuseLastSearch,
  onAssigneeQueryChange,
  onSubmit,
  backgroundImageUrl = "",
}: Props) {
  const [isHelpOn, setIsHelpOn] = useState(false);

  return (
    <>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <SkipNavigationLink />

        {navbar}

        <main style={{ flex: 1, display: "flex" }}>
          <Form
            assigneeQuery={assigneeQuery}
            assigneeSuggestions={assigneeSuggestions}
            areAssigneeSuggestionsLoading={areAssigneeSuggestionsLoading}
            isHelpOn={isHelpOn}
            shouldReuseLastSearch={shouldReuseLastSearch}
            setIsHelpOn={setIsHelpOn}
            onSubmit={onSubmit}
            onAssigneeQueryChange={onAssigneeQueryChange}
          />
          <HelpText backgroundImageUrl={backgroundImageUrl} />
        </main>
      </div>
      <HelpModal
        {...help}
        isOpen={isHelpOn}
        onDismiss={() => setIsHelpOn(false)}
      />
    </>
  );
}

type LicensingReportRequestRejectionTypeFilter = Pick<
  LicensingReportRequest,
  "shouldInclude102Rejections" | "shouldInclude103Rejections"
>;

const rejectionTypeFilterToLicensingReportRequestFilter: Record<
  RejectionTypeFilter,
  LicensingReportRequestRejectionTypeFilter
> = {
  "102And103": {
    shouldInclude102Rejections: true,
    shouldInclude103Rejections: true,
  },
  "102": {
    shouldInclude102Rejections: true,
    shouldInclude103Rejections: false,
  },
  "103": {
    shouldInclude102Rejections: false,
    shouldInclude103Rejections: true,
  },
};

function getLicensingReportRejectionTypeFilter(
  formFilter: RejectionTypeFilter
): LicensingReportRequestRejectionTypeFilter {
  return rejectionTypeFilterToLicensingReportRequestFilter[formFilter];
}

const citationDateRangeOptions: Option<CitationDateRange>[] = [
  { label: "Last 5 Years", value: "last5Years" },
  { label: "Last 3 Years", value: "last3Years" },
  { label: "Last 1 Year", value: "last1Year" },
  { label: "Custom Date Range", value: "customDateRange" },
];

const rejectionTypeOptions: Option<RejectionTypeFilter>[] = [
  { label: "102 and 103 Rejections", value: "102And103" },
  { label: "102 Rejections", value: "102" },
  { label: "103 Rejections", value: "103" },
];

/**
 * This was originally developed with the presumption that
 * assignees with the same ID won't appear multiple times
 * in assignee suggestions, which was proven not to be the case.
 *
 * We then introduced adding and removing a frontend-only suffix
 * to assignee IDs so that the options appear to be unique for
 * `<Combobox />`. It's not pretty, but it was the quickest
 * option. It should probably be removed when the data is cleaned
 * up so identical assignees don't appear under different parents.
 */
function Form({
  assigneeQuery,
  assigneeSuggestions,
  areAssigneeSuggestionsLoading,
  isHelpOn,
  shouldReuseLastSearch,
  setIsHelpOn,
  onAssigneeQueryChange,
  onSubmit,
}: {
  assigneeQuery: Props["assigneeQuery"];
  assigneeSuggestions: Props["assigneeSuggestions"];
  areAssigneeSuggestionsLoading: Props["areAssigneeSuggestionsLoading"];
  isHelpOn: boolean;
  shouldReuseLastSearch: boolean;
  setIsHelpOn: Dispatch<SetStateAction<boolean>>;
  onAssigneeQueryChange: Props["onAssigneeQueryChange"];
  onSubmit: Props["onSubmit"];
}) {
  const searchHistory = new SearchHistory();
  const lastSearch = searchHistory.getLastSearch();
  const defaults = getDefaultSearch(shouldReuseLastSearch ? lastSearch : null);

  const [assigneeIds, setAssigneeIds] = useState(
    new Set<string>(defaults.assigneeIds)
  );
  const [hasAssigneeError, setHasAssigneeError] = useState(false);
  const [citationDateRange, setCitationDateRange] = useState(
    defaults.citationDateRange
  );
  const [citationDateFrom, setCitationDateFrom] = useState(
    defaults.citationDateFrom
  );
  const [citationDateTo, setCitationDateTo] = useState(defaults.citationDateTo);
  const [rejectionTypeFilter, setRejectionTypeFilter] = useState(
    defaults.rejectionTypeFilter
  );
  const [shouldIncludeAbandonedApps, setShouldIncludeAbandonedApps] = useState(
    defaults.shouldIncludeAbandonedApps
  );
  const [shouldIncludeSelfCitations, setShouldIncludeSelfCitations] = useState(
    defaults.shouldIncludeSelfCitations
  );
  const [reportName, setReportName] = useState(defaults.reportName);

  useEffect(() => {
    if (citationDateRange === "customDateRange") {
      return;
    }
    setCitationDateFrom(nullCitationDate);
    setCitationDateTo(nullCitationDate);
  }, [citationDateRange]);

  const hasSomeAssignees = assigneeIds.size > 0;
  const isCitationDateFromValid =
    citationDateRange !== "customDateRange" ||
    (citationDateRange === "customDateRange" &&
      citationDateFrom.monthOfYear !== null);

  const assigneeOptions = createAssigneeOptions(
    assigneeQuery,
    assigneeSuggestions
  );
  const idToAssigneeOption = indexById(flattenOptions(assigneeOptions));

  const didChangeAssignees =
    defaults.assigneeIds.sort().join(", ") !==
    [...assigneeIds].map(removeFrontendIdSuffix).sort().join(", ");

  const formattedSelectedAssignees = didChangeAssignees
    ? Array.from(assigneeIds)
        .map(removeFrontendIdSuffix)
        .map((id) => idToAssigneeOption[id].metadata.name)
        .join(", ")
    : defaults.formattedCitedAssigneeNames;

  function handleHelpToggle(state: HelpToggleProps["state"]) {
    setIsHelpOn(state === "on" ? true : false);
    getAnalytics().recordEvent({
      pageName: "era_report_page",
      interfaceEvent: "tab_click",
      interfaceValue: "?",
      action: "click",
    });
  }

  function handleSubmit() {
    if (!isCitationDateFromValid) {
      setCitationDateFrom((citationDateFrom) => ({
        ...citationDateFrom,
        hasError: true,
      }));
    }

    if (!hasSomeAssignees) {
      setHasAssigneeError(true);
      return;
    }

    const isValid = isCitationDateFromValid && hasSomeAssignees;
    if (!isValid) {
      return;
    }

    const cleanAssigneeIds = [
      ...new Set(Array.from(assigneeIds).map(removeFrontendIdSuffix)),
    ];

    searchHistory.setLastSearch({
      assigneeIds: cleanAssigneeIds,
      formattedCitedAssigneeNames: formattedSelectedAssignees,
      citationDateRange,
      citationDateFrom,
      citationDateTo,
      rejectionTypeFilter,
      shouldIncludeAbandonedApps,
      shouldIncludeSelfCitations,
      reportName,
    });

    getAnalytics().recordEvent({
      pageName: "era_search_page",
      interfaceEvent: "report_run",
      interfaceValue: "create_report",
      action: "click",
      context: {
        citedAssigneeCount: cleanAssigneeIds.length,
        citationDateRange,
        rejectionTypeFilter,
        includeAbandonedApps: shouldIncludeAbandonedApps,
        includeSelfCitations: shouldIncludeSelfCitations,
        reportName,
      },
    });

    onSubmit({
      assigneeIds: cleanAssigneeIds,
      citationDateFrom: citationDateFrom.monthOfYear
        ? convertMonthOfYearToDate(citationDateFrom.monthOfYear)
        : getPreciseCitationDate(citationDateRange),
      citationDateTo: citationDateTo.monthOfYear
        ? convertMonthOfYearToDate(citationDateTo.monthOfYear)
        : null,
      reportName: reportName || null,
      shouldIncludeAbandonedApps,
      shouldIncludeSelfCitations,
      ...getLicensingReportRejectionTypeFilter(rejectionTypeFilter),
    });
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        maxWidth: 600,
        padding: "32px 30px 0",
        borderRight: `1px solid ${colors.grey350}`,
      }}
    >
      <div style={{ width: "min-content", marginLeft: "auto" }}>
        <HelpToggle
          state={isHelpOn ? "on" : "off"}
          onToggle={handleHelpToggle}
        />
      </div>
      <FormRow>
        <ComboBox
          selectedValues={assigneeIds}
          formattedSelectedValues={formattedSelectedAssignees}
          label="Cited Assignee"
          query={assigneeQuery}
          options={assigneeOptions}
          placeholder="Search for a Cited Assignee"
          error={hasAssigneeError ? "Assignee is required" : ""}
          isLoading={areAssigneeSuggestionsLoading}
          onQueryChange={(query) => onAssigneeQueryChange(query)}
          onSelect={(assigneeIds) => setAssigneeIds(assigneeIds)}
        />
      </FormRow>
      <FormRow style={{ width: 220 }}>
        <SimpleSelect
          label="Citation Date Range"
          value={citationDateRange}
          options={citationDateRangeOptions}
          onChange={(value) => setCitationDateRange(value)}
        />
      </FormRow>
      {citationDateRange === "customDateRange" && (
        <FormRow style={{ display: "flex" }}>
          <MonthPicker
            month={citationDateFrom.monthOfYear}
            label="Citation Date From"
            placeholder="Date From"
            error={citationDateFrom.hasError ? "Dates are required" : ""}
            latestAllowedSelection={
              citationDateTo.monthOfYear
                ? citationDateTo.monthOfYear.subtractMonths(1)
                : MonthOfYear.now()
            }
            onMonthChange={(monthOfYear) =>
              setCitationDateFrom({ monthOfYear, hasError: false })
            }
          />
          <div style={{ paddingLeft: 20 }} />
          <MonthPicker
            month={citationDateTo.monthOfYear}
            label="Citation Date To"
            placeholder="Date To"
            earliestAllowedSelection={
              citationDateFrom.monthOfYear
                ? citationDateFrom.monthOfYear.addMonths(1)
                : nullEarliestAllowedSelection
            }
            latestAllowedSelection={MonthOfYear.now().addMonths(1)}
            onMonthChange={(monthOfYear) =>
              setCitationDateTo({ monthOfYear, hasError: false })
            }
          />
        </FormRow>
      )}
      <FormRow style={{ width: 220 }}>
        <SimpleSelect
          label="Rejection Type"
          value={rejectionTypeFilter}
          options={rejectionTypeOptions}
          onChange={(newFilter) => setRejectionTypeFilter(newFilter)}
        />
      </FormRow>
      <FormRow style={{ marginBottom: 28 }}>
        <Checkbox
          label={<Text>Exclude Abandoned Applications</Text>}
          isChecked={!shouldIncludeAbandonedApps}
          onChange={(shouldExclude) =>
            setShouldIncludeAbandonedApps(!shouldExclude)
          }
        />
        <div style={{ marginBottom: 12 }}></div>
        <Checkbox
          label={<Text>Exclude Self-Citations</Text>}
          isChecked={!shouldIncludeSelfCitations}
          onChange={(shouldExclude) =>
            setShouldIncludeSelfCitations(!shouldExclude)
          }
        />
      </FormRow>
      <FormRow>
        <SimpleInput
          label={
            <>
              Report Name <i>(optional)</i>
            </>
          }
          placeholder="Create a name for your report"
          value={reportName}
          hasMaxWidth={false}
          onChange={(e) => setReportName(e.target.value)}
        />
      </FormRow>
      <FormRow>
        <Button
          variant="brand"
          size="sm"
          isDisabled={!hasSomeAssignees}
          onClick={handleSubmit}
        >
          Create Report
        </Button>
      </FormRow>
    </div>
  );
}

let nextFrontendId = 0;

function getNextFrontendId() {
  nextFrontendId += 1;
  return nextFrontendId - 1;
}

function addFrontendIdSuffix(assigneeId: string, frontendId: number): string {
  return `${assigneeId}#${frontendId}`;
}

function removeFrontendIdSuffix(assigneeId: string): string {
  return assigneeId.split("#")[0];
}

function createAssigneeOptions(
  query: string,
  assigneeSuggestions: AssigneeWithCitationCount[]
) {
  const queryLength = query.length;
  /**
   * If an assignee name starts with the query,
   * it is of higher relevance than other suggestions.
   */
  const getRelevance = (assignee: AssigneeWithCitationCount) =>
    query.localeCompare(assignee.name.substring(0, queryLength), undefined, {
      sensitivity: "base",
    }) === 0
      ? 2
      : 1;

  return Object.values(assigneeSuggestions)
    .sort((a, b) => {
      const queryPositionDiff = getRelevance(b) - getRelevance(a);
      return queryPositionDiff || b.citationCount - a.citationCount;
    })
    .map((assignee) =>
      createAssigneeOption(query, assignee, getNextFrontendId)
    );
}

type AssigneeOptionMetadata = {
  name: string;
  id: string;
};

function createAssigneeOption(
  query: string,
  assignee: AssigneeWithCitationCount,
  getNextFrontendId: () => number
): ComboBoxOption<AssigneeOptionMetadata> {
  return {
    value: addFrontendIdSuffix(assignee.id, getNextFrontendId()),
    label: <AssigneeSuggestionLabel assignee={assignee} query={query} />,
    metadata: {
      name: assignee.name,
      id: assignee.id,
    },
    nestedOptions: (assignee.nestedAssignees || []).map((a) =>
      createAssigneeOption(query, a, getNextFrontendId)
    ),
  };
}

function indexById(flatOptions: ComboBoxOption<AssigneeOptionMetadata>[]) {
  return flatOptions.reduce<
    Record<string, ComboBoxOption<AssigneeOptionMetadata>>
  >((valueToOption, option) => {
    valueToOption[option.metadata.id] = option;
    return valueToOption;
  }, {});
}

function FormRow({ style, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return <div style={{ width: 540, marginBottom: 24, ...style }} {...rest} />;
}

function HelpText({ backgroundImageUrl }: { backgroundImageUrl: string }) {
  return (
    <div
      style={{
        flex: 1,
        padding: "64px 80px",
        background: backgroundImageUrl.length
          ? `url("${backgroundImageUrl}")`
          : "#00172e",
        backgroundSize: "cover",
      }}
    >
      <Text
        as="h1"
        size={36}
        weight="bold"
        color="white"
        style={{ marginBottom: 20 }}
      >
        Examiner Rejection Analysis
      </Text>
      <div style={{ width: 525 }}>
        <Text
          size={18}
          style={{ width: "100%", marginBottom: 24, lineHeight: "30px" }}
          color="white"
        >
          Examiner Rejection Analysis identifies citations to your portfolio
          that examiners have made in office action rejection documents. Search
          over 35 million examiner citations that have been made in more than 13
          million office actions.
        </Text>
        <Text
          as="h2"
          size={23}
          weight="bold"
          color="white"
          style={{ marginBottom: 12 }}
        >
          Examiner Rejection Analysis will help you:
        </Text>
        <List style={{ marginLeft: 14 }}>
          <ListItem style={{ color: "white" }}>
            <Text size={18} color="white" style={{ lineHeight: "30px" }}>
              Identify potential competitors or licensees
            </Text>
          </ListItem>
          <ListItem style={{ color: "white" }}>
            <Text size={18} color="white" style={{ lineHeight: "30px" }}>
              Determine threats and possible infringers
            </Text>
          </ListItem>
          <ListItem style={{ color: "white" }}>
            <Text size={18} color="white" style={{ lineHeight: "30px" }}>
              Pinpoint your valuable and highly cited patents
            </Text>
          </ListItem>
          <ListItem style={{ color: "white" }}>
            <Text size={18} color="white" style={{ lineHeight: "30px" }}>
              Discover opportunities in adjacent markets
            </Text>
          </ListItem>
        </List>
      </div>
    </div>
  );
}

function AssigneeSuggestionLabel({
  query,
  assignee,
}: {
  query: string;
  assignee: AssigneeWithCitationCount;
}) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <HighlightedLabel query={query} label={assignee.name} />
      </div>
      <div>{assignee.citationCount.toLocaleString()}</div>
    </div>
  );
}

function getPreciseCitationDate(range: CitationDateRange) {
  if (range === "customDateRange") {
    throw new Error(
      "Can't get precise citation date for a custom range. Use user-selected values instead."
    );
  }

  const rangeToYearOffset = {
    last5Years: 5,
    last3Years: 3,
    last1Year: 1,
  };
  const yearOffset = rangeToYearOffset[range];
  const today = new Date();

  return new Date(today.getFullYear() - yearOffset, today.getMonth());
}

function convertMonthOfYearToDate(monthOfYear: MonthOfYear) {
  const { year, month } = monthOfYear;
  // create an UTC date
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#return_value
  return new Date(`${year}-${String(month).padStart(2, "0")}-01`);
}
