export function downloadURIEncodedFile(content: string, fileName: string) {
  const link = document.createElement("a");
  link.setAttribute("href", content);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();
  link.remove();
}

export function insertAsyncScript(url: string) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  script.src = url;
  document.body.appendChild(script);
}
