/**
 * We don't build separate bundles for different
 * environments (at least not at the time of creation
 * of this module).
 */

export enum Environment {
  Production = "production",
  QA = "qa",
  Development = "development",
  Unknown = "Unknown",
}

export function getEnvironment(
  hostname = window.location.hostname
): Environment {
  const knownHostnameToEnvironment: Record<string, Environment> = {
    "go.patentadvisor.com": Environment.Production,
    "qa.patentadvisor.com": Environment.QA,
    "dev.patentadvisor.com": Environment.Development,
  };
  return hostname in knownHostnameToEnvironment
    ? knownHostnameToEnvironment[hostname]
    : Environment.Unknown;
}
