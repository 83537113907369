import React from "react";
import ReactTooltip, { Place } from "react-tooltip";
import styles from "./module.sass";

const Tooltip = ({
  message,
  children,
  place = "top",
}: {
  message: string | Array<String>;
  children: React.ReactNode;
  place?: Place;
}) => {
  return (
    <>
      <div
        className={styles.container}
        data-tip={Array.isArray(message) ? message?.join("<br/>") : message}
      >
        {children}
      </div>

      <ReactTooltip
        className={styles.tooltip}
        place={place}
        effect="solid"
        textColor="white"
        backgroundColor="rgb(19, 42, 67)"
        arrowColor="rgb(19, 42, 67)"
        delayShow={100}
        delayHide={125}
        html={Array.isArray(message)}
      />
    </>
  );
};

export default Tooltip;
