import React from "react";
import { Switch, Route } from "react-router-dom";

import BrowserExtensionPage from "pages/BrowserExtensionPage";
import "style/global.sass";

export default function BrowserExtensionApp() {
  return (
    <Switch>
      <Route path="/" component={BrowserExtensionPage}></Route>
    </Switch>
  );
}
