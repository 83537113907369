import React, { forwardRef, ReactNode, TextareaHTMLAttributes } from "react";
import cn from "classnames";

import InputLabel from "atoms/InputLabel";
import InputError from "atoms/InputError";

import styles from "./module.sass";

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;

  error?: string;
  render?: (input: ReactNode) => ReactNode;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, error = "", render = (textArea) => textArea, ...rest }, ref) => {
    const textArea = (
      <textarea ref={ref} className={styles.textArea} {...rest}></textarea>
    );

    return (
      <label
        className={cn(styles.labelGroup, error && styles.labelGroup$error)}
      >
        <InputLabel>{label}</InputLabel>
        {render(textArea)}
        {error.length > 0 && <InputError>{error}</InputError>}
      </label>
    );
  }
);

export default TextArea;
