import React from "react";
import { User } from "domain/users";
import SettingsWithExtension from "./SettingsWithExtension";
import SettingsWithoutExtension from "./SettingsWithoutExtension";

export type Props = {
  hasBrowserExtension: User["hasActiveBrowserPlugin"];
};

export default function BrowserExtensionSettings({
  hasBrowserExtension,
}: Props) {
  return (
    <>
      {hasBrowserExtension ? (
        <SettingsWithExtension computerImage="/assets/img/patentcenter-on-computer.jpg" />
      ) : (
        <SettingsWithoutExtension
          imageWithExtension="/assets/img/patentcenter-with-extension.jpg"
          imageWithoutExtension="/assets/img/patentcenter-without-extension.jpg"
        />
      )}
    </>
  );
}
