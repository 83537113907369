import React, { useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import Highlighter from "react-highlight-words";
import {
  DictionaryItemType,
  getReactSelectDefaultProps,
  RaceConditionGuard,
} from "components/PTABDecisions/components/utils";

import styles from "./SelectWithSuggestions.module.sass";

const SelectWithSuggestions = ({
  placeholder,
  value,
  setValue,
  fetch,
  width,
  inputLengthThreshold = 3,
  className,
  isHighlightingEnabled,
  maxMenuHeight,
}: {
  placeholder?: string;
  value?: DictionaryItemType | null;
  setValue: (judge: DictionaryItemType | null) => void;
  fetch: (value: string) => Promise<Array<DictionaryItemType>>;
  width?: number;
  inputLengthThreshold?: number;
  className?: string;
  isHighlightingEnabled?: boolean;
  maxMenuHeight?: number;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Array<DictionaryItemType>>([]);
  const highlightColor = "#fff176";

  const raceConditionGuard = useMemo(() => {
    return new RaceConditionGuard();
  }, []);

  useEffect(() => {
    if (inputValue?.length >= inputLengthThreshold) {
      setIsLoading(true);

      raceConditionGuard.getGuardedPromise(
        fetch(inputValue)
          .then((res) => {
            setOptions(res);
          })
          .finally(() => {
            setIsLoading(false);
          })
      );
    } else {
      raceConditionGuard.getGuardedPromise(
        new Promise(() => {
          setOptions([]);
          setIsLoading(false);
        })
      );
    }
  }, [inputValue]);

  const highlightOption = (optionLabel: string) => (
    <Highlighter
      highlightStyle={{ backgroundColor: highlightColor, padding: "0 0 1px" }}
      unhighlightStyle={{ paddingBottom: 1 }}
      searchWords={[inputValue]}
      textToHighlight={optionLabel}
    />
  );

  return (
    <div className={styles.smallSelectContainer}>
      <ReactSelect
        {...getReactSelectDefaultProps({
          width: width,
          height: 24,
          hasValue: !!value,
          hasOptions: false,
        })}
        value={value}
        inputValue={inputValue}
        onChange={setValue}
        onInputChange={setInputValue}
        options={options}
        formatOptionLabel={(option) =>
          isHighlightingEnabled ? highlightOption(option.label) : option.label
        }
        placeholder={placeholder}
        isClearable={true}
        isSearchable={true}
        isLoading={isLoading}
        noOptionsMessage={() => "No results"}
        filterOption={() => true}
        maxMenuHeight={maxMenuHeight}
        className={className}
      />
    </div>
  );
};

export default SelectWithSuggestions;
