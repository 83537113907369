import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { deleteSingle, fetchAll } from "domain/monitors";

export default function useMonitors() {
  const queryClient = useQueryClient();
  const { data: monitors = [], status } = useQuery("monitors", fetchAll);
  const deletion = useMutation(deleteSingle, {
    onSuccess: () => {
      queryClient.invalidateQueries("monitors");
    },
  });

  return { monitors, status, mutations: { deletion } };
}
