import { DictionaryItemType } from "components/PTABDecisions/components/utils";

const toDictionaryItem = (
  label: string,
  value: string
): DictionaryItemType => ({
  label,
  value,
});

export enum DecisionType {
  Decision = "D",
  Judgement = "J",
  DecisionOnMotions = "M",
  Rehearing = "R",
  Order = "O",
  SubsequentDecision = "S",
  InstitutionDecision = "I",
  FinalWrittenDecision = "W",
  Petition = "P",
}

export const DecisionTypes = [
  toDictionaryItem("Decision", DecisionType.Decision),
  toDictionaryItem("Judgement", DecisionType.Judgement),
  toDictionaryItem("Decision on Motions", DecisionType.DecisionOnMotions),
  toDictionaryItem("Rehearing", DecisionType.Rehearing),
  toDictionaryItem("Order", DecisionType.Order),
  toDictionaryItem("Subsequent Decision", DecisionType.SubsequentDecision),
  toDictionaryItem("Institution Decision", DecisionType.InstitutionDecision),
  toDictionaryItem("Final Written Decision", DecisionType.FinalWrittenDecision),
  toDictionaryItem("Petition", DecisionType.Petition),
];

export enum DecisionMainType {
  Final = "F",
  Precedential = "P",
  Informative = "I",
}

export const DecisionMainTypes = [
  toDictionaryItem("Final", DecisionMainType.Final),
  toDictionaryItem("Precedential", DecisionMainType.Precedential),
  toDictionaryItem("Informative", DecisionMainType.Informative),
];

export type DecisionDetailsType = {
  decision_id?: number;
  correspondent_name: string;
  application_number: string;
  filing_date: string;
  first_named_inventor: string;
  attorney_docket_number: string;
  confirmation_number: number;
  customer_number: number;
  appeal_number: string;
  publication_date: string;
  publication_number: string;
  reexamination_number: string;
  patent_number: string;
  style_name: string;
  full_title: string;
  decision_date: string;
  title_of_invention: string;
  pclass?: string;
  subclass?: string;
  patentee_counsel: string;
  petitioner: string;
  dissents: boolean;
  remand: boolean;
  reexamination: boolean;
  reissue: boolean;
  oral_hearing: boolean;
  oral_hearing_date: string;
  federal_circuit_name: string;
  covered_business_model: string;
  inter_partes_review: string;
  type?: DecisionDetailsType;
  bigtype?: DecisionMainType;
  notes: string;
  examiner: string;
  group_art_unit: number;
  opinion_judge: string;
  review_status: string;
};

export type ExtTagType = {
  id?: number;
  tag_id: number;
  claimsOfIssueId?: string;
  dispositive: boolean;
  legalSupport?: string;
  name: string;
};

export type TagType = {
  id: number;
  name: string;
};

export type IssueSetTagType = {
  name: string;
  dispositive: boolean;
};

export type IssueSetType = {
  claims_of_issue: string;
  disposition: string;
  non_substantive: boolean;
  tags: Array<IssueSetTagType>;
  issue: string;
  issue_set_id?: number;
};

export enum DailyReviewStatusTypes {
  Pending = "pending",
  Reviewed = "reviewed",
  Failed = "failed",
}

export type DailyReviewType = {
  decision_id: string;
  status: DailyReviewStatusTypes;
  import_date: string;
  type: DecisionType;
  bigtype?: DecisionMainType;
  application_number: string;
  case_name: string;
  decision_date?: string;
  no_issues: boolean;
  unknown_outcome: boolean;
  errors?: any;
};

export enum MonthlyReviewStatusTypes {
  NoDecisions = "No Decisions",
  ReviewRequired = "Review Required",
  ReviewInProgress = "Review In Progress",
  Complete = "Complete",
  AutoConversionFailed = "Auto-Conversion Failed",
}

export type MonthlyReviewType = {
  import_date: string;
  decision_date: string;
  status: MonthlyReviewStatusTypes;
  count?: number;
  failed?: number;
  unknown_outcome?: number;
  no_issues?: number;
};

export type RecapType = {
  claims_of_issue?: string;
  disposition: string;
  issue: string;
  issue_tags?: Array<string>;
};

export type ApplicationLookupType = {
  id: number;
  case_name: string;
  recap?: Array<RecapType>;
  application_number: string;
  decision_date: string;
  appeal_number: string;
  type: DecisionType;
  bigtype: DecisionMainType;
  art_unit: number;
};
