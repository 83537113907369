export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export class MonthOfYear {
  readonly year: number;
  readonly month: Month;

  constructor(year: number, month: Month) {
    this.year = year;
    this.month = month;
  }

  static now() {
    const now = new Date();
    return new MonthOfYear(now.getFullYear(), (now.getMonth() + 1) as Month);
  }

  isAfter(second: MonthOfYear) {
    return (
      this.year > second.year ||
      (this.year >= second.year && this.month > second.month)
    );
  }

  isBefore(second: MonthOfYear) {
    return (
      this.year < second.year ||
      (this.year <= second.year && this.month < second.month)
    );
  }

  addMonths(monthsToAdd: number) {
    if (monthsToAdd < 0) {
      throw new Error("`monthsToAdd` can't be negative.");
    }
    const unconstrainedNewMonth = this.month + monthsToAdd;
    const constrainedNewMonth = (unconstrainedNewMonth % 12) as Month;
    const yearChange = Math.floor(unconstrainedNewMonth / 12);
    return new MonthOfYear(this.year + yearChange, constrainedNewMonth);
  }

  subtractMonths(monthsToSubtract: number): MonthOfYear {
    if (monthsToSubtract < 0) {
      throw new Error("`monthsToAdd` can't be negative.");
    }

    if (monthsToSubtract === this.month) {
      return new MonthOfYear(this.year - 1, 12);
    }
    if (monthsToSubtract > this.month) {
      const yearChange = Math.floor(-monthsToSubtract / 12);
      const monthChange = monthsToSubtract % 12;
      return new MonthOfYear(this.year + yearChange, this.month).subtractMonths(
        monthChange
      );
    }
    return new MonthOfYear(this.year, (this.month - monthsToSubtract) as Month);
  }

  equals(other: MonthOfYear): boolean {
    return this.year === other.year && this.month === other.month;
  }
}

export const isFuture = (date: Date): boolean => {
  return date > new Date();
};

export const getStartOfMonth = (date: Date): Date => {
  const clone = new Date(date);
  clone.setDate(1);
  clone.setHours(0);
  clone.setMinutes(0);
  clone.setSeconds(0);
  clone.setMilliseconds(0);
  return clone;
};

export const getStartOfLastMonth = (date: Date): Date => {
  const clone = new Date(date);
  clone.setDate(0);
  return getStartOfMonth(clone);
};

export const getStartOfNextMonth = (date: Date): Date => {
  const clone = new Date(date);
  clone.setDate(1);
  clone.setHours(0);
  clone.setMinutes(0);
  clone.setSeconds(0);
  clone.setMilliseconds(0);
  return incrementMonth(clone);
};

export const incrementMonth = (date: Date): Date => {
  const clone = new Date(date);
  clone.setMonth(clone.getMonth() + 1);
  return clone;
};

export const getLastSunday = (date: Date): Date => {
  const clone = new Date(date);
  clone.setDate(clone.getDate() - clone.getDay());
  return clone;
};

export const incrementDays = (date: Date, dayCount: number): Date => {
  const clone = new Date(date);
  clone.setDate(clone.getDate() + dayCount);
  return clone;
};

/**
 * @param dateFrom Included.
 * @param dateTo Not included.
 */
export const getRange = (dateFrom: Date, dateTo: Date): Date[] => {
  const result = [];
  const iterator = new Date(dateFrom);

  while (iterator < dateTo) {
    result.push(new Date(iterator));
    iterator.setDate(iterator.getDate() + 1);
  }

  return result;
};

export const getDaysInMonth = (date: Date): number => {
  const clone = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return clone.getDate();
};

export type Format =
  | "Y-m-d H:M:S"
  | "Y-m-d H:M"
  | "d/m/Y"
  | "Y-m-d"
  | "mm/dd/yyyy";

export const format = (date: Date, format: Format = "Y-m-d H:M:S") => {
  switch (format) {
    case "d/m/Y":
      return date.toLocaleDateString("en-US");

    case "Y-m-d":
      return date.toISOString().split("T")[0];

    case "mm/dd/yyyy":
      const pMonth = `${date.getMonth() + 1}`.padStart(2, "0");
      const pDate = `${date.getDate()}`.padStart(2, "0");
      return `${pMonth}/${pDate}/${date.getFullYear()}`;

    case "Y-m-d H:M:S":
      const hoursAndMinutes = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }).format(date);
      return `${date.toISOString().split("T")[0]} ${hoursAndMinutes}`;

    case "Y-m-d H:M":
      const time = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(date);
      return `${date.toISOString().split("T")[0]} ${time}`;
  }
};
