import React from "react";
import { useQuery } from "react-query";

import {
  getCurrentBrowser,
  getBrowserExtensionUrl,
  getSupportDocumentsUrl,
  getBrowserExtensionSettings,
} from "../domain/browserExtension";

function useBrowserExtensionSettings() {
  const { data: browserExtensionUrl } = useQuery(
    "browserExtensionUrl",
    getBrowserExtensionUrl
  );

  const { data: supportDocumentsUrl } = useQuery(
    "supportDocumentsUrl",
    getSupportDocumentsUrl
  );

  const { data: currentBrowser } = useQuery(
    "currentBrowser",
    getCurrentBrowser
  );

  const { data: browserExtensionSettings } = useQuery(
    "browserExtensionFormData",
    getBrowserExtensionSettings
  );

  return {
    browserExtensionUrl,
    currentBrowser,
    browserExtensionSettings,
    supportDocumentsUrl,
  };
}

export default useBrowserExtensionSettings;
