import React, { CSSProperties, ReactNode } from "react";
import cn from "classnames";

import styles from "./Container.module.sass";

export type Props = {
  children: ReactNode;

  component?: any;
  className?: string;
  style?: CSSProperties;
  isPadded?: boolean;
};

export default function Container({
  children,
  component = undefined,
  className = "",
  style = {},
  isPadded = false,
}: Props) {
  const DynamicComponent = component ?? "div";
  return (
    <DynamicComponent
      className={cn(
        styles.container,
        isPadded && styles.container$padded,
        className
      )}
      style={style}
    >
      {children}
    </DynamicComponent>
  );
}
