import React, { ReactNode } from "react";
import cn from "classnames";

import Tag from "atoms/Tag";

import styles from "./BusinessDevelopment.module.sass";
import { recordTabClick } from "./analytics";
import { SectionHeading } from ".";

export default function BusinessDevelopment({
  hasSmartReports,
  hasLicensingReports,
}: {
  hasSmartReports: boolean;
  hasLicensingReports: boolean;
}) {
  if (!hasSmartReports && !hasLicensingReports) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.businessDevelopment,
        styles.businessDevelopment$active
      )}
    >
      <div id="businessDevelopment" className={styles.content}>
        <SectionHeading>Business Development & Analysis</SectionHeading>

        {hasSmartReports && (
          <>
            <LawFirmReportCardLink />
            <Description>Evaluate your law firm's performance</Description>
            <CompareLawFirmsLink />
            <Description>Compare multiple law firms</Description>
            <TechSpaceBenchmarkingLink />
            <Description>
              Evaluate companies or law firms in specific technologies
            </Description>
          </>
        )}

        {hasLicensingReports && (
          <>
            <ExaminerRejectionAnalysisLink />
            <Description>
              Identify targets for possible licensing deals
            </Description>
          </>
        )}
      </div>
    </div>
  );
}

const Link = ({
  href,
  children,
  onBeforeNavigate,
}: {
  href: string;
  children: ReactNode;
  onBeforeNavigate: () => void;
}) => (
  <a className={styles.link} href={href} onClick={onBeforeNavigate}>
    {children}
  </a>
);

const Description = ({ children }: { children: ReactNode }) => (
  <div className={styles.description}>{children}</div>
);

const LawFirmReportCardLink = () => (
  <Link
    href="/smart-reports/law-firm-performance.php"
    onBeforeNavigate={() => {
      recordTabClick("law_firm_report_card");
    }}
  >
    Law Firm Report Card
  </Link>
);

const CompareLawFirmsLink = () => (
  <Link
    href="/smart-reports/benchmarking-report.php?type=outside"
    onBeforeNavigate={() => {
      recordTabClick("outside_counsel_evaluation");
    }}
  >
    Outside Counsel Evaluation
  </Link>
);

const TechSpaceBenchmarkingLink = () => (
  <Link
    href="/smart-reports/benchmarking-report.php?type=tech-space"
    onBeforeNavigate={() => {
      recordTabClick("tech_space_benchmarking");
    }}
  >
    Tech Space Benchmarking
  </Link>
);

const ExaminerRejectionAnalysisLink = () => (
  <Link
    href="/app.php#licensing-reports/setup"
    onBeforeNavigate={() => {
      recordTabClick("examiner_rejection_analysis");
    }}
  >
    Examiner Rejection Analysis
  </Link>
);
