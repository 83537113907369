import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";
import cn from "classnames";
import styles from "./module.sass";

export type Props = {
  children?: ReactNode;
  isDismissable?: boolean;
  variant?: "info" | "warning" | "error" | "success";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
};

const Notification = ({
  children,
  isDismissable = true,
  variant = "info",
  onClick = () => {},
  style = {},
}: Props) => {
  return (
    <div
      className={cn(
        styles.message,
        styles[`message$${variant}`],
        isDismissable && styles.dismissable
      )}
      style={style}
    >
      <div className={styles.messageText}>{children}</div>
      {isDismissable ? (
        <button
          type="button"
          className={styles.dismiss}
          onClick={onClick}
          tabIndex={0}
        >
          Dismiss
        </button>
      ) : null}
    </div>
  );
};

export default Notification;
