import React from "react";
import { browserName } from "react-device-detect";
import { useQuery } from "react-query";

import { fetchUser } from "domain/users";
import Navbar from "components/Navbar";
import { Row, Divider } from ".";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import SkipNavigationLink from "atoms/SkipNavigationLink";

import styles from "./module.sass";

const SignedIn = () => {
  const isChrome = browserName == "Chrome" ? true : false;

  const { data: user } = useQuery("user", fetchUser);

  return (
    <>
      <SkipNavigationLink />
      {user && <Navbar user={user} />}
      <main className={styles.thankYouContainer}>
        <h1 className={styles.title} style={{ padding: "2px 0 50px" }}>
          Thanks for adding the PatentAdvisor Extension!
        </h1>
        {isChrome && (
          <>
            <Row
              subtitleStyle={{ margin: "-7px 0 20px" }}
              subtitle="Pin the extension"
              image="/assets/img/thankyou/image-pin-extension.png"
            >
              <>
                <p style={{ margin: 0, lineHeight: "21px" }}>
                  To make accessing the extension easier, please pin it by
                  following these steps:
                </p>
                <ol className={styles.list}>
                  <li>Click the puzzle icon to next to your profile avatar.</li>
                  <li>In the drop-down menu, find PatentAdvisor Extension.</li>
                  <li>Click the pushpin icon so that it turns blue.</li>
                </ol>
              </>
            </Row>
            <Divider style={"45px 0 35px"} />
          </>
        )}

        <Row
          subtitleStyle={{ margin: "30px 0 20px" }}
          subtitle="See the extension in action"
          image="/assets/img/thankyou/image-pair-laptop.png"
        >
          <>
            Check out PatentAdvisor data within one of these sites:
            <div
              style={{
                marginTop: 14,
                display: "flex",
                gridGap: 10,
              }}
            >
              <Anchor
                href="https://patentcenter.uspto.gov/#!/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button variant={"contained"} size={"sm"}>
                  Patent Center
                </Button>
              </Anchor>
              <Anchor
                href="https://ppair-my.uspto.gov/pair/PrivatePair"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button variant={"contained"} size={"sm"}>
                  Private PAIR
                </Button>
              </Anchor>
            </div>
          </>
        </Row>
        <Divider style={"29px 0 34px"} />
        <Row
          subtitleStyle={{ margin: "-8px 0 20px" }}
          subtitle="Access your extension settings"
          image="/assets/img/thankyou/image-access-browser-extension.png"
        >
          <p style={{ margin: "0 0 18px", lineHeight: "21px" }}>
            You can always change your extension settings from the Browser
            Extension link in the PatentAdvisor header.
          </p>
        </Row>
      </main>
    </>
  );
};

export default SignedIn;
