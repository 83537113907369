import React from "react";
import styles from "./module.sass";

export default function RELXLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125.3"
      height="33"
      viewBox="0 0 188 49.5"
      className={styles.logo}
    >
      <path
        className={styles.graphic}
        d="M31.4 23c8.3 0 18.2-1.8 18.2-9.5 0-5.4-5.8-7.4-11-7.4-8 0-22.8 4.3-22.8 19.6 0 7.7 6 12.1 15.6 12.1 10.1 0 17.5-6.3 19.8-14.9-5.9 9.9-13.5 13-19.7 13-7.7 0-10.6-5.5-10.6-10.2 0-13.5 10.9-18 17.7-18 4.6 0 7.3 2.8 7.3 6 0 8.7-12.9 8.4-18.8 8.4h-2.8L23 23.4c2.7.3 6.4 1.1 10.5 3.1 8.5 4 16.5 14.9 27 14.9 3.8 0 4.8-1.1 5.5-2.1-5.2 3.2-13.8-2.3-18.4-6.6-3.9-3.7-7.2-7.6-16.2-9.7"
      />
      <path
        fill="#646569"
        d="M83 37.6c-.3 0-.5-.1-.6-.4l-5.2-10.5h-4.7c-.2 0-.3.1-.3.3v10.3c0 .3-.2.4-.4.4H68c-.3 0-.4-.2-.4-.4V11c0-.3.2-.4.4-.4h10.4c5 0 8.7 3.3 8.7 8.1 0 3.5-2 6.3-5.2 7.4l5.7 11c.2.3 0 .5-.3.5H83zm-.5-18.9c0-2.3-1.7-3.8-4.2-3.8h-5.8c-.2 0-.3.1-.3.3v7.2c0 .2.1.3.3.3h5.8c2.5-.2 4.2-1.8 4.2-4M91.9 11c0-.3.2-.4.4-.4h16.4c.3 0 .4.2.4.4v3.4c0 .3-.2.4-.4.4H96.8c-.2 0-.3.1-.3.3v6.6c0 .2.1.3.3.3h9.6c.3 0 .4.2.4.4v3.4c0 .3-.2.4-.4.4h-9.6c-.2 0-.3.1-.3.3v6.8c0 .2.1.3.3.3h11.9c.3 0 .4.2.4.4v3.4c0 .3-.2.4-.4.4H92.3c-.3 0-.4-.2-.4-.4V11zM113.5 11c0-.3.2-.4.4-.4h3.9c.3 0 .4.2.4.4v22.2c0 .2.1.3.3.3h11.1c.3 0 .4.2.4.4v3.4c0 .3-.2.4-.4.4H114c-.3 0-.4-.2-.4-.4l-.1-26.3zM149.4 37.7c-.3 0-.5-.1-.7-.4l-5.4-9.2h-.1l-5.5 9.2c-.2.3-.3.4-.7.4h-4.3c-.3 0-.4-.3-.3-.5l8.1-13.5-7.5-12.6c-.2-.3 0-.5.3-.5h4.3c.3 0 .5.1.7.4l4.9 8.2h.1l4.9-8.2c.2-.3.3-.4.7-.4h4.3c.3 0 .4.3.3.5L146 23.7l8.1 13.5c.2.3 0 .5-.3.5h-4.4z"
      />
      <path
        fill="#696d6e"
        d="M163.1 10.7v1.1H161v5.5h-1.3v-5.5h-2.1v-1.1h5.5m7.1 6.6l-.3-3.8c0-.5 0-1.1-.1-1.8-.2.6-.3 1.3-.6 1.9l-1.2 3.7h-1.3l-1.2-3.8c-.1-.5-.3-1.3-.5-1.8h-.1c0 .6 0 1.3-.1 1.8l-.3 3.8h-1.3l.5-6.6h2l1.1 3.3c.2.5.3 1 .4 1.7h.1c.2-.6.3-1.2.4-1.6l1.2-3.3h1.9l.5 6.6-1.1-.1z"
      />
    </svg>
  );
}
