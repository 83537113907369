import React, { HTMLAttributes, ReactNode, TdHTMLAttributes } from "react";
import {
  Cell,
  Column,
  ColumnInstance,
  Row,
  TableCellProps,
  TableHeaderProps,
  TableInstance,
} from "react-table";
import cn from "classnames";

import { UpDown, SmallChevron } from "components/Icons";
import Pagination from "components/Pagination";
import colors from "style/colors.module.scss";

import styles from "./Table.module.sass";

export type Props<T extends object> = {
  table: TableInstance<T>;

  colorScheme?: "striped" | "white" | "gray";
  emptyMessage?: ReactNode | null;

  tableClassName?: string;
  tableHeaderRowClassName?: string;

  isPaginationHidden?: boolean;

  onPageChange?: (newIndex: number) => void;
  renderRowSubcomponent?: (row: Row<T>) => ReactNode;
  renderPagination?: (pagination: ReactNode) => ReactNode;
};

export type ExtendedColumn<T extends object> = Column<T> & {
  additionalHeaderProps?: Partial<TableHeaderProps>;
  addCellProps?: (cell: Cell<T>) => Partial<TableCellProps>;
};

export type ExtendedColumnInstance<T extends object> = ColumnInstance<T> & {
  additionalHeaderProps?: Partial<TableHeaderProps>;
  addCellProps?: (cell: Cell<T>) => Partial<TableCellProps>;
};

/**
 * @todo
 * 1. replace `tableClassName` with `addTableProps()`
 * 2. replace `tableHeaderRowClassName` with `addHeaderGroupProps()`
 */
export default function Table<T extends object>({
  table,

  colorScheme = "striped",
  emptyMessage = null,
  tableClassName,
  tableHeaderRowClassName,

  isPaginationHidden = false,

  onPageChange = () => {},
  renderRowSubcomponent = () => null,
  renderPagination = (pagination) => (
    <div style={{ paddingTop: 28 }}>{pagination}</div>
  ),
}: Props<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,

    // pagination
    page,
    gotoPage,
    setPageSize,

    // state
    state: { pageIndex, pageSize },
  } = table;

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table
          {...getTableProps()}
          className={cn(
            styles.table,
            styles[`table$${colorScheme}`],
            !rows.length && styles.table$empty,
            tableClassName
          )}
        >
          <thead className={styles.head}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={tableHeaderRowClassName}
              >
                {headerGroup.headers.map((column, i) => {
                  const additionalHeaderProps =
                    (column as any).additionalHeaderProps ?? {};

                  return (
                    <th
                      {...column.getHeaderProps()}
                      {...additionalHeaderProps}
                      className={cn(styles.th, additionalHeaderProps.className)}
                    >
                      <div className={styles.thContent}>
                        <span
                          {...column.getSortByToggleProps?.({ title: "" })}
                          className={cn(
                            column.canSort && styles.sortableColumnHeaderText
                          )}
                        >
                          {column.render("Header")}
                        </span>
                        <span>
                          {column.canSort && column.isSorted && (
                            <span className={styles.upDownWrapper}>
                              <UpDown
                                variant={column.isSortedDesc ? "down" : "up"}
                              />
                            </span>
                          )}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!rows.length ? <>{emptyMessage}</> : null}

            {page.map((row, currentRowIndex) => {
              prepareRow(row);

              // exclude `role` from row props as it can't be
              // passed to React.Fragment
              // @see https://github.com/TanStack/table/issues/1951
              const { role, ...rowProps } = row.getRowProps();

              return (
                <React.Fragment {...rowProps}>
                  <tr className={styles.tr}>
                    {row.cells.map((cell) => {
                      const additionalCellProps =
                        (
                          cell.column as ExtendedColumnInstance<T>
                        ).addCellProps?.(cell) ?? {};

                      return (
                        <td
                          {...cell.getCellProps()}
                          {...additionalCellProps}
                          className={cn(
                            styles.td,
                            additionalCellProps.className
                          )}
                        >
                          {cell.isAggregated
                            ? cell.render("Aggregated")
                            : cell.render("Cell", { currentRowIndex })}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubcomponent(row)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {!isPaginationHidden &&
        rows.length > pageSize &&
        renderPagination(
          <Pagination
            pageSize={pageSize}
            onChangePageSize={(newPageSize: number, newPageIndex: number) => {
              setPageSize(newPageSize);
              gotoPage(newPageIndex);
              onPageChange(newPageIndex);
            }}
            pageIndex={pageIndex}
            itemCount={rows.length}
            onChange={(newPage) => {
              gotoPage(newPage);
              onPageChange(newPage);
            }}
          />
        )}
    </>
  );
}

/**
 * @example
 * <Table
 *   table={table}
 *   emptyMessage={
 *     <EmptyMessage colspan={7}>
 *       No items
 *     </EmptyMessage>
 *   }
 * />
 */
export const EmptyMessage = ({
  colSpan,
  children,
  style,
  className,
  ...rest
}: TdHTMLAttributes<HTMLTableCellElement>) => (
  <tr>
    <td
      colSpan={colSpan}
      align="center"
      className={cn(styles.emptyMessage, className)}
      style={style}
      {...rest}
    >
      {children}
    </td>
  </tr>
);

export interface ExpanderProps {
  isExpanded: boolean;
}

/**
 * @example
 * // Example of usage in a column definition:
   {
      id: "expander",
      addCellProps: addExpanderCellProps,
      Cell: ({ row }: CellProps<Data>) => (
        <Expander
          isExpanded={row.isExpanded}
          {...row.getToggleRowExpandedProps()}
        />
      ),
    },
 */
export const Expander = ({ isExpanded }: ExpanderProps) => (
  <SmallChevron
    width={12}
    height={7}
    direction={isExpanded ? "down" : "right"}
    fill={colors.blue900}
    style={{ marginBottom: 2 }}
  />
);

export function addExpanderCellProps<T extends object>(
  cell: Cell<T>
): Partial<TableCellProps> & { title: undefined } {
  const { row } = cell;

  return {
    ...row.getToggleRowExpandedProps(),
    title: undefined,
    className: styles.expander,
    style: {
      width: 1,
      paddingLeft: 12,
      paddingRight: 12,
      boxSizing: "border-box",
      borderRight: `1px solid ${colors.blueGray400}`,
      backgroundColor: "var(--expander-background-color)",
      cursor: "pointer",
    },
  };
}

/**
 * We center the header text of columns with actions,
 * that is, buttons.
 *
 * @example
 * // Part of column definitions:
   {
     id: "actions",
     accessor: "name",
     disableSortBy: true,
     additionalHeaderProps: actionsHeaderAdditionalProps,
     Header: "Actions",
     // ...
    }
 */
export const actionsHeaderAdditionalProps = {
  style: { display: "flex", justifyContent: "center" },
};

/**
 * To be used around tables which are contained in rows
 * of other tables.
 *
 * It indents the contained table to the right and collapses
 * it's borders into the borders of the containing row.
 */
export function SubcomponentTableContainer({
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        paddingLeft: 35,
        marginTop: -2,
        marginBottom: -2,
        marginRight: -2,
        ...style,
      }}
      {...rest}
    ></div>
  );
}
