import Link from "components/Link";
import { Gap } from "components/PTABDecisions/components/utils";
import React, { HTMLAttributes, ReactNode } from "react";
import styles from "./module.sass";
import cn from "classnames";
import Pulse from "atoms/Pulse";
import Toast, { useToastContext } from "components/Toast";
import Text from "atoms/Text";
import colors from "style/colors.module.scss";

export const Pill = ({
  color,
  children,
  style,
  ...rest
}: {
  color: "yellow" | "green";
} & HTMLAttributes<HTMLSpanElement>) => (
  <Text
    {...rest}
    as="span"
    size={14}
    style={{
      ...style,
      padding: "2px 10px",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: color === "yellow" ? colors.yellow800 : colors.green400,
    }}
  >
    {children}
  </Text>
);

const Title = ({
  children,
  header,
  className,
}: {
  children?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
}) => {
  return !!children ? (
    <div className={cn(styles.title, className)}>
      {header && header}
      <p className={styles.titleText}>{children}</p>
    </div>
  ) : null;
};

export { Title };

const Layout = ({
  title,
  children,
  header,
  className,
  isMonthlyReviewActive,
  isApplicationLookupActive,
  isLoading,
  isWide,
}: {
  title?: string;
  children: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  isMonthlyReviewActive?: boolean;
  isApplicationLookupActive?: boolean;
  isLoading?: boolean;
  isWide?: boolean;
}) => {
  const toastContext = useToastContext();

  return (
    <div className={styles.root}>
      <div className={cn(styles.container, isWide && styles.container$wide)}>
        <span className={styles.navBar}>
          <Link
            href={"/#monthly-review"}
            className={cn(
              styles.link,
              isMonthlyReviewActive && styles.link$active
            )}
          >
            MONTHLY REVIEW
          </Link>
          <Gap size={24} />
          <Link
            href={"/#application-lookup"}
            className={cn(
              styles.link,
              isApplicationLookupActive && styles.link$active
            )}
          >
            APPLICATION LOOKUP
          </Link>
          <Gap />
        </span>

        <Title header={header}>{title}</Title>

        <div className={cn(styles.content, className)}>{children}</div>

        {!!toastContext?.toast && <Toast {...toastContext.toast} />}
      </div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Pulse />
        </div>
      )}
    </div>
  );
};

export default Layout;
