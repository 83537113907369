import React, { FormEventHandler, useState } from "react";

import Input from "components/Input";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { getAnalytics } from "domain/analytics";

import { TabPanel, TabPanelItem } from ".";

export default function AssigneeOrApplicantForm() {
  const [name, setName] = useState("");
  const [isHistoricalDataIncluded, setIsHistoricalDataIncluded] =
    useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    recordFormSubmission(name);
    window.location.href = `/assignee-tree-search.php?o=${name}&h=${isHistoricalDataIncluded}`;
  };

  return (
    <TabPanel>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <TabPanelItem>
          <Input
            label="Assignee"
            value={name}
            onChange={(value) => setName(value.trimLeft())}
          />
        </TabPanelItem>
        <TabPanelItem>
          <div style={{ width: "100%", marginTop: -18 }}>
            <Checkbox
              label="Search Historical Data"
              isChecked={isHistoricalDataIncluded}
              onChange={(isChecked) => setIsHistoricalDataIncluded(isChecked)}
            />
          </div>
        </TabPanelItem>
        <TabPanelItem>
          <Button
            id="homepageAssigneeBtnSubmit"
            leftIcon="magnifier"
            type="submit"
            variant="brand"
            isDisabled={!name}
          >
            Search
          </Button>
        </TabPanelItem>
      </form>
    </TabPanel>
  );
}

function recordFormSubmission(query: string) {
  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_assignees",
    interfaceValue: "search",
    action: "click",
    context: { query },
  });
}
