import axios from "axios";

import {
  Event,
  AppealEvent,
  Application,
  DetaillessEvent,
  OfficeActionEvent,
} from ".";

export interface APIDetaillessEvent extends Omit<DetaillessEvent, "date"> {
  date: string;
}

export interface APIAppealEvent extends Omit<AppealEvent, "date"> {
  date: string;
}

export interface APIOfficeActionEvent
  extends Omit<OfficeActionEvent, "rejectionDate" | "responseDate"> {
  rejectionDate: string;
  responseDate: string | null;
}

export type APIEvent =
  | APIDetaillessEvent
  | APIAppealEvent
  | APIOfficeActionEvent;

export interface APIApplication extends Omit<Application, "events"> {
  events: APIEvent[];
}

export function fetchApplicationByNumber(
  applicationNumber: number
): Promise<Application> {
  return axios
    .get<APIApplication>("/api/v2/timeline.php", {
      params: { applicationNumber },
    })
    .then(({ data: apiApplication }) => ({
      ...apiApplication,
      events: apiApplication.events.map(mapAPIToDomainEvent),
    }));
}

export function fetchApplicationById(id: number): Promise<Application> {
  return axios
    .get<APIApplication>("/api/v2/timeline.php", {
      params: { applicationId: id },
    })
    .then(({ data: apiApplication }) => ({
      ...apiApplication,
      events: apiApplication.events.map(mapAPIToDomainEvent),
    }));
}

function mapAPIToDomainEvent(apiEvent: APIEvent): Event {
  const { name } = apiEvent;

  if (name === "appeal") {
    return {
      ...apiEvent,
      date: new Date((apiEvent as APIAppealEvent).date),
    } as AppealEvent;
  }
  if (name === "officeAction") {
    const { rejectionDate, responseDate } = apiEvent as APIOfficeActionEvent;
    return {
      ...apiEvent,
      rejectionDate: new Date(rejectionDate),
      responseDate: responseDate ? new Date(responseDate) : null,
    } as OfficeActionEvent;
  }
  return {
    ...apiEvent,
    date: new Date((apiEvent as APIDetaillessEvent).date),
  } as Event;
}
