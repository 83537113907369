import { getAnalytics } from "domain/analytics";

export function recordMenuItemClick(menuItemLabel: string) {
  const formattedLabel = menuItemLabel
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .replace(/\s/g, "_")
    .toLowerCase();
  getAnalytics().recordEvent({
    pageName: "navigation_bar",
    interfaceEvent: "menu_item_click",
    interfaceValue: formattedLabel,
    action: "click",
  });
}
