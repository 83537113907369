import React from "react";

export default function Base() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 299.8 70.5"
      width={176.5}
      height={41}
    >
      <path d="M94.6 19.2h-4v31.5h20v-3.9h-16zM263.5 30.5l-2.7-2.3-7.6 8.8-7.6-8.7-.1-.1-2.7 2.3 8 9.2-7.9 9.1-.1.1 2.7 2.3 7.7-8.8 7.6 8.8 2.7-2.3-8-9.2 7.9-9.1zM211.1 41.9l-21.5-22.7h-.8v31.5h4V28.1l21.5 22.6h.8V19.2h-4zM267.5 28.7h3.8v22h-3.8zM267.3 19.2h4.1v3.5h-4.1zM230.9 28.4c-6.2 0-11.1 5-11.1 11.4 0 6.3 4.8 11.4 11.3 11.4 3.8 0 7-1.6 8.9-3.6l-2.7-2.3c-1.4 1.3-3.5 2.4-6.1 2.4-4 0-7-3-7.4-6.9h17.9c-.1-8-4.6-12.4-10.8-12.4m-7 8.9c.8-3.3 3.5-5.5 6.7-5.5 3.4 0 6.2 1.9 6.7 5.5h-13.4zM285.3 38.2l-1.9-.7c-2.7-.9-3.3-2-3.3-3.3 0-1.5 1.5-2.4 3.3-2.4 1.6 0 2.7.7 3.7 1.7l2.6-2.2c-1.6-1.9-3.8-2.9-6.3-2.9-4.2 0-7.1 2.6-7.1 5.9 0 2.6 1.4 5.1 5.5 6.5l1.8.7c2.7.9 3.9 1.8 3.9 3.5s-1.8 2.8-4.1 2.8c-2.4 0-4-1.1-5.4-2.8l-2.7 2.4c2 2.4 4.8 3.8 8 3.8 5 0 8.1-2.8 8.1-6.3-.2-3.4-2.2-5.4-6.1-6.7M155.8 30.5l-2.7-2.3-7.6 8.8-7.6-8.7-.1-.1-2.7 2.3 8 9.2-7.9 9.1-.1.1 2.7 2.3 7.7-8.8 7.6 8.8 2.7-2.3-8-9.2 8-9.1zM159.8 28.7h3.8v22h-3.8zM159.7 19.2h4.1v3.5h-4.1zM123.2 28.4c-6.2 0-11.1 5-11.1 11.4 0 6.3 4.8 11.4 11.3 11.4 3.8 0 7-1.6 8.9-3.6l-2.7-2.3c-1.4 1.3-3.5 2.4-6.1 2.4-4 0-7.1-3-7.4-6.9H134c-.1-8-4.6-12.4-10.8-12.4m-7 8.9c.8-3.3 3.5-5.5 6.7-5.5 3.3 0 6.2 1.9 6.7 5.5h-13.4zM177.6 38.2l-1.9-.7c-2.7-.9-3.3-2-3.3-3.3 0-1.5 1.5-2.4 3.3-2.4 1.6 0 2.7.7 3.7 1.7l2.6-2.2c-1.6-1.9-3.7-2.9-6.3-2.9-4.2 0-7.1 2.6-7.1 5.9 0 2.6 1.4 5.1 5.4 6.5l1.8.7c2.7.9 3.9 1.8 3.9 3.5s-1.8 2.8-4.1 2.8c-2.4 0-4-1.1-5.4-2.8l-2.7 2.4c2 2.4 4.8 3.8 8 3.8 5 0 8.1-2.8 8.1-6.3-.1-3.4-2.1-5.4-6-6.7M294.4 22.8c-1.6 0-2.9 1.3-2.9 2.9 0 1.7 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9s-1.3-2.9-2.9-2.9m0 5.4c-1.4 0-2.4-1.1-2.4-2.5s1.1-2.5 2.4-2.5 2.4 1.1 2.4 2.5-1.1 2.5-2.4 2.5" />
      <path d="M295.6 24.9c0-.6-.4-.9-1.2-.9h-1.2v3.3h.5v-1.4h.6l.8 1.4h.6l-.8-1.5c.5-.1.7-.4.7-.9m-1.2.5h-.6v-1h.6c.5 0 .7.1.7.5-.1.4-.3.5-.7.5M71.5 13.3c-1.6 0-2.9 1.3-2.9 2.9 0 1.7 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9.1-1.6-1.2-2.9-2.9-2.9m0 5.4c-1.4 0-2.4-1.1-2.4-2.5s1.1-2.5 2.4-2.5c1.3 0 2.4 1.1 2.4 2.5.1 1.4-1 2.5-2.4 2.5" />
      <path d="M72.8 15.5c0-.6-.4-.9-1.2-.9h-1.2v3.3h.6v-1.4h.6l.8 1.4h.6l-.8-1.5c.3-.1.6-.4.6-.9m-1.2.5H71v-1h.6c.5 0 .7.1.7.5-.1.4-.3.5-.7.5" />
      <path
        fill="#E8171F"
        d="M14.9 31.8c3.5-7 9.2-12.6 16-15.8.8-.4 1.6-.7 2.4-1 .1 0 0-.1 0-.1C9.2 19-1.2 48.9 14.9 61.8c.1 0 .1 0 .1-.1-2.3-3.3-7.9-14.4-.1-29.9"
      />
      <path
        fill="#E8171F"
        d="M20.4 51.8c-.6-4-.6-8 0-11.9 1.3-7.8 5-14.9 10.4-20.2 1-1 2.1-1.9 3.2-2.8.1 0 0-.1-.1-.1-12.6 5.6-19.9 19.7-19 32.5.5 5.9 2.8 16.6 14 18.8.1 0 .1-.1.1-.1-4.5-3.2-7.8-10.6-8.6-16.2M4.8 22.9C10.4 14.9 22 9.6 33.3 11c.1 0 .1-.1 0-.1-8.6-2.5-17.6-.8-23.1 3.6-2.4 1.9-4.2 5.2-5.6 8.4.1 0 .2.1.2 0"
      />
      <path
        fill="#E8171F"
        d="M68.5 32.8C67 14.6 50.6.8 32.4 2.5 26 3 19.3 5.6 14.2 9.9c-.1.1 0 .1.1.1 4.3-2.5 10.3-2.4 13.8-2 1.8.2 3.7.6 5.4 1.2 1.8.5 3.5 1.3 5.1 2.1.8.4 1.7.9 2.5 1.5 3-1.3 6.1-2.2 9.3-2.6.2 0 .5.1.5.3.1.3-.1.6-.3.6-1.7.4-3.4.8-5 1.5-.1 0-.1.1 0 .1 2.3 0 4.6.3 6.8.7.2 0 .4.2.4.4 0 .3-.2.6-.5.5-2-.3-3.9-.4-5.9-.3-.1 0-.1.1 0 .1.9.3 1.8.6 2.7 1 1.7.7 3.3 1.5 4.8 2.5.1.1.2.3.2.4 0 .4-.4.5-.7.4-1.5-.8-3.1-1.6-4.8-2.2-.7-.2-1.4-.5-2.1-.7-.1 0-.1.1-.1.1 1.2.7 2.3 1.5 3.3 2.4 1.4 1.2 2.7 2.5 3.9 3.8.1.1.2.3.1.5-.2.3-.6.4-.8.1-1.2-1.3-2.5-2.5-3.9-3.6-.8-.6-1.7-1.2-2.6-1.8-.1 0-.1 0-.1.1.3.3.5.5.7.8 1.2 1.4 2.3 2.8 3.2 4.4s1.7 3.2 2.4 4.9c.1.2 0 .4-.1.5-.3.3-.7.2-.8-.1-.7-1.6-1.5-3.2-2.4-4.7-.9-1.5-2-2.9-3.2-4.2-1.2-1.3-2.5-2.5-3.9-3.5-.4-.3-1-.7-1.2-.9-27.4 15.3-17.9 55.4 1.9 53.3 15.9-3.5 26.9-18.2 25.6-34.8"
      />
      <path
        fill="#E8171F"
        d="M9 27.8c6.3-10 17.3-14.3 24.1-15 .1 0 .1-.1 0-.1-2.8-.2-5.4-.1-8.7.6-3.4.8-6.7 2.1-9.7 4-3 1.9-5.6 4.3-7.7 7.1 0 0 0 .1-.1.1-4 5.5-5.3 12.6-3.7 19.2.2.8.7 2.3 1 3.3 0 .1.1 0 .1 0-.8-7.9 1.5-14.1 4.7-19.2"
      />
    </svg>
  );
}
