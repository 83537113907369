import React from "react";

import TrialUserApproval from "organisms/TrialUserApproval";
import { SubmittedTrialRequest } from "domain/trial";
import Footer from "components/Footer";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import useUser from "hooks/useUser";
import PageLoader from "molecules/PageLoader";
import SkipNavigationLink from "atoms/SkipNavigationLink";
import Navbar, { EmptyNavbar } from "components/Navbar";
import useTrialRequests from "hooks/useTrialRequests";

import styles from "./module.sass";
import MainContainer from "atoms/Layout";

export default function TrialUserApprovalPage() {
  const { status: isLoggedInStatus, isLoggedIn } = useIsLoggedIn();
  if (isLoggedInStatus === "success" && !isLoggedIn) {
    redirectToLogin();
  }

  const { status: userStatus, user } = useUser();
  if (userStatus === "success" && !user?.permissions.trialUserApproval) {
    redirectToLogin();
  }

  const {
    status: trialRequestsStatus,
    trialRequests,
    mutations,
  } = useTrialRequests();

  const handleApprove = (trialRequest: SubmittedTrialRequest, note: string) => {
    mutations.approval.mutate({ trialRequest, note });
  };
  const handleReject = (trialRequest: SubmittedTrialRequest, note: string) => {
    mutations.rejection.mutate({ trialRequest, note });
  };
  const handleHide = (trialRequest: SubmittedTrialRequest, note: string) => {
    mutations.hiding.mutate({ trialRequest, note });
  };

  const isLoading =
    userStatus !== "success" ||
    isLoggedInStatus !== "success" ||
    trialRequestsStatus !== "success";

  return (
    <MainContainer>
      <SkipNavigationLink />
      <PageLoader isLoading={isLoading} />
      {user ? <Navbar user={user} /> : <EmptyNavbar />}
      <main className={styles.container}>
        <TrialUserApproval
          trialRequests={trialRequests}
          onApprove={handleApprove}
          onReject={handleReject}
          onHide={handleHide}
        />
      </main>
      <Footer position="static" />
    </MainContainer>
  );
}

function redirectToLogin() {
  window.location.href = "/login.php";
}
