import React, { MouseEventHandler } from "react";
import cn from "classnames";

import { X as XIcon } from "components/Icons";

import styles from "./module.sass";

export type Props = {
  label: string;
  value: string;
  className?: string;
  onClear?: MouseEventHandler<HTMLButtonElement>;
};

const Pill = ({ label, value, onClear = () => {}, className }: Props) => (
  <div className={cn(className, styles.root)}>
    <div>
      <span className={styles.label}>{label}:&nbsp;</span>
      <span className={styles.value}>{value}</span>
    </div>

    <button type="button" className={styles.button} onClick={onClear}>
      <XIcon className={styles.x} />
    </button>
  </div>
);

export default Pill;
