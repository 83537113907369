import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileExcel,
  faMoneyBillAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBriefcase,
  faFirstAid,
  faChartBar,
  faChartPie,
  faDatabase,
  faDesktop,
  faFolderOpen,
  faShareAlt,
  faTable,
  faTags,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

import { Briefcase } from "domain/briefcases";
import { Permissions, User } from "domain/users/models";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";
import PPRDialog from "components/PPRDialog";

import styles from "./module.sass";

export type Props = {
  briefcase: Briefcase;
  permissions: Permissions;
  hasUserCompany: User["hasCompany"];
  isOpen: boolean;
  isReadOnly: boolean;
  onSave: (briefcase: Briefcase) => void;
  onDismiss: () => void;
  onAfterFinalStrategy: (briefcase: Briefcase) => void;
  onPortfolioPerformanceReport: (
    briefcase: Briefcase,
    dateFrom: Date,
    dateTo: Date | null
  ) => void;
};

export default function BriefcaseDialog({
  briefcase,
  permissions,
  hasUserCompany,
  isReadOnly,
  isOpen,
  onSave,
  onDismiss,
  onAfterFinalStrategy,
  onPortfolioPerformanceReport,
}: Props) {
  const [name, setName] = useState(briefcase.name);
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState(briefcase.description);
  const [isPPRDialogShown, setIsPPRDialogShown] = useState(false);
  const isTouched =
    name !== briefcase.name || description !== briefcase.description;

  const handleGeneratePPR = (dateFrom: Date, dateTo: Date | null) => {
    setIsPPRDialogShown(false);
    onPortfolioPerformanceReport(briefcase, dateFrom, dateTo);
  };
  const validate = () => {
    setNameError("");
    if (!name) {
      setNameError("This field is required.");
      return false;
    }
    return true;
  };
  const handleSave = () => {
    if (!validate()) {
      return;
    }
    onSave({
      ...briefcase,
      name,
      description,
    });
  };

  return (
    <>
      <Modal
        title="Briefcase menu"
        ariaLabel="Briefcase dialog"
        isOpen={isOpen}
        onDismiss={onDismiss}
      >
        <form className={styles.form}>
          <Input
            label="Name"
            value={name}
            error={nameError}
            isReadOnly={isReadOnly}
            onChange={(value) => setName(value)}
          />
          <textarea
            className={styles.textarea}
            placeholder="some note..."
            value={description}
            disabled={isReadOnly}
            onChange={(e) => setDescription(e.target.value)}
          />
          {!isReadOnly && (
            <Button
              variant="brand"
              size="sm"
              isDisabled={!isTouched}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </form>
        <div className={styles.grid}>
          <a
            href={`/portfolio.php?Parent=Briefcase&Briefcase=${briefcase.uuid}`}
            target="_blank"
            className={styles.gridItem}
          >
            <FontAwesomeIcon
              icon={faChartPie}
              size="4x"
              style={{ backgroundImage: "none" }}
            />
            <span>VISUAL EXPLORATION</span>
          </a>
          <a
            href={`/statistics.php?Parent=Briefcase&Briefcase=${briefcase.uuid}`}
            target="_blank"
            className={styles.gridItem}
          >
            <FontAwesomeIcon
              icon={faChartBar}
              size="4x"
              style={{ backgroundImage: "none" }}
            />
            <span>DASHBOARD EXPLORATION</span>
          </a>
          {permissions.alignmentReport && (
            <a
              href={`/alignment-report.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faFileAlt}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>ALIGNMENT REPORTS</span>
            </a>
          )}
          {permissions.prosecutionPatternMonitoring &&
          !briefcase.isAutoFolder &&
          !briefcase.isFollower ? (
            <a
              href={`/ppm.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faDesktop}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>PROSECUTION PATTERN MONITORING</span>
            </a>
          ) : null}
          {permissions.autoFolders &&
          !briefcase.isAutoFolder &&
          !briefcase.isFollower ? (
            <a
              href={`/auto-folder.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>AUTO FOLDERS</span>
            </a>
          ) : null}
          {permissions.briefcaseAccess && (
            <a
              href={`/briefcase-management.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faBriefcase}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>APPLICATIONS</span>
            </a>
          )}
          {briefcase.isCreatedByCurrentUser &&
          hasUserCompany &&
          !briefcase.hasPrivateOutOfCompanyApplications &&
          !(briefcase.isAutoFolder && briefcase.hasFollowerBriefcaseSync) ? (
            <a
              href={`/briefcase-sharing.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faShareAlt}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>SHARING</span>
            </a>
          ) : null}
          {permissions.briefcaseMetadataManagement && !briefcase.isAutoFolder && (
            <a
              href={`/briefcase-metadata-management.php?Parent=Briefcase&Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faTags}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>METADATA MANAGEMENT</span>
            </a>
          )}
          {permissions.budgetManagement && !briefcase.isAutoFolder && (
            <a
              href={`/budget-management.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faMoneyBillAlt}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>BUDGET MANAGEMENT</span>
            </a>
          )}
          {permissions.briefcaseHealth && (
            <a
              href={`/briefcase-health.php?Briefcase=${briefcase.uuid}`}
              target="_blank"
              className={styles.gridItem}
            >
              <FontAwesomeIcon
                icon={faFirstAid}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>
                {briefcase.isAutoFolder
                  ? "AUTO FOLDER HEALTH"
                  : "BRIEFCASE HEALTH"}
              </span>
            </a>
          )}
          <div
            className={styles.gridItem}
            onClick={() => onAfterFinalStrategy(briefcase)}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              size="4x"
              style={{ backgroundImage: "none" }}
            />
            <span>AFTER FINAL STRATEGY</span>
          </div>
          {!briefcase.isAutoFolder && (
            <div
              className={styles.gridItem}
              onClick={() => setIsPPRDialogShown(true)}
            >
              <FontAwesomeIcon
                icon={faTable}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>PORTFOLIO PERFORMANCE REPORT</span>
            </div>
          )}
          {briefcase.isCreatedByCurrentUser && briefcase.isFollower && (
            <div
              className={styles.gridItem}
              onClick={(e) => {
                const urlParams = new URLSearchParams({
                  Briefcase: briefcase.uuid,
                });
                window.location.href = `/briefcase-sync-manual.php?${urlParams.toString()}`;
              }}
            >
              <FontAwesomeIcon
                icon={faSync}
                size="4x"
                style={{ backgroundImage: "none" }}
              />
              <span>BRIEFCASE SYNC</span>
            </div>
          )}
        </div>
      </Modal>
      <PPRDialog
        isOpen={isPPRDialogShown}
        onDismiss={() => setIsPPRDialogShown(false)}
        onGenerate={handleGeneratePPR}
      />
    </>
  );
}
