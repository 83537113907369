import axios, { AxiosResponse } from "axios";

type BaseResponse = {
  status: string;
  message: string;
};

export type APIExaminer = {
  id: number;
  name: string;
};

type CancelablePromise<T> = Promise<T> & { cancel: () => void };

export function searchExaminers(nameFragment: string) {
  type Response = BaseResponse & { data: APIExaminer[] };

  const cancelTokenSource = axios.CancelToken.source();

  const params = new URLSearchParams({
    function: "get_examiner",
    examiner_name: nameFragment,
    limit_results: "100",
  });

  const promise = axios.post<Response>("/api/anticipat_api.php", params, {
    cancelToken: cancelTokenSource.token,
  });
  (promise as CancelablePromise<AxiosResponse<Response>>).cancel = () => {
    cancelTokenSource.cancel();
  };

  return promise as CancelablePromise<AxiosResponse<Response>>;
}
