import axios from "axios";

import issueIdToName from "./issue-id-to-name.json";
import {
  Decision,
  Issue,
  IssueCategory,
  IssueGroup,
  TextChunk,
  IssueID,
  IssueOutcome,
} from "./models";

interface APIIssue {
  id: IssueID;
  outcome: IssueOutcome;
  textChunks: TextChunk[];
}

type APIDecision = Omit<Decision, "decisionDate" | "filingDate" | "issues"> & {
  decisionDate: string;
  filingDate: string;
  issues: APIIssue[];
};

interface Response<TData = any> {
  data: TData;
}

export function getDecision(id: number): Promise<Decision> {
  const formData = new FormData();
  formData.append("function", "get_detailed_opinion");
  formData.append("decision_id", id.toString());

  return axios
    .post<Response<APIDecision>>("/api/anticipat_api.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => mapToDomainDecision(resp.data.data));
}

function mapToDomainDecision(apiDecision: APIDecision): Decision {
  return {
    ...apiDecision,
    filingDate: apiDecision.filingDate
      ? new Date(apiDecision.filingDate)
      : null,
    decisionDate: new Date(apiDecision.decisionDate),
    issueGroups: mapApiIssuesToIssueGroups(apiDecision.issues),
  };
}

function mapApiIssuesToIssueGroups(apiIssues: APIIssue[]): IssueGroup[] {
  const issueGroupToIssues: Partial<{ [key in IssueCategory]: Issue[] }> = {};

  apiIssues.forEach((apiIssue) => {
    const issueCategory = getIssueCategory(apiIssue.id);

    if (!issueGroupToIssues[issueCategory]) {
      issueGroupToIssues[issueCategory] = [];
    }

    issueGroupToIssues[issueCategory]!.push(mapApiIssueToIssue(apiIssue));
  });

  return Object.keys(issueGroupToIssues).map((category) => {
    return {
      category,
      issues: issueGroupToIssues[category as IssueCategory],
    } as IssueGroup;
  });
}

function mapApiIssueToIssue(apiIssue: APIIssue): Issue {
  return {
    ...apiIssue,
    name: issueIdToName[apiIssue.id],
    category: getIssueCategory(apiIssue.id),
  };
}

function getIssueCategory(id: APIIssue["id"]): IssueCategory {
  if (id.startsWith("101")) return "101";
  if (id.startsWith("102")) return "102";
  if (id.startsWith("103")) return "103";
  if (id.includes("112(a)")) return "112a";
  if (id.includes("112(b)")) return "112b";
  if (id.includes("112(d)")) return "112d";
  if (id.includes("112(f)")) return "112f";
  if (id.includes("Markoush")) return "improperMarkoushGrouping";
  if (id.includes("Obviousness Type")) return "obviousnessTypeDoublePatenting";

  throw Error(`Couldn't map ${id} to an issue category`);
}
