import React from "react";

export default function Full({
  logoColor,
  textColor,
}: {
  logoColor?: string;
  textColor?: string;
}) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 181.42 35.01"
      width={199.406}
      height={38.588}
    >
      <path
        fill={textColor || "#010101"}
        d="M48.99 8.98h-2.14v16.77h10.64v-2.08h-8.5V8.98zM138.84 15l-1.42-1.23-4.08 4.69-4.04-4.65-.04-.04-1.42 1.23 4.25 4.9-4.21 4.85-.04.04 1.42 1.23 4.08-4.69 4.05 4.65.03.04 1.42-1.23-4.25-4.89 4.22-4.86.03-.04zM110.97 21.04L99.53 8.98h-.42v16.77h2.13V13.7l11.44 12.05h.42V8.98h-2.13v12.06zM140.98 14.04H143v11.72h-2.02zM140.88 8.99h2.21v1.89h-2.21z"
      />
      <path
        fill={textColor || "#010101"}
        d="M426.61 201.93a6 6 0 00.14 12.09 6.5 6.5 0 004.71-1.9l-1.46-1.26a4.82 4.82 0 01-3.26 1.26 3.91 3.91 0 01-3.94-3.65h9.52c-.04-4.18-2.43-6.54-5.71-6.54zm-3.71 4.78a3.68 3.68 0 013.59-2.93 3.35 3.35 0 013.56 2.93zM455.53 207.15l-1-.35c-1.41-.49-1.78-1.05-1.78-1.78s.81-1.29 1.73-1.29a2.53 2.53 0 012 .92l1.36-1.19a4.14 4.14 0 00-3.34-1.53c-2.21 0-3.8 1.36-3.8 3.11 0 1.41.76 2.7 2.9 3.47l.95.35c1.44.5 2.07 1 2.07 1.88s-1 1.48-2.17 1.48a3.6 3.6 0 01-2.87-1.48l-1.46 1.26a5.43 5.43 0 004.27 2c2.65 0 4.31-1.48 4.31-3.35s-1.1-2.77-3.17-3.5z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={textColor || "#010101"}
        d="M81.56 15l-1.43-1.23-4.08 4.69-4.04-4.65-.04-.04L70.55 15l4.26 4.9-4.22 4.85-.04.04 1.42 1.23 4.08-4.69 4.05 4.65.03.04 1.43-1.23-4.26-4.89 4.22-4.86.04-.04zM83.69 14.04h2.02v11.72h-2.02zM83.59 8.99h2.21v1.89h-2.21z"
      />
      <path
        fill={textColor || "#010101"}
        d="M369.32 201.93a6 6 0 00.14 12.09 6.5 6.5 0 004.71-1.9l-1.44-1.25a4.82 4.82 0 01-3.26 1.26 3.91 3.91 0 01-3.94-3.65H375c0-4.19-2.4-6.55-5.68-6.55zm-3.71 4.78a3.68 3.68 0 013.59-2.93 3.35 3.35 0 013.56 2.93zM398.24 207.15l-1-.35c-1.41-.49-1.78-1.05-1.78-1.78s.81-1.29 1.73-1.29a2.53 2.53 0 012 .92l1.36-1.19a4.14 4.14 0 00-3.34-1.53c-2.21 0-3.8 1.36-3.8 3.11 0 1.41.76 2.7 2.9 3.47l.95.35c1.44.5 2.07 1 2.07 1.88s-1 1.48-2.17 1.48a3.6 3.6 0 01-2.87-1.48l-1.46 1.26a5.43 5.43 0 004.27 2c2.65 0 4.31-1.48 4.31-3.35s-1.1-2.77-3.17-3.5zM460.37 199a1.56 1.56 0 00-1.56 1.56 1.56 1.56 0 101.56-1.56zm0 2.88a1.32 1.32 0 111.29-1.32 1.3 1.3 0 01-1.29 1.27z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={textColor || "#010101"}
        d="M461 200.11c0-.32-.23-.5-.63-.5h-.62v1.76h.29v-.74h.31l.4.74h.31l-.42-.78a.44.44 0 00.36-.48zm-.64.27h-.32v-.51h.31c.25 0 .37.08.37.25s-.09.26-.34.26zM341.84 193.93a1.57 1.57 0 000 3.13 1.57 1.57 0 000-3.13zm0 2.88a1.29 1.29 0 01-1.3-1.31 1.3 1.3 0 112.59 0 1.28 1.28 0 01-1.29 1.31z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={textColor || "#010101"}
        d="M342.49 195.09c0-.32-.23-.5-.62-.5h-.63v1.76h.29v-.73h.31l.4.73h.32l-.42-.78a.44.44 0 00.35-.48zm-.64.27h-.31v-.5h.31c.25 0 .36.07.36.24s-.11.26-.36.26z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={logoColor || "#e61e25"}
        d="M311.7 203.75a18.6 18.6 0 018.52-8.41c.42-.21.85-.39 1.28-.56v-.07c-12.83 2.16-18.33 18.11-9.77 24.93h.06c-1.27-1.75-4.2-7.64-.09-15.89z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={logoColor || "#e61e25"}
        d="M314.64 214.38a19.93 19.93 0 010-6.31 19.46 19.46 0 015.54-10.73 16.79 16.79 0 011.69-1.47v-.06c-6.7 3-10.57 10.48-10.08 17.3.28 3.11 1.5 8.85 7.47 10 0 0 .06 0 0-.07-2.46-1.7-4.19-5.66-4.62-8.66zM306.35 199c3-4.24 9.16-7.09 15.17-6.35v-.07c-4.58-1.34-9.36-.44-12.27 1.92a11.76 11.76 0 00-3 4.47s.08.08.1.03z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={logoColor || "#e61e25"}
        d="M340.22 204.3a17.67 17.67 0 00-28.86-12.18v.06c2.29-1.32 5.46-1.28 7.32-1.07a16.6 16.6 0 012.9.61 17.52 17.52 0 012.72 1.13c.45.24.89.5 1.31.78a18.18 18.18 0 014.94-1.41.25.25 0 01.28.16.25.25 0 01-.19.33 18 18 0 00-2.66.78v.07a17.71 17.71 0 013.64.38.26.26 0 01.2.21.26.26 0 01-.29.29 17.48 17.48 0 00-3.15-.19v.07c.49.15 1 .33 1.46.52a16.8 16.8 0 012.57 1.31.25.25 0 01-.25.43 18.24 18.24 0 00-2.55-1.15c-.37-.13-.74-.24-1.12-.35 0 0-.06 0 0 .07a17.33 17.33 0 011.77 1.28 18 18 0 012.05 2 .23.23 0 010 .25.25.25 0 01-.41.08 16.72 16.72 0 00-2.05-1.9 15.67 15.67 0 00-1.36-.94l-.05.05c.14.14.27.29.4.43a15.92 15.92 0 013 5 .25.25 0 010 .26.25.25 0 01-.42-.08 15.83 15.83 0 00-1.28-2.49 15.66 15.66 0 00-1.7-2.21 16 16 0 00-2.07-1.87c-.21-.16-.53-.38-.66-.46-14.58 8.13-9.52 29.48 1 28.37a17.67 17.67 0 0013.51-18.62z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={logoColor || "#e61e25"}
        d="M308.57 201.64a18 18 0 0112.81-8v-.06a15 15 0 00-4.65.34 15.9 15.9 0 00-5.13 2.15 15.62 15.62 0 00-4.08 3.78v.05a12.1 12.1 0 00-2 10.14c.09.4.37 1.24.55 1.77h.07a15.43 15.43 0 012.43-10.17z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={textColor || "#010101"}
        id="Path_10161"
        data-name="Path 10161"
        d="M473.58 197v16.79h-2.16V197z"
        transform="translate(-305.12 -188.08)"
      />
      <path
        fill={textColor || "#010101"}
        id="Path_10162"
        data-name="Path 10162"
        d="M476.33 197.37a24.59 24.59 0 014.11-.33 6.76 6.76 0 014.64 1.39 4.5 4.5 0 011.45 3.48 4.88 4.88 0 01-1.28 3.56 6.8 6.8 0 01-5.06 1.83 7.11 7.11 0 01-1.72-.15v6.68h-2.13zm2.14 8a7 7 0 001.77.17c2.58 0 4.15-1.26 4.15-3.56s-1.54-3.26-3.91-3.26a9.05 9.05 0 00-2 .17z"
        transform="translate(-305.12 -188.08)"
      />
    </svg>
  );
}
