import axios from "axios";
import { BASE_URI } from "../api";

export const STATUSES = {
  OK: "ok",
  WARNING: "warning",
  ERROR: "error",
};

export type SERVER_RESPONSE = { data?: any; message?: string; status?: string };

export const basicFetch = async (
  formData: FormData
): Promise<SERVER_RESPONSE | undefined> => {
  try {
    const x = await axios({
      method: "post",
      baseURL: BASE_URI,
      url: `/api/anticipat_api.php`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const resp: SERVER_RESPONSE = x.data;

    if (resp.status !== STATUSES.OK) {
      console.error("basicFetch", JSON.stringify(resp));
    }

    return resp;
  } catch (e) {
    console.log("api error", e);
  }
};

export const fetchItems = async (formData: FormData): Promise<Array<any>> => {
  const resp = await basicFetch(formData);

  if (resp?.status === STATUSES.OK && Array.isArray(resp.data)) {
    return resp.data;
  } else {
    return [];
  }
};

export const downloadBlob = (object: any, fileName: string) => {
  const url = window.URL.createObjectURL(object);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const base64toBlob = (base64Data: string): Blob => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays);
};
