import React from "react";

const OpenInNewWindow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={16}
    height={16}
    fill={"#fff"}
  >
    <path d="M15.27 0H9.38a.745.745 0 1 0 0 1.49h4.1L5.93 9.04a.73.73 0 0 0 1 1l7.61-7.55v4.1a.73.73 0 1 0 1.46 0V.73a.73.73 0 0 0-.73-.73Z" />
    <path d="M12.48 7.19a.74.74 0 0 0-.74.74v6.56H1.47V4.26h6.61a.74.74 0 0 0 0-1.47H.74a.74.74 0 0 0-.74.73v11.75a.74.74 0 0 0 .74.73h11.74a.73.73 0 0 0 .73-.73V7.93a.74.74 0 0 0-.73-.74Z" />
  </svg>
);

export default OpenInNewWindow;
