import React, { SVGProps } from "react";

export type Props = {
  variant?: "rounded" | "sharp";
} & SVGProps<SVGSVGElement>;

const X = ({
  variant = "rounded",
  fill = "#000",
  width,
  height,
  style,
  ...rest
}: Props) =>
  variant === "rounded" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={width || 12}
      height={height || 12}
      fill={fill}
      tabIndex={-1}
      style={{ fill, ...style }}
      {...rest}
    >
      <path d="M7.42 6l4.29 4.3a1 1 0 01-1.41 1.41L6 7.42l-4.29 4.29A1 1 0 01.3 10.3L4.59 6 .3 1.71A1 1 0 01.3.3a1 1 0 011.41 0L6 4.59 10.3.3a1 1 0 011.41 0 1 1 0 010 1.41z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={width || 13.2}
      width={width || 13.7}
      viewBox="0 0 13.7 13.2"
      style={{ fill, ...style }}
      {...rest}
    >
      <path d="M13.7 2.2L11.4 0 6.8 4.4 2.3 0 0 2.2l4.5 4.4L0 11l2.3 2.2 4.5-4.4 4.6 4.4 2.3-2.2-4.6-4.4z" />
    </svg>
  );

export default X;
