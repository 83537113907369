import React from "react";

import { makeNumberReadable } from "domain/humanize";

export type Props = {
  children: number;
};

/**
 * Makes a number readable by adding commas.
 *
 * Could be extended to handle money and other use cases.
 */
export default function Readable(props: Props) {
  return <span>{makeNumberReadable(props.children)}</span>;
}
