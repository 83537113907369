import React, { useRef } from "react";

import styles from "./module.sass";

/**
 * Scrolls to <main /> on click.
 */
export default function SkipNavigationLink() {
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    const target = document.querySelector("main");

    target?.scrollIntoView();
    ref.current?.blur();
  };

  return (
    <button
      ref={ref}
      className={styles.skipNavigationLink}
      onClick={handleClick}
    >
      Skip to main content
    </button>
  );
}
