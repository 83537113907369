import React, {
  FormEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";

import { APIArtUnit } from "domain/artUnit";
import useArtUnitAutocomplete from "hooks/useArtUnitAutocomplete";
import { DocumentType, AppStatus, RejectionType } from "domain/applications";
import { APIExaminer } from "domain/examiners";
import useExaminerAutocomplete from "hooks/useExaminerAutocomplete";
import { MultiSelect } from "components/Select";
import DatePicker from "components/DatePicker";
import Pill from "components/Pill";
import Button from "components/Button";
import { SearchInput } from "components/Input";
import AutocompleteInput from "molecules/AutocompleteInput";
import { getAnalytics } from "domain/analytics";
import { format as formatDate } from "domain/dates";

import { TabPanel, SecondaryTabPanel, TabPanelItem } from ".";

export default function FileWrapperForm() {
  const [keywordSearch, setKeywordSearch] = useState("");
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [rejectionTypes, setRejectionTypes] = useState<RejectionType[]>([]);
  const [appStatuses, setAppStatuses] = useState<AppStatus[]>([]);
  const [mailRoomDateFrom, setMailRoomDateFrom] = useState<Date | null>(null);
  const [mailRoomDateTo, setMailRoomDateTo] = useState<Date | null>(null);
  const [mailRoomLabelFrom, setMailRoomLabelFrom] = useState("Date From");
  const [mailRoomLabelTo, setMailRoomLabelTo] = useState("Date To");

  const [artUnitQuery, setArtUnitQuery] = useState("");
  const artUnitQueryRef = useRef<HTMLInputElement>(null);
  const [artUnits, setArtUnits] = useState<APIArtUnit[]>([]);
  const artUnitSuggestions = useArtUnitAutocomplete(artUnitQuery);

  const [examinerQuery, setExaminerQuery] = useState("");
  const examinerQueryRef = useRef<HTMLInputElement>(null);
  const [examiners, setExaminers] = useState<APIExaminer[]>([]);
  const examinerSuggestions = useExaminerAutocomplete(examinerQuery);

  useEffect(() => {
    if (documentTypes.includes("officeActions")) {
      return;
    }

    setRejectionTypes([]);
  }, [documentTypes]);

  const selectArtUnit = (artUnit: APIArtUnit) => {
    setArtUnits((artUnits) => [
      ...artUnits.filter((a) => a.id !== artUnit.id),
      artUnit,
    ]);
    setArtUnitQuery("");
    artUnitQueryRef.current?.focus();
  };
  const unselectArtUnit = (artUnit: APIArtUnit) => {
    setArtUnits((artUnits) => artUnits.filter((a) => a.id !== artUnit.id));
  };

  const selectExaminer = (examiner: APIExaminer) => {
    setExaminers((examiners) => [
      ...examiners.filter((e) => e.id !== examiner.id),
      examiner,
    ]);
    setExaminerQuery("");
    examinerQueryRef.current?.focus();
  };
  const unselectExaminer = (examiner: APIExaminer) => {
    setExaminers((examiners) => examiners.filter((e) => e.id !== examiner.id));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    recordFormSubmission({
      keywordSearch,
      documentTypes,
      rejectionTypes,
      applicationStatuses: appStatuses,
      artUnits,
      examiners,
      mailRoomDateFrom,
      mailRoomDateTo,
    });

    const urlParams = new URLSearchParams({
      keywords: keywordSearch,
      documentTypes: documentTypes.join(","),
      rejectionTypes: rejectionTypes.join(","),
      appStatuses: appStatuses.join(","),
      // YYYY-mm-dd
      mailRoomDateFrom: mailRoomDateFrom?.toISOString().split("T")[0] ?? "",
      // YYYY-mm-dd
      mailRoomDateTo: mailRoomDateTo?.toISOString().split("T")[0] ?? "",
      artUnits: artUnits.map((a) => a.id).join(","),
      examiners: examiners.map((e) => e.id).join(","),
    });
    window.location.href = `file-wrappers.php?${urlParams.toString()}`;
  };
  const handleResetClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setKeywordSearch("");
    setDocumentTypes([]);
    setRejectionTypes([]);
    setAppStatuses([]);
    setMailRoomDateFrom(null);
    setMailRoomDateTo(null);
    setMailRoomLabelFrom("Date From");
    setMailRoomLabelTo("Date To");
    setArtUnits([]);
    setArtUnitQuery("");
    setExaminers([]);
    setExaminerQuery("");
  };

  const hasAnyValue =
    keywordSearch.length > 0 ||
    documentTypes.length > 0 ||
    rejectionTypes.length > 0 ||
    appStatuses.length > 0 ||
    mailRoomDateFrom !== null ||
    mailRoomDateTo !== null ||
    artUnits.length > 0 ||
    examiners.length > 0;
  const artUnitIds = artUnits.map((a) => a.id);
  const examinersIds = examiners.map((e) => e.id);
  const areRejectionTypesShown = documentTypes.includes("officeActions");

  return (
    <>
      <TabPanel>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <TabPanelItem>
            <SearchInput
              label="Keyword Search"
              value={keywordSearch}
              onChange={(value) => setKeywordSearch(value.trimLeft())}
            />
          </TabPanelItem>
          <TabPanelItem>
            <MultiSelect
              label="Document Group"
              values={documentTypes}
              onChange={(values) => setDocumentTypes(values)}
              options={documentTypeOptions}
            />
          </TabPanelItem>
          {areRejectionTypesShown && (
            <TabPanelItem>
              <MultiSelect
                label="Rejection Type (Office Actions)"
                values={rejectionTypes}
                onChange={(values) => setRejectionTypes(values)}
                options={rejectionTypeOptions}
              />
            </TabPanelItem>
          )}
          <TabPanelItem>
            <MultiSelect
              label="Application Status"
              values={appStatuses}
              onChange={(values) => setAppStatuses(values)}
              options={appStatusOptions}
            />
          </TabPanelItem>
          <TabPanelItem>
            <AutocompleteInput
              ref={artUnitQueryRef}
              value={artUnitQuery}
              label={artUnits.length ? "Add another Art Unit" : "Art Unit"}
              options={
                artUnitSuggestions !== null
                  ? artUnitSuggestions
                      .filter((a) => !artUnitIds.includes(a.id))
                      .map((artUnit) => {
                        return {
                          label: artUnit.name,
                          value: artUnit.id.toString(),
                        };
                      })
                  : []
              }
              onChange={(value) => setArtUnitQuery(value)}
              onSelect={(option) =>
                selectArtUnit({
                  id: Number(option.value),
                  name: String(option.label),
                })
              }
              isNoResultsMessageShown={
                artUnitSuggestions !== null && !artUnitSuggestions.length
              }
            />
          </TabPanelItem>
          <TabPanelItem>
            <AutocompleteInput
              ref={examinerQueryRef}
              value={examinerQuery}
              label={
                examiners.length ? "Add another Examiner" : "Examiner Name"
              }
              options={
                examinerSuggestions !== null
                  ? examinerSuggestions
                      .filter((e) => !examinersIds.includes(e.id))
                      .map((examiner) => {
                        return {
                          label: examiner.name,
                          value: examiner.id.toString(),
                        };
                      })
                  : []
              }
              onChange={(value) => setExaminerQuery(value)}
              onSelect={(option) =>
                selectExaminer({
                  id: Number(option.value),
                  name: String(option.label),
                })
              }
              isNoResultsMessageShown={
                examinerSuggestions !== null && !examinerSuggestions.length
              }
            />
          </TabPanelItem>
          <div
            style={{
              marginBottom: 35,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DatePicker
              label={mailRoomLabelFrom}
              date={mailRoomDateFrom}
              isFutureDisabled={true}
              onChange={(date) => {
                setMailRoomDateFrom(date);
                setMailRoomLabelFrom("Mail Room Date From");
              }}
            />
            <DatePicker
              label={mailRoomLabelTo}
              date={mailRoomDateTo}
              isFutureDisabled={true}
              onChange={(date) => {
                setMailRoomDateTo(date);
                setMailRoomLabelTo("Mail Room Date To");
              }}
            />
          </div>
          <TabPanelItem>
            <Button isDisabled={!hasAnyValue} onClick={handleResetClick}>
              Reset
            </Button>
            <div style={{ paddingLeft: 50 }}>
              <Button
                id="homepageFileWrapperFormBtnSubmit"
                leftIcon="magnifier"
                type="submit"
                variant="brand"
                isDisabled={!hasAnyValue}
              >
                Search
              </Button>
            </div>
          </TabPanelItem>
        </form>
      </TabPanel>
      {hasAnyValue && (
        <SecondaryTabPanel>
          <div style={{ fontWeight: "bold", marginBottom: 4 }}>
            Applied Filters:
          </div>
          {keywordSearch.length > 0 && (
            <PillWrapper>
              <Pill
                label="Keyword Search"
                value={keywordSearch}
                onClear={() => setKeywordSearch("")}
              />
            </PillWrapper>
          )}
          {documentTypeOptions
            .filter((d) => documentTypes.includes(d.value))
            .map(({ value, label }) => (
              <PillWrapper>
                <Pill
                  label="Document Group"
                  value={label}
                  onClear={() =>
                    setDocumentTypes(documentTypes.filter((d) => d !== value))
                  }
                />
              </PillWrapper>
            ))}
          {rejectionTypeOptions
            .filter((o) => rejectionTypes.includes(o.value))
            .map(({ value, label }) => (
              <PillWrapper>
                <Pill
                  label="Rejection Type"
                  value={label}
                  onClear={() =>
                    setRejectionTypes(rejectionTypes.filter((r) => r !== value))
                  }
                />
              </PillWrapper>
            ))}
          {appStatusOptions
            .filter((a) => appStatuses.includes(a.value))
            .map(({ value, label }) => (
              <PillWrapper>
                <Pill
                  label="Application Status"
                  value={label}
                  onClear={() =>
                    setAppStatuses(appStatuses.filter((a) => a !== value))
                  }
                />
              </PillWrapper>
            ))}
          {mailRoomDateFrom && (
            <PillWrapper>
              <Pill
                label="From Date"
                value={new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }).format(mailRoomDateFrom)}
                onClear={() => {
                  setMailRoomDateFrom(null);
                  setMailRoomLabelFrom("Date From");
                }}
              />
            </PillWrapper>
          )}
          {mailRoomDateTo && (
            <PillWrapper>
              <Pill
                label="To Date"
                value={new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }).format(mailRoomDateTo)}
                onClear={() => {
                  setMailRoomDateTo(null);
                  setMailRoomLabelTo("Date To");
                }}
              />
            </PillWrapper>
          )}
          {artUnits.map((artUnit) => (
            <PillWrapper key={artUnit.id}>
              <Pill
                label="Art Unit"
                value={artUnit.name}
                onClear={() => unselectArtUnit(artUnit)}
              />
            </PillWrapper>
          ))}
          {examiners.map((examiner) => (
            <PillWrapper key={examiner.id}>
              <Pill
                label="Examiner Name"
                value={examiner.name}
                onClear={() => unselectExaminer(examiner)}
              />
            </PillWrapper>
          ))}
        </SecondaryTabPanel>
      )}
    </>
  );
}

const PillWrapper = (props: HTMLAttributes<HTMLDivElement>) => (
  <div style={{ marginTop: 8 }} {...props}></div>
);

const documentTypeOptions: {
  value: DocumentType;
  label: string;
}[] = [
  { value: "appeal", label: "Appeal" },
  { value: "claims", label: "Claims" },
  { value: "officeActions", label: "Office Actions" },
  { value: "petition", label: "Petition" },
  { value: "responses", label: "Responses" },
  { value: "reExam", label: "Re Exam" },
  { value: "terminalDisclaimers", label: "Terminal Disclaimers" },
  { value: "noticeOfAllowance", label: "Notice of Allowance" },
];

const rejectionTypeOptions: {
  value: RejectionType;
  label: string;
}[] = [
  { value: "101", label: "101 Rejection" },
  { value: "102", label: "102 Rejection" },
  { value: "103", label: "103 Rejection" },
  { value: "112", label: "112 Rejection" },
  { value: "alice", label: "References Alice" },
  { value: "mayo", label: "References Mayo" },
  { value: "myriad", label: "References Myriad" },
];

const appStatusOptions: {
  value: AppStatus;
  label: string;
}[] = [
  { value: "patented", label: "Patented" },
  { value: "abandoned", label: "Abandoned" },
  { value: "pending", label: "Pending" },
];

interface Query {
  keywordSearch: string;

  documentTypes: DocumentType[];
  rejectionTypes: RejectionType[];
  applicationStatuses: AppStatus[];

  artUnits: APIArtUnit[];
  examiners: APIExaminer[];

  mailRoomDateFrom: Date | null;
  mailRoomDateTo: Date | null;
}

/**
 * @todo Should we redact `keywordSearch`?
 */
function recordFormSubmission(query: Query) {
  const { artUnits, examiners, ...redactedQuery } = query;
  const { mailRoomDateFrom, mailRoomDateTo } = query;

  getAnalytics().recordEvent({
    pageName: "home_page",
    interfaceEvent: "search_file_wrappers",
    interfaceValue: "search",
    action: "click",
    context: {
      ...redactedQuery,
      artUnitCount: artUnits.length,
      examinerCount: examiners.length,
      mailRoomDateFrom: mailRoomDateFrom
        ? formatDate(mailRoomDateFrom, "Y-m-d")
        : null,
      mailRoomDateTo: mailRoomDateTo
        ? formatDate(mailRoomDateTo, "Y-m-d")
        : null,
    },
  });
}
