import React, { useEffect, RefObject, MouseEvent } from "react";

export default function useClickOutside(
  elRef: RefObject<HTMLElement>,
  callback: (e: MouseEvent<HTMLElement>) => void
) {
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!elRef?.current?.contains(e.target)) {
        callback(e);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [elRef, callback]);
}
