import React from "react";

export type Page =
  | QuickPAIR
  | { name: "login" }
  | { name: "passwordReset" }
  | { name: "homepage" }
  | { name: "other" };

type QuickPAIR =
  | { name: "quickPAIR"; applicationNumber: number; applicationId: null }
  | { name: "quickPAIR"; applicationNumber: null; applicationId: number }
  | { name: "quickPAIR"; applicationNumber: null; applicationId: null };

/**
 * Hook instead of a function as it might include
 * frontend pages once we move away server side rendering.
 */
export default function usePage(): Page {
  const { pathname, hash } = window.location;

  if (["/", "/index.php"].includes(pathname)) {
    return { name: "homepage" };
  }
  if (pathname.startsWith("/login.php")) {
    return { name: "login" };
  }
  if (pathname.startsWith("/forgot-password.php")) {
    return { name: "passwordReset" };
  }

  if (isQuickPAIR(window.location)) {
    return getQuickPAIR(window.location);
  }

  return { name: "other" };
}

function isQuickPAIR(location: Location) {
  const { pathname, hash } = location;
  const regex = /^\/(?:[^/]+\/)?quickpair/i;
  return !!regex.test(pathname);
}

function getQuickPAIR(location: Location): QuickPAIR {
  const { pathname, hash } = location;

  const applicationNumberMatches =
    /^\/(?:[^/]+\/)?quickpair\/(?<applicationNumber>\d+)/.exec(pathname);
  const applicationNumber = applicationNumberMatches?.[1];
  if (applicationNumber) {
    return {
      name: "quickPAIR",
      applicationNumber: Number(applicationNumber),
      applicationId: null,
    };
  }

  const applicationIdMatches =
    /^\/(?:[^/]+\/)?quickpair\/id\/(?<applicationId>\d+)/.exec(pathname);
  const applicationId = applicationIdMatches?.[1];
  if (applicationId) {
    return {
      name: "quickPAIR",
      applicationId: Number(applicationId),
      applicationNumber: null,
    };
  }

  return {
    name: "quickPAIR",
    applicationId: null,
    applicationNumber: null,
  };
}
