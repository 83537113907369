import React, { useState } from "react";
import useBrowserExtensionSettings from "hooks/useBrowserExtensionSettings";
import { InfoIcon } from "components/Icons";
import Popover from "atoms/Popover";
import Anchor from "atoms/Anchor";

import styles from "./module.sass";

export type Props = {
  hasBrowserExtension: boolean;
};

const SupportList = ({ hasBrowserExtension }: Props) => {
  const [isPopoverShown, setIsPopoverShown] = useState(false);

  const { supportDocumentsUrl } = useBrowserExtensionSettings();

  const faqUrl = supportDocumentsUrl?.data.faqUrl;
  const technicalSecurityUrl = supportDocumentsUrl?.data.technicalSecurityUrl;

  return (
    <div>
      <div
        id="browser-extension-id"
        style={{ fontWeight: "bold", fontSize: 16, marginBottom: "13px" }}
      >
        Support:
      </div>
      <ul
        className={styles.supportList}
        style={{
          listStyle: "none",
          marginBottom: 0,
          paddingLeft: 0,
          fontSize: 16,
        }}
      >
        {hasBrowserExtension ? null : (
          <li>
            <Popover
              offset={[0, 15]}
              content={Message()}
              padding="big"
              size="large"
              variant="light"
              isDismissable={true}
              isVisible={isPopoverShown}
              onDismiss={() => setIsPopoverShown(false)}
            >
              <span
                className={styles.hasTooltip}
                onMouseOver={() => setIsPopoverShown(true)}
              >
                Having Installation Issues? <InfoIcon color={"#6d6e71"} />
              </span>
            </Popover>
          </li>
        )}
        <li>
          <Anchor href={faqUrl} download>
            Frequently Asked Questions
          </Anchor>
        </li>
        <li>
          <Anchor href={technicalSecurityUrl} download>
            Technical and Security Requirements
          </Anchor>
        </li>
      </ul>
    </div>
  );
};

export default SupportList;

const Message = () => (
  <>
    <h2
      style={{
        margin: "0 0 15px",
        fontWeight: "bold",
        fontSize: "18px",
        letterSpacing: "unset",
      }}
    >
      Having Installation Issues?
    </h2>
    <p style={{ marginBottom: "0", letterSpacing: "unset" }}>
      Your firm may have restricted your ability to download. Please check with
      your librarian or IT <br />
      team to get access to the browser extension. You
      <br />
      can also contact us at{" "}
      <Anchor href="tel:8774123987 ">877-412-3987</Anchor> or through
      <br />
      <Anchor target="_blank" href="https://www.lexisnexisip.com/contact/">
        this link
      </Anchor>
      .
    </p>
  </>
);
