import { BASE_URI } from "../api";
import axios from "axios";

import { User, APIUser } from "./models";

function fetchUser(): Promise<User> {
  return axios
    .get<APIUser>(`${BASE_URI}/api/v2/user.php`, {})
    .then(({ data: apiUser }) => fromAPIUser(apiUser));
}

async function isLoggedIn(): Promise<boolean> {
  const resp = await axios.get<{ isLoggedIn: boolean }>(
    "/api/v2/is-logged-in.php"
  );
  return resp.data.isLoggedIn;
}

function fromAPIUser({ lastLogin, ...rest }: APIUser): User {
  return {
    ...rest,
    lastLogin: lastLogin ? new Date(lastLogin) : null,
  };
}

export { fetchUser, isLoggedIn };
