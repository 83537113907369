import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Layout from "../components/Layout";
import Button from "components/Button";
import TextInput from "components/PTABDecisions/components/TextInput";
import {
  DictionaryItemType,
  Gap,
} from "components/PTABDecisions/components/utils";
import styles from "./module.sass";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import {
  ApplicationLookupType,
  DecisionMainTypes,
  fetchOutcomeOptions,
  findDecisionId,
  RecapType,
} from "domain/ptabAdmin";
import { format } from "domain/dates";
import Table from "components/Table";
import Link from "components/Link";
import useDocumentTitle from "hooks/useDocumentTitle";
import { PAGE_TITLE, useScrollToTop } from "../utils";
import Text from "components/Text";

const RecapCell = ({
  outcomeOptions,
  data,
}: {
  outcomeOptions: Array<DictionaryItemType>;
  data: RecapType;
}) => {
  const { issue, issue_tags, claims_of_issue, disposition } = data;
  const dispositionLabel: string | undefined = outcomeOptions.find(
    (option) => option.value === disposition
  )?.label;
  const tags = issue_tags?.map((tag) => (
    <>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {`• ${tag}`}
    </>
  ));

  return (
    <Text>
      {`${
        claims_of_issue && claims_of_issue?.length > 0
          ? `Claims ${claims_of_issue} under `
          : ""
      }${issue}${
        dispositionLabel && dispositionLabel?.length > 0
          ? `: ${dispositionLabel}`
          : ""
      }`}
      {tags}
    </Text>
  );
};

export const useOutcomeOptions = () => {
  const [outcomeOptions, setOutcomeOptions] = useState<
    Array<DictionaryItemType>
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetchOutcomeOptions()
      .then(setOutcomeOptions)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const findOutcomeOption = useCallback(
    (disposition?: string) => {
      if (disposition && outcomeOptions?.length > 0) {
        return outcomeOptions.find((x) => x.value === disposition);
      } else {
        return null;
      }
    },
    [outcomeOptions]
  );

  return { outcomeOptions, findOutcomeOption, isLoading };
};

const ApplicationLookupPage = () => {
  useDocumentTitle(PAGE_TITLE);
  useScrollToTop();

  const history = useHistory();

  const [applicationNumberValue, setApplicationNumberValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [data, setData] = useState<Array<ApplicationLookupType>>([]);

  const { outcomeOptions } = useOutcomeOptions();

  const searchForApplication = () => {
    setIsLoading(true);
    setResult("");

    findDecisionId(applicationNumberValue)
      ?.then((res: Array<ApplicationLookupType>) => {
        if (res?.length > 1) {
          setData(res);
        } else if (res?.length === 1) {
          history.push({
            pathname: "/decision",
            search: new URLSearchParams({
              decisionId: res[0].id.toString(),
            }).toString(),
          });
        } else {
          setData([]);
          setResult("No results found");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns: Column<ApplicationLookupType>[] = useMemo(
    () => [
      {
        Header: "Case Name",
        accessor: "case_name",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const applicationLookup: ApplicationLookupType = row.original;
          return (
            <Link href={`/#decision?decisionId=${applicationLookup.id}`} dark>
              {value}
            </Link>
          );
        },
      },
      {
        Header: "Recap",
        accessor: "recap",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const applicationLookup: ApplicationLookupType = row.original;
          return applicationLookup.recap?.map((recapData, index) => (
            <>
              {index > 0 && (
                <>
                  <br />
                  <br />
                </>
              )}
              <RecapCell data={recapData} outcomeOptions={outcomeOptions} />
            </>
          ));
        },
      },
      {
        Header: "Application #",
        accessor: "application_number",
      },
      {
        Header: "Decision Date",
        accessor: "decision_date",
        Cell: ({ value, row }: { value: string; row: any }) => {
          return !value ? "" : format(new Date(value), "mm/dd/yyyy").toString();
        },
      },
      {
        Header: "Appeal #",
        accessor: "appeal_number",
      },
      {
        Header: "Type",
        accessor: "bigtype",
        Cell: ({ value, row }: { value: string; row: any }) => {
          return (
            DecisionMainTypes.find((type) => type.value === value)?.label ??
            value
          );
        },
      },
      {
        Header: "Art Unit",
        accessor: "art_unit",
      },
    ],
    [outcomeOptions]
  );

  const table = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 100,
        sortBy: useMemo(
          () => [
            {
              id: "decision_date",
              desc: false,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <Layout
      isApplicationLookupActive
      className={styles.layoutContent}
      isLoading={isLoading}
    >
      <div className={styles.content}>
        <p className={styles.titleText}>Application Lookup</p>

        <Gap vertical size={28} />

        <div className={styles.search}>
          <TextInput
            value={applicationNumberValue}
            onChange={setApplicationNumberValue}
            onEnterClick={searchForApplication}
            placeholder={"Enter application number"}
            className={styles.applicationNumberInput}
          />
          <Gap size={8} />
          <Button
            variant={"contained"}
            size={"sm"}
            onClick={searchForApplication}
            className={styles.button}
          >
            Search
          </Button>
        </div>

        <Gap vertical size={28} />

        {result && (
          <Text className={styles.noResultPlaceholderText}>{result}</Text>
        )}
      </div>

      {data?.length > 0 && (
        <>
          <Table table={table} isPaginationHidden />
          <Gap vertical size={60} />
        </>
      )}
    </Layout>
  );
};

export default ApplicationLookupPage;
