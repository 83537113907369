import React from "react";

const Calendar = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.2 24.2"
    width={24}
    height={24}
    className={className}
  >
    <path d="M19.1 3v1.2H23v4.9H1.2V4.2h4.2V3H0v21.2h24.2V3zM5.4 22.9H1.2v-5.4h4.2zm0-6.5H1.2v-6.1h4.2zm6.1 6.5H6.7v-5.4h4.8zm0-6.6H6.7v-6.1h4.8zm5.7 6.7h-4.5v-5.4h4.5zm0-6.6h-4.5v-6.1h4.5zM23 23h-4.5v-5.4H23zm0-6.6h-4.5v-6.1H23z" />
    <path d="M6.4 0h1.2v6.1H6.4zM16.9 0h1.2v6.1h-1.2zM9.1 3h6.4v1.2H9.1z" />
  </svg>
);

export default Calendar;
