import React, { SVGProps } from "react";

export default function EyeCrossed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={21}
      viewBox="0 0 24 21"
      fill="#cdcdcd"
      {...props}
    >
      <path d="M16.525 0l-2.3 3.9c-.7-.1-1.5-.2-2.2-.2-4.6 0-8.7 2.5-11.8 6.4-.3.3-.3.8 0 1.1 1.9 2.4 4.1 4.3 6.7 5.4l-1.9 3.3 1.9 1.1 11.5-19.9zm-7.6 13.1c-.5-.7-.9-1.5-.9-2.4 0-2.2 1.8-4 4-4 .2 0 .4 0 .5.1zM23.925 10.1c-1.6-2.1-3.5-3.7-5.6-4.8l-2.4 4.2c.1.4.2.7.2 1.1 0 1.9-1.3 3.5-3.1 3.9l-1.8 3.1h.9c4.6 0 8.7-2.5 11.8-6.4.2-.3.2-.7 0-1.1z" />
    </svg>
  );
}
