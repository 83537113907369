import { useEffect } from "react";

const PAGE_TITLE = "PTAB Decision Review - PatentAdvisor®";

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export { PAGE_TITLE, useScrollToTop };
