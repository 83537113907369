import React, {
  useState,
  FocusEventHandler,
  useRef,
  KeyboardEventHandler,
  useEffect,
  ReactNode,
  CSSProperties,
} from "react";
import cn from "classnames";

import useClickOutside from "hooks/useClickOutside";
import SelectDropdown, { Size } from "molecules/SelectDropdown";
import InputLabel from "atoms/InputLabel";
import InputError from "atoms/InputError";
import InputPlaceholder from "atoms/InputPlaceholder";
import { Option } from "atoms/SelectOption";
import { SmallChevron } from "components/Icons";

import styles from "./module.sass";

export type Props<V extends string = string> = {
  value: V | null;
  options: Option<V>[];
  label: ReactNode;
  onChange: (value: V) => void;

  placeholder?: string;
  error?: string;
  direction?: Direction;
  size?: Size;
  onBlur?: FocusEventHandler<HTMLDivElement>;
};

export type Direction = "up" | "down";

function SimpleSelect<V extends string = string>({
  size = "default",
  value,
  options,
  label,
  onChange,
  direction = "down",
  placeholder = "Please select",
  error = "",
  onBlur = () => {},
}: Props<V>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchBuffer, setSearchBuffer] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const selectedOption = options.find((o) => o.value === value);

  useClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    if (!searchBuffer.length) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setSearchBuffer("");
    }, 1000);

    const option = options.find((o) =>
      o.searchableLabel
        ?.replace(/\s/g, "")
        .toLowerCase()
        .startsWith(searchBuffer)
    );
    if (!option?.ref?.current) {
      return;
    }
    option.ref.current.scrollIntoView();
    option.ref.current.focus();

    return () => clearTimeout(timeoutId);
  }, [searchBuffer]);

  const selectOption = (o: Option<V>) => {
    triggerRef.current?.focus();
    setIsOpen(false);
    onChange(o.value);
  };

  const handleBlur: FocusEventHandler<HTMLDivElement> = (e) => {
    const isFocusInsideHierarchy =
      e.relatedTarget instanceof Node &&
      e.currentTarget.contains(e.relatedTarget);
    if (isFocusInsideHierarchy) {
      e.stopPropagation();
      return;
    }
    onBlur(e);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (!isOpen) {
      return;
    }

    const key = e.key.toLowerCase();
    const isLetter = key >= "a" && key <= "z";
    if (!isLetter) {
      return;
    }

    setSearchBuffer((buffer) => buffer + key);
  };

  return (
    <div
      ref={ref}
      className={cn(
        styles.simpleSelect,
        error && styles.simpleSelect$error,
        isOpen && styles.simpleSelect$open,
        size === "small" && styles.simpleSelect$small
      )}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      <InputLabel>{label}</InputLabel>
      <button
        ref={triggerRef}
        type="button"
        className={cn(
          styles.trigger,
          isOpen && styles.trigger$open,
          size === "small" && styles.trigger$small
        )}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {selectedOption?.label ? (
          <span
            className={cn(styles.label, size === "small" && styles.label$small)}
          >
            {selectedOption.label}
          </span>
        ) : (
          <InputPlaceholder>{placeholder}</InputPlaceholder>
        )}
        <SmallChevron
          width={11}
          height={6}
          direction={isOpen ? "up" : "down"}
          style={{ marginBottom: -1 }}
          className={styles.chevron}
        />
      </button>
      {isOpen && (
        <SelectDropdown
          options={options}
          onSelect={selectOption}
          selectedOption={selectedOption}
          size={size}
          topMargin={getTopMargin(size, label)}
          style={getStyle(direction, size)}
        />
      )}
      {error ? <InputError>{error}</InputError> : null}
    </div>
  );
}

export default SimpleSelect;

function getStyle(direction: Direction, size: Size): CSSProperties {
  if (direction === "down") {
    return {};
  }
  return {
    transform: `translateY(-100%) translateY(${
      size === "small" ? "-32px" : "-40px"
    })`,
  };
}

function getTopMargin(size: Size, label: Props["label"]) {
  if (size === "small") {
    return 32;
  }
  return label ? 64 : 46;
}
