import { Analytics, Event, User } from "./factory";

export class LoggingAnalytics implements Analytics {
  private log = console.log;

  public recordEvent(event: Event) {
    this.log("[Analytics] Recorded event:", event);
  }

  public recordPageView(url: string, name: string) {
    this.log("[Analytics] Recorded page view:", { url, name });
  }

  public setCurrentUser(user: User) {
    this.log("[Analytics] Set current user:", user);
  }

  public removeCurrentUser() {
    this.log("[Analytics] Removed current user.");
  }

  public setLog(log: typeof console.log) {
    this.log = log;
  }
}
