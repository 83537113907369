import React, { useEffect, ReactNode } from "react";
import { useTable, useSortBy, usePagination, CellProps } from "react-table";

import { Citation, LicensingReport } from "domain/licensingReports";
import { linkToAssigneeStatistics, nullAssignee } from "domain/assignees";
import { default as BaseTable, ExtendedColumn } from "components/Table";
import Anchor from "atoms/Anchor";
import { format as formatDate } from "domain/dates";
import { capitalize } from "domain/strings";
import {
  linkToApplicationDocument,
  linkToQuickPAIR,
} from "domain/applications";
import { PdfIcon } from "components/Icons";
import colors from "style/colors.module.scss";

import {
  sortByCitationDate,
  citationDateSortOrder,
  sortByRejectedAssignee,
  sortByRejectionDocument,
  sortByRejectedReadableApplicationNumber,
} from "./sorting";
import { Dropdown } from "./Dropdown";
import NoResults from "./NoResults";

export interface Props {
  licensingReport: LicensingReport;
  setCountText: (countText: ReactNode) => void;
}

export default function UngroupedTable({
  licensingReport,
  setCountText,
}: Props) {
  useEffect(() => {
    setCountText(
      <>
        Displaying <b>{licensingReport.citations.length.toLocaleString()}</b>{" "}
        Citations
      </>
    );
  }, [licensingReport.citations.length]);

  const table = useTable(
    {
      columns,
      data: licensingReport.citations,
      disableSortRemove: true,
      initialState: {
        sortBy: citationDateSortOrder,
        pageSize: 25,
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <BaseTable
      table={table}
      colorScheme="white"
      emptyMessage={
        <NoResults colSpan={9}>
          No citations were found. Try increasing the citation date range on the{" "}
          <Anchor href="/app.php#licensing-reports/setup?reuseLastSearch=true">
            previous screen.
          </Anchor>
        </NoResults>
      }
    />
  );
}

const columns: ExtendedColumn<Citation>[] = [
  {
    id: "citedApplication.assignee",
    accessor: (citation) => citation.citedApplication.assignee,
    Header: "Cited Assignee",
    addCellProps: () => ({ style: { width: 140 } }),
  },
  {
    id: "citedApplication.citedDocument",
    sortDescFirst: true,
    accessor: (citation) => citation.citedApplication.citedDocument,
    Header: "Cited Document",
    addCellProps: () => ({ style: { width: 140 } }),
    Cell: ({
      row: {
        original: {
          citedApplication: { citedDocument, applicationNumber },
        },
      },
      currentRowIndex,
    }: CellProps<Citation>) => (
      <Dropdown
        detailsUrl={linkToQuickPAIR(applicationNumber)}
        pdfUrl={linkToApplicationDocument(applicationNumber)}
        isCaretVisible={true}
      >
        {citedDocument}
      </Dropdown>
    ),
  },
  {
    id: "citedApplication.title",
    accessor: (citation) => citation.citedApplication.title,
    Header: "Title",
    addCellProps: (cell) => ({
      title: cell.value,
      style: {
        width: 290,
        maxWidth: 390,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    }),
  },
  {
    id: "rejectedApplication.assignee",
    accessor: (citation) => citation.rejectedApplication.assignee,
    sortType: sortByRejectedAssignee,
    additionalHeaderProps: {
      style: { borderLeft: `1px solid ${colors.blueGray400}` },
    },
    Header: "Rejected Assignee",
    addCellProps: () => ({
      style: { width: 140, borderLeft: `1px solid ${colors.blueGray400}` },
    }),
    Cell: ({ value: assignee }: CellProps<Citation>) =>
      assignee ? (
        <Anchor href={linkToAssigneeStatistics(assignee)} target="_blank">
          {assignee.name}
        </Anchor>
      ) : (
        nullAssignee.name
      ),
  },
  {
    id: "rejectedApplication.applicationNumber",
    accessor: (citation) => citation.rejectedApplication.applicationNumber,
    sortType: sortByRejectedReadableApplicationNumber,
    sortDescFirst: true,
    Header: (
      <>
        Rejected
        <br />
        Application
      </>
    ),
    addCellProps: () => ({ style: { width: 100 } }),
    Cell: ({
      row: {
        original: {
          rejectedApplication: { applicationNumber, readableApplicationNumber },
        },
      },
    }: CellProps<Citation>) => (
      <Dropdown
        detailsUrl={linkToQuickPAIR(applicationNumber)}
        pdfUrl={linkToApplicationDocument(applicationNumber)}
      >
        {readableApplicationNumber}
      </Dropdown>
    ),
  },
  {
    id: "rejectedApplication.status",
    accessor: (citation) => citation.rejectedApplication.status,
    Header: (
      <>
        Rejected
        <br />
        App Status
      </>
    ),
    addCellProps: () => ({ style: { width: 100 } }),
    Cell: ({ value: status }: CellProps<Citation>) => capitalize(status),
  },
  {
    accessor: "rejectionType",
    Header: (
      <>
        Rejection
        <br />
        Type
      </>
    ),
    addCellProps: () => ({ style: { width: 100 } }),
  },
  {
    accessor: "rejectionDocumentUrl",
    sortType: sortByRejectionDocument,
    Header: (
      <>
        Rejection
        <br />
        Document
      </>
    ),
    addCellProps: () => ({ style: { width: 120 } }),
    Cell: ({
      row: {
        original: { rejectionDocumentUrl, isRejectionFinal },
      },
    }) => (
      <Anchor
        href={rejectionDocumentUrl}
        target="_blank"
        style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}
      >
        {isRejectionFinal ? "Final" : "Non-Final"}{" "}
        <PdfIcon
          width={20}
          height={20}
          style={{ marginLeft: 4 }}
          hasHoverState={false}
        />
      </Anchor>
    ),
  },
  {
    id: "citationDate",
    accessor: "citationDate",
    sortType: sortByCitationDate,
    sortDescFirst: true,
    Header: (
      <>
        Mail Room
        <br />
        Date
      </>
    ),
    addCellProps: () => ({ style: { width: 120, whiteSpace: "nowrap" } }),
    Cell: ({ value }) => formatDate(value, "Y-m-d"),
  },
];
