import axios from "axios";
import Chance from "chance";
import ReleaseNote from "molecules/ReleaseNote";

type APIReleaseNoteItem = {
  description: string;
  type: "product update" | "bug fix";
};

type APIReleaseNote = {
  id: number;
  creationDate: string;
  releaseDate: string;
  overview: string;
  items: APIReleaseNoteItem[];
};

export type ReleaseNote = {
  id: number;
  creationDate: Date;
  releaseDate: Date;
  overview: string;
  productUpdates: string[];
  bugFixes: string[];
};

export function findLatest(): Promise<ReleaseNote[]> {
  return axios
    .get<APIReleaseNote[]>("/api/v2/release-notes.php", {
      params: {
        action: "findLatest",
      },
    })
    .then((response) => {
      return response.data
        .map(createFromAPIReleaseNote)
        .sort((a, b) => (a.releaseDate > b.releaseDate ? -1 : 1));
    });
}

export function findAll(): Promise<ReleaseNote[]> {
  return axios
    .get<APIReleaseNote[]>("/api/v2/release-notes.php", {
      params: {
        action: "findAll",
      },
    })
    .then((response) => {
      return response.data
        .map(createFromAPIReleaseNote)
        .sort((a, b) => (a.creationDate > b.creationDate ? -1 : 1));
    });
}

export function deleteSingle(id: number): Promise<ReleaseNote> {
  return axios
    .post<APIReleaseNote>("/api/v2/release-notes.php", {
      action: "delete",
      id,
    })
    .then((response) => {
      return createFromAPIReleaseNote(response.data);
    });
}

export function create({
  releaseDate,
  overview,
  productUpdates,
  bugFixes,
}: Omit<ReleaseNote, "id" | "creationDate">): Promise<ReleaseNote> {
  return axios
    .post<APIReleaseNote>("/api/v2/release-notes.php", {
      action: "create",
      releaseDate: releaseDate.toISOString(),
      overview,
      items: [
        ...productUpdates.map((productUpdate) => {
          return {
            type: "product update",
            description: productUpdate,
          };
        }),
        ...bugFixes.map((bugFix) => {
          return {
            type: "bug fix",
            description: bugFix,
          };
        }),
      ],
    })
    .then((response) => {
      return createFromAPIReleaseNote(response.data);
    });
}

export function edit({
  id,
  creationDate,
  overview,
  releaseDate,
  productUpdates,
  bugFixes,
}: ReleaseNote): Promise<ReleaseNote> {
  return axios
    .post<APIReleaseNote>("/api/v2/release-notes.php", {
      action: "edit",
      id: id,
      creationDate: creationDate.toISOString(),
      releaseDate: releaseDate.toISOString(),
      overview,
      items: [
        ...productUpdates.map((productUpdate) => {
          return {
            type: "product update",
            description: productUpdate,
          };
        }),
        ...bugFixes.map((bugFix) => {
          return {
            type: "bug fix",
            description: bugFix,
          };
        }),
      ],
    })
    .then((response) => {
      return createFromAPIReleaseNote(response.data);
    });
}

export function generateFake(seed = ""): ReleaseNote {
  const chance = seed ? new Chance(seed) : new Chance();

  return {
    id: chance.integer(),
    creationDate: chance.date(),
    releaseDate: chance.date(),
    overview: chance.paragraph(),
    productUpdates: [...Array(3)].map((_) => chance.sentence()),
    bugFixes: [...Array(3)].map((_) => chance.sentence()),
  };
}

function createFromAPIReleaseNote(apiReleaseNote: APIReleaseNote): ReleaseNote {
  return {
    id: apiReleaseNote.id,
    overview: apiReleaseNote.overview,
    releaseDate: new Date(apiReleaseNote.releaseDate),
    creationDate: new Date(apiReleaseNote.creationDate),
    productUpdates: apiReleaseNote.items
      .filter((i) => i.type === "product update")
      .map((i) => i.description),
    bugFixes: apiReleaseNote.items
      .filter((i) => i.type === "bug fix")
      .map((i) => i.description),
  };
}
