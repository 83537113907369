export interface Assignee {
  id: string;
  name: string;

  nestedAssignees?: Assignee[];
}

export interface AssigneeWithCitationCount extends Assignee {
  citationCount: number;

  nestedAssignees?: AssigneeWithCitationCount[];
}

export const nullAssignee: Assignee = {
  id: "nullAssignee",
  name: "UNASSIGNED",
};

export const isNullAssignee = (assignee: Assignee) =>
  assignee.id === "nullAssignee";

export function linkToAssigneeStatistics(assignee: Assignee): string {
  if (isNullAssignee(assignee)) {
    console.warn(
      `Trying to link to the statistics page of a \`nullAssignee\`.`
    );
    return "/";
  }
  return `/statistics.php?Parent=AssignmentParty&AssignmentParty=${assignee.id}`;
}
