import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";
import Button from "components/Button";
import Text from "components/Text";
import cn from "classnames";
import styles from "./module.sass";

const Title = ({
  children,
  style,
}: {
  children?: ReactNode;
  style?: CSSProperties;
}) => (
  <Text className={styles.titleText} style={style}>
    {children}
  </Text>
);

const SmallButton = ({
  children,
  onClick,
  className,
}: {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant={"contained"}
      size={"xxs"}
    >
      {children}
    </Button>
  );
};

const Partition = ({
  id,
  title,
  children,
  overlay,
  style,
}: {
  id: any;
  title?: string;
  children: any;
  overlay?: any;
  style?: CSSProperties;
}) => {
  return (
    <>
      {!!title && <Title>{title}</Title>}
      <div
        className={cn(styles.root, !!overlay && styles.highlightOnHover)}
        key={`id:${id}`}
        style={style}
      >
        {children}
        {!!overlay && <div className={styles.overlay}>{overlay}</div>}
      </div>
    </>
  );
};

export { Title, SmallButton, Partition };
