import axios, { AxiosResponse } from "axios";

import { CancellablePromise } from "domain/promise";

export type BaseResponse = {
  status: string;
  message: string;
};

export type APIArtUnit = {
  id: number;
  name: string;
};

export function searchArtUnits(nameFragment: string) {
  type Response = BaseResponse & { data: APIArtUnit[] };

  const cancelTokenSource = axios.CancelToken.source();

  const params = new URLSearchParams({
    function: "search_art_unit_names",
    name_fragment: nameFragment,
    limit_results: "100",
  });

  const promise = axios.post<Response>("/api/anticipat_api.php", params, {
    cancelToken: cancelTokenSource.token,
  });
  (promise as CancellablePromise<AxiosResponse<Response>>).cancel = () => {
    cancelTokenSource.cancel();
  };

  return promise as CancellablePromise<AxiosResponse<Response>>;
}
