import React, { ReactNode, useRef, useState } from "react";

import useClickOutside from "hooks/useClickOutside";
import { LinkOption } from "atoms/SelectOption";
import Button from "components/Button";
import { SmallChevron, PdfIcon } from "components/Icons";
import { BaseDropdown } from "molecules/SelectDropdown";

import styles from "./Dropdown.module.sass";

export interface DropdownProps {
  children: ReactNode;
  detailsUrl: string;
  pdfUrl: string;

  isCaretVisible?: boolean;
}

export function Dropdown({
  children: label,
  detailsUrl,
  pdfUrl,
  isCaretVisible = true,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref}>
      <Button
        variant="link"
        style={{
          padding: 0,
          height: "auto",
          fontSize: 15,
          fontWeight: "normal",
        }}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {label}
        {isCaretVisible && (
          <SmallChevron
            width={11}
            height={6}
            direction={isOpen ? "up" : "down"}
            className={styles.chevron}
          />
        )}
      </Button>
      {isOpen && (
        <BaseDropdown style={{ width: 170 }}>
          <LinkOption
            href={detailsUrl}
            target="_blank"
            style={{ fontSize: 14 }}
          >
            View in QuickPAIR
          </LinkOption>
          <LinkOption
            href={pdfUrl}
            target="_blank"
            style={{ display: "flex", alignItems: "center", fontSize: 14 }}
          >
            View PDF{" "}
            <PdfIcon
              width={20}
              height={20}
              hasHoverState={false}
              style={{ marginLeft: 4 }}
            />
          </LinkOption>
        </BaseDropdown>
      )}
    </div>
  );
}
