import React from "react";
import { useQuery } from "react-query";

import { getDecision } from "domain/ptab";

export default function useDecision(id: number) {
  const { status, error, data: decision } = useQuery(
    ["decision", id],
    ({ queryKey }) => {
      const [name, id] = queryKey;
      return getDecision(Number(id));
    },
    {
      retry: (failureCount, error: any) => {
        if (error?.response?.status === 404) {
          return false;
        }
        return failureCount >= 3;
      },
    }
  );

  return { status, error, decision };
}
