import React, { CSSProperties, FormEvent, useEffect, useState } from "react";
import useBrowserExtensionSettings from "hooks/useBrowserExtensionSettings";
import { setBrowserExtensionSettings } from "domain/browserExtension";
import PageTitle from "components/PageTitle";
import Anchor from "atoms/Anchor";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { About } from "./Copy";
import SupportList from "./SupportList";
import Notification from "atoms/Notification";
import styles from "./module.sass";

export type WithExtensionProps = {
  computerImage: string;
};

const SettingsWithExtension = ({ computerImage }: WithExtensionProps) => {
  const { browserExtensionSettings } = useBrowserExtensionSettings();
  const isPassiveLoginEnabled = browserExtensionSettings?.data.passiveLogin;
  const isExtensionEnabled = browserExtensionSettings?.data.extensionEnabled;

  const [isAutoLoginEnabled, setIsAutoLoginEnabled] = useState(false);
  const [isPluginEnabled, setIsPluginEnabled] = useState(false);
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsAutoLoginEnabled(
      isPassiveLoginEnabled ? isPassiveLoginEnabled : false
    );
    setIsPluginEnabled(isExtensionEnabled ? isExtensionEnabled : false);
  }, [isPassiveLoginEnabled, isExtensionEnabled]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setBrowserExtensionSettings({
      isExtensionEnabled: isPluginEnabled,
      isPassiveLogin: isAutoLoginEnabled,
    });
    setIsNotificationShown(true);
    setIsDisabled(true);
  };

  const handleFormNotification = () => {
    setIsNotificationShown(!isNotificationShown);
  };

  const handleInstallationStatusNotification = () => {
    document
      .getElementsByClassName("browser-ext__msg-installed")[0]
      .classList.add("hidden");
  };

  return (
    <>
      <div className="hidden browser-ext__msg-installed">
        <Notification
          variant="success"
          children="The PAIR Browser Extension was successfully installed."
          onClick={handleInstallationStatusNotification}
          style={{ marginBottom: 0 }}
        />
      </div>

      <PageTitle>Browser Extension for Patent Center</PageTitle>

      <main className={styles.grid}>
        <div>
          <div className={styles.headingNote}>
            Easily access all of the patent prosecution analytics that you rely
            on directly in Patent Center.
          </div>
          <div className={styles.buttonsContainer}>
            <Anchor
              href="https://patentcenter.uspto.gov/#!/"
              target="_blank"
              style={{
                textDecoration: "none",
                display: "inline-block",
                width: "fit-content",
              }}
            >
              <Button variant="contained" size="sm">
                Visit Patent Center
              </Button>
            </Anchor>
          </div>
          <div className={styles.infoContainer}>
            <img
              className={styles.image}
              src={computerImage}
              alt="Public PAIR"
              style={{ marginTop: 50 }}
            />
            <div className={styles.listContainer}>
              Use the PatentAdvisor Extension to access:
              <br />
              <About />
            </div>
          </div>
        </div>

        <div className={styles.formContainer}>
          <h2 className={styles.h2}>Your PatentAdvisor Extension Settings</h2>
          <Divider style={{ margin: "25px 0 20px" }} />
          <form onSubmit={handleSubmit}>
            <div className={styles.formGrid}>
              <Checkbox
                label="Auto Login"
                isChecked={isAutoLoginEnabled}
                onChange={() => {
                  setIsAutoLoginEnabled(!isAutoLoginEnabled);
                  setIsDisabled(false);
                }}
                isBold={true}
              />
              <p style={{ margin: 0 }}>
                Select this to remain logged in to PatentAdvisor until you log
                out. This activates
                <br />
                and enables the extension. If you log out of PatentAdvisor, and
                then use Patent
                <br />
                Center, you will be asked for your PatentAdvisor credentials.
                <br />
                We do not recommend using this setting on a public computer.
              </p>
            </div>
            <Divider style={{ margin: "17px 0 20px" }} />
            <div className={styles.formGrid}>
              <Checkbox
                label="Enable"
                isChecked={isPluginEnabled}
                onChange={() => {
                  setIsPluginEnabled(!isPluginEnabled);
                  setIsDisabled(false);
                }}
                isBold={true}
              />
              <p style={{ margin: "0" }}>
                Click to enable or disable the extension.
              </p>
            </div>
            <Divider style={{ margin: "20px 0" }} />
            <div style={{ marginTop: 38 }}>
              <Button
                variant="brand"
                size="sm"
                type="submit"
                isDisabled={isDisabled}
              >
                Save
              </Button>
              {isNotificationShown ? (
                <Notification
                  variant="success"
                  children="Your PatentAdvisor Extension Settings have been saved."
                  onClick={handleFormNotification}
                />
              ) : (
                <div style={{ height: 96 }}></div>
              )}
            </div>
          </form>
          <div className={styles.footerLinks} style={{ paddingTop: 25 }}>
            <SupportList hasBrowserExtension={true} />
          </div>
        </div>
      </main>
    </>
  );
};

export default SettingsWithExtension;

const Divider = ({ style }: { style: CSSProperties }) => (
  <div className={styles.divider} style={style}></div>
);
