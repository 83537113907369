import React, { useEffect, useMemo, useState } from "react";
import Table from "components/Table";
import { useTable, usePagination, Column, useSortBy } from "react-table";
import Layout from "../components/Layout";
import styles from "./module.sass";
import { useHistory, useLocation } from "react-router";
import {
  fetchDailyReviews,
  DailyReviewStatusTypes,
  DailyReviewType,
  DecisionTypes,
  DecisionMainTypes,
} from "domain/ptabAdmin";
import queryString from "query-string";
import { Gap, MONTHS } from "components/PTABDecisions/components/utils";
import Link from "components/Link";
import { format } from "domain/dates";
import useDocumentTitle from "hooks/useDocumentTitle";
import { PAGE_TITLE, useScrollToTop } from "../utils";
import Text from "components/Text";

const getStatusColor = (status: DailyReviewStatusTypes): string => {
  switch (status?.toLowerCase()) {
    case DailyReviewStatusTypes.Failed:
      return "rgb(228, 57, 53)";
    case DailyReviewStatusTypes.Reviewed:
      return "rgb(95, 193, 23)";
    case DailyReviewStatusTypes.Pending:
      return "rgb(229, 163, 10)";
  }
  return "rgb(33, 33, 33)";
};

const getStatusLabel = (status: DailyReviewStatusTypes): string => {
  switch (status?.toLowerCase()) {
    case DailyReviewStatusTypes.Failed:
      return "Failed";
    case DailyReviewStatusTypes.Reviewed:
      return "Reviewed";
    case DailyReviewStatusTypes.Pending:
      return "Pending";
  }
  return status;
};

export type ParamsType = {
  date?: string;
};

const DailyReviewPage = () => {
  useDocumentTitle(PAGE_TITLE);
  useScrollToTop();

  const history = useHistory();
  const location = useLocation();

  const { date: _date }: ParamsType = queryString.parse(location.search);
  const date = _date ? new Date(_date) : new Date();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<Array<DailyReviewType>>([]);

  useEffect(() => {
    setIsLoading(true);

    fetchDailyReviews({ decisionDate: _date })
      .then((res) => {
        setData(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history]);

  const columns: Column<DailyReviewType>[] = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }: { value: DailyReviewStatusTypes; row: any }) => (
          <p
            key={value}
            style={{ color: getStatusColor(value), padding: 0, margin: 0 }}
          >
            {getStatusLabel(value)}
          </p>
        ),
      },
      {
        Header: "Import Date",
        accessor: "import_date",
        Cell: ({ value }: { value: string }) => value.substr(0, 10),
      },
      {
        Header: "Decision Date",
        accessor: "decision_date",
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value, row }: { value: string; row: any }) => {
          const original: DailyReviewType = row.original;

          return `${
            DecisionMainTypes.find((x) => x.value === original.bigtype)?.label
          } ${DecisionTypes?.find((x) => x.value === original.type)?.label}`;
        },
      },
      {
        Header: "Application #",
        accessor: "application_number",
      },
      {
        Header: "Case Name",
        accessor: "case_name",
      },
      {
        Header: "Unknown Outcome",
        accessor: "unknown_outcome",
        Cell: ({ value, row }: { value: boolean; row: any }) => (
          <Text className={value ? styles.redText : undefined}>
            {value ? "Yes" : "-"}
          </Text>
        ),
      },
      {
        Header: "No Issues",
        accessor: "no_issues",
        Cell: ({ value, row }: { value: boolean; row: any }) => (
          <Text className={value ? styles.redText : undefined}>
            {value ? "Yes" : "-"}
          </Text>
        ),
      },
      {
        Header: "Errors",
        accessor: "errors",
      },
      {
        Header: "Actions",
        Cell: ({ value, row }: { value: any; row: any }) => {
          const original: DailyReviewType = row.original;
          return (
            <Link
              href={`/#decision?decisionId=${original.decision_id}`}
              isFluid
              className={styles.editLink}
            >
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const table = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 100,
        sortBy: useMemo(
          () => [
            {
              id: "decision_date",
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <Layout
      title={`${
        MONTHS[date.getMonth()]
      } ${date.getUTCDate()}, ${date.getFullYear()}`}
      isLoading={isLoading}
      header={
        <>
          <Link
            href={`/#monthly-review?month=${
              MONTHS[date.getMonth()]
            }&year=${date.getFullYear()}`}
            dark
          >
            Back to Monthly Review
          </Link>
          <Gap vertical size={8} />
        </>
      }
    >
      <Table table={table} isPaginationHidden />
      <Gap vertical size={60} />
    </Layout>
  );
};

export default DailyReviewPage;
