import axios from "axios";
import Chance from "chance";

import { format as formatDate } from "domain/dates";

export enum VerificationStatus {
  Expired = 3,
}

export type TrialRequest = {
  firstName: string;
  lastName: string;
  companyHq: string;
  companyHqState: string;
  companyHqProvince: string;
  companyHqPostalCode: string;
  businessEmail: string;
  password: string;
  isNewsletterEnabled: boolean;
  businessPhone: string;
  companyName: string;
  jobTitle: string;
  personLinkedin: string;
  revenueRange: string;
  managementLevel: string;
  primaryIndustry: string;
  companyEmployees: string;
  companyWebsite: string;
  companyCountry: string;
  companyState: string;
  conversionPage: string;
};

export type TrialResponse = {};

export type SubmittedTrialRequest = {
  id: number;
  name: string;
  company: string;
  email: string;
  dateRequested: Date;
  status: "pending" | "approved" | "rejected" | "hidden";
  dateReviewed: Date | null;
  note: string;
  reviewer: string;
};

export function submitRequest(request: TrialRequest) {
  return axios.post<TrialResponse>(
    "/api/v2/free-trial.php",
    new URLSearchParams({
      ...request,
      isNewsletterEnabled: String(request.isNewsletterEnabled),
    })
  );
}

export function resendEmailVerification(email: string) {
  return axios.post(
    "/api/v2/resend-email-verification.php",
    new URLSearchParams({
      email,
    })
  );
}

export function findAllTrialRequests(): Promise<SubmittedTrialRequest[]> {
  type APISubmittedTrialRequest = SubmittedTrialRequest & {
    dateRequested: string;
    dateReviewed: string;
  };

  return axios
    .get<APISubmittedTrialRequest[]>("/api/v2/trial-requests.php")
    .then((response) =>
      response.data.map((apiRequest) => {
        return {
          ...apiRequest,
          dateRequested: new Date(apiRequest.dateRequested),
          dateReviewed: apiRequest.dateReviewed
            ? new Date(apiRequest.dateReviewed)
            : null,
        };
      })
    );
}

export function approveTrialRequest({
  trialRequest,
  note,
}: {
  trialRequest: SubmittedTrialRequest;
  note: string;
}) {
  return axios.post("/api/v2/review-trial-request.php", {
    action: "approve",
    id: trialRequest.id,
    note,
  });
}

export function rejectTrialRequest({
  trialRequest,
  note,
}: {
  trialRequest: SubmittedTrialRequest;
  note: string;
}) {
  return axios.post("/api/v2/review-trial-request.php", {
    action: "reject",
    id: trialRequest.id,
    note,
  });
}

export function hideTrialRequest({
  trialRequest,
  note,
}: {
  trialRequest: SubmittedTrialRequest;
  note: string;
}) {
  return axios.post("/api/v2/review-trial-request.php", {
    action: "hide",
    id: trialRequest.id,
    note,
  });
}

export function formatAsCSV(trialRequests: SubmittedTrialRequest[]): string {
  const csvSeparatorRow = "Sep=,\r\n";
  const csvHeaderRow = [
    "Name",
    "Company",
    "Email",
    "Date of Request",
    "Date of Review",
    "Notes",
    "Status",
    "Reviewed By",
  ]
    .join(",")
    .concat("\r\n");
  const csvRecords = trialRequests
    .map((r) => {
      const dateRequested = formatDate(r.dateRequested, "Y-m-d H:M");
      const dateReviewed = r.dateReviewed
        ? formatDate(r.dateReviewed, "Y-m-d")
        : "-";
      const note = r.note ? r.note : "-";

      return [
        r.name,
        r.company,
        r.email,
        dateRequested,
        dateReviewed,
        note,
        r.status,
        r.reviewer,
      ];
    })
    .map((values) => values.map((v) => `"${v.replace('"', '""')}"`))
    .map((values) => values.join(","))
    .join("\r\n");

  return csvSeparatorRow + csvHeaderRow + csvRecords;
}

export function fakeSubmittedTrialRequest(seed: any): SubmittedTrialRequest {
  const chance = new Chance(seed);

  return {
    id: chance.normal(),
    name: chance.animal(),
    company: chance.animal(),
    email: chance.email(),
    dateRequested: chance.date(),
    status: chance.pickone(["approved", "rejected", "pending", "hidden"]),
    dateReviewed: chance.date(),
    note: chance.paragraph(),
    reviewer: chance.animal(),
  };
}
