import React, { ReactNode } from "react";
import cn from "classnames";

import styles from "./module.sass";

export type Props = {
  children: ReactNode;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "bold"
    | ""
    | "italic"
    | "footnote"
    | "small";
  color?:
    | ""
    | "light"
    | "lighter"
    | "lightest"
    | "link"
    | "white"
    | "darkBlue"
    | "label";
  className?: any;
  [x: string]: any;
};

const Text = ({
  children,
  variant = "",
  color = "",
  className,
  ...rest
}: Props) => (
  <span
    className={cn(
      styles.text,
      variant && styles[`text$${variant}`],
      color && styles[`text$${color}`],
      className && className
    )}
    {...rest}
  >
    {children}
  </span>
);

export default Text;
