import React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import queryString from "query-string";

import { APILicensingReportRequest } from "domain/licensingReports";
import useLicensingReport from "hooks/useLicensingReport";
import Viewer from "templates/LicensingReports/Viewer";
import Navbar, { EmptyNavbar } from "components/Navbar";
import PageLoader from "molecules/PageLoader";
import useUser from "hooks/useUser";
import { redirectToLogin } from "integration/login";

export interface Props extends RouteComponentProps {}

export default function LicensingReportViewerPage(props: Props) {
  const { status: userStatus, user, isLoggedOut } = useUser();
  if (isLoggedOut) {
    redirectToLogin();
  }

  const location = useLocation();

  // const request = queryString.parse(location.search, {
  //   arrayFormat: "comma",
  // });
  const request = queryString.parse(location.search);
  const stringified = queryString.stringify(request);

  const {
    licensingReport,
    status: licensingReportStatus,
    error,
  } = useLicensingReport(request as unknown as APILicensingReportRequest);

  const isSpinnerVisible = [userStatus, licensingReportStatus].includes(
    "loading"
  );

  const navbar = user ? (
    <Navbar user={user} position="relative" />
  ) : (
    <EmptyNavbar position="relative" />
  );

  return (
    <>
      <PageLoader isLoading={isSpinnerVisible} />
      {licensingReport && (
        <Viewer
          navbar={navbar}
          licensingReport={licensingReport}
          onBack={() => {
            window.location.href =
              "/app.php#licensing-reports/setup?reuseLastSearch=true";
          }}
          searchParams={stringified}
        />
      )}
    </>
  );
}
