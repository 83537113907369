export type LinkType = {
  href: string;
  name: string;
};

declare var API_BASE_URI: string | undefined;

const DEFAULT_BASE_URI = "";

export const BASE_URI =
  typeof API_BASE_URI === "undefined" ? DEFAULT_BASE_URI : API_BASE_URI;
